/**
 *
 *
 */

import React from "react";
import HeaderTop from "components/HeaderTop";
import HeaderUpper from "components/HeaderUpper";
import HeaderNav from "components/HeaderNav";
import PageWrapper from "components/PageWrapper";
import Footer from "components/Footer";
//import MainSlider from 'components/MainSlider';

/**
 * [Component] Template
 * --
 */
const Template = ({ children }) => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      <PageWrapper>
        {/* HeaderTop */}
        <HeaderTop />
        {/* HeaderUpper */}
        <HeaderUpper />
        {/* HeaderNav */}
        <HeaderNav />

        {children}

        {/* <!--footer-main--> */}
        <Footer />
        {/* <!--End footer-main--> */}
      </PageWrapper>
      {/* <!--End pagewrapper--> */}
    </>
  );
};

export default Template;
