/**
 *
 *
 */

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

/**
 * [Component] DivisionOverview
 * --
 */
const Ko = () => {
  const sahaDistrictOfficeLocation = {
    latitude: 35.10458,
    longitude: 128.974734,
  };

  const sahaDistrictOfficeIcon = L.icon({
    iconUrl: "/resources/sahaDistrictOfficeIcon.png",
    iconSize: [32, 32],
  });

  const secondBuildingIcon = L.icon({
    iconUrl: "/resources/sahaDistrictOffice2Icon.png",
    iconSize: [32, 32],
  });

  return (
    <>
      <section
        className="service-overview section"
        style={{ backgroundColor: "#f7f7f7", padding: "40px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2
                  style={{
                    color: "#11ab87",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  사하구청의 위치 및 정보 페이지에 오신 것을 환영합니다!
                </h2>
                <p style={{ fontSize: "1.2em", color: "#555" }}>
                  사하구청은 지역 행정의 중심으로서 지역 사회의 발전과 번영을
                  위해 헌신하고 있습니다. 본 페이지에서는 사하구청의 위치,
                  연락처, 운영 시간 등 필수 정보를 제공함으로써 여러분의 이용에
                  도움을 드리고자 합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2
                  style={{
                    color: "#11ab87",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  사하구청 오시는길
                </h2>
              </div>
              <MapContainer
                center={[
                  sahaDistrictOfficeLocation.latitude,
                  sahaDistrictOfficeLocation.longitude,
                ]}
                zoom={14}
                style={{ width: "100%", height: "500px", margin: "20px 0" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker
                  position={[
                    sahaDistrictOfficeLocation.latitude,
                    sahaDistrictOfficeLocation.longitude,
                  ]}
                  icon={sahaDistrictOfficeIcon}
                >
                  <Popup>사하구청</Popup>
                </Marker>
                <Marker
                  position={[35.095013, 128.961512]}
                  icon={secondBuildingIcon}
                >
                  <Popup>사하구청 제2청사</Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      </section>

      <section
        className="service-overview section"
        style={{ backgroundColor: "#f7f7f7", padding: "40px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img
                src="/resources/DangniStation.png"
                width="100%"
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                }}
                alt="Dangni Station"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2
                  style={{
                    color: "#333",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>사하구청</small>
                </h2>
              </div>
              <p style={{ fontSize: "1.2em", color: "#555" }}>
                사하구청을 찾아오시는 길:
              </p>
              <p
                style={{ fontSize: "1.4em", fontWeight: "bold", color: "#333" }}
              >
                부산광역시 사하구 낙동대로398번길 12
              </p>
              <div className="content-block" style={{ marginTop: "30px" }}>
                <h2
                  style={{
                    color: "#333",
                    fontSize: "1.8em",
                    marginBottom: "10px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>연락처 정보</small>
                </h2>
              </div>
              <div style={{ fontSize: "1.2em", color: "#555" }}>
                <p>
                  <i
                    className="fas fa-phone"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                  051-220-4000
                </p>
                <p>
                  <i
                    className="fas fa-fax"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Fax:</span> 051-220-4269
                </p>
                <p>
                  <i
                    className="fas fa-envelope"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                  admin@newsaha.net
                </p>
              </div>
              <div className="content-block" style={{ marginTop: "30px" }}>
                <h2
                  style={{
                    color: "#333",
                    fontSize: "1.8em",
                    marginBottom: "10px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>업무 시간</small>
                </h2>
              </div>
              <p style={{ fontSize: "1.2em" }}>
                <b style={{ color: "#333" }}>Hours:</b> 오전 9시부터 오후
                6시까지
              </p>
              <p style={{ fontSize: "1.2em" }}>
                <b style={{ color: "#333" }}>점심시간:</b> 오후 12시부터 1시까지
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2
              style={{
                color: "#11ab87",
                fontSize: "2em",
                marginBottom: "20px",
              }}
            >
              대중교통 안내
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              {/* = 버스 이용시 = */}
              <>
                <h3>
                  <i className="fas fa-bus"></i> 버스 이용시:
                </h3>
                <p style={{ fontSize: "1.2em", marginTop: 15 }}>
                  <b>11:</b> 영선동 ↔ 다대포
                  <br />
                  <b>113:</b> 동삼중리 ↔ 장림공단
                  <br />
                  <b>2:</b> 부산역 ↔ 다대포
                  <br />
                  <b>16:</b> 구덕운동장 ↔ 엄궁
                  <br />
                  <b>520(좌석):</b> 용원 ↔ 한성기린아파트
                  <br />
                  <b>58-2(심야):</b> 용원 ↔ 사하구청
                  <br />
                  <b>123:</b> 서구청 ↔ 김해구산동
                  <br />
                  <b>126:</b> 충무동 ↔ 화명동
                  <br />
                  <b>138:</b> 용당동 ↔ 장림
                  <br />
                  <b>1000(심야):</b> 서면 ↔ 다대포
                  <br />
                  <b>1001(심야):</b> 청강리 ↔ 하단
                  <br />
                </p>
              </>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* = 도시철도 이용시 = */}
              <>
                <h3>
                  <i className="fas fa-subway"></i> 도시철도 이용시:
                </h3>
                <p style={{ fontSize: "1.2em", marginTop: 15 }}>
                  당리역에서 하차 후 5번 출구
                </p>
              </>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* = 주차안내 = */}
              <>
                <h3>
                  <i className="fas fa-car"></i> 주차안내
                </h3>
                <p style={{ fontSize: "1.2em", marginTop: 15 }}>
                  사하구청 구내 주차가 다소 어려울 수 있으니 참고하시기
                  바랍니다. 구청 앞 공영주차장을 이용하시거나 가급적 대중교통을
                  이용해 주시기 바랍니다.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
