/**
 *
 *
 */

import ko from "./ko";
import ja from "./ja";
import en from "./en";
import { getCookie } from "utils";

let globalObject = {
  intl: {
    locale: "ko",
    txt: ko,
    // locale: 'ko',
    // txt: ko,
  },
};

/**
 * 상위 프로젝트의 다국어 환경 정보를 셋팅하는 함수
 **/
export function setComponentLocale(lang) {
  console.log("[setComponentLocale] lang: ", lang);
  switch (lang) {
    case "KR":
    case "Kr":
    case "KO":
    case "Ko":
    case "ko": {
      globalObject.intl.locale = lang;
      globalObject.intl.txt = ko;
      break;
    }
    case "US":
    case "us":
    case "EN":
    case "En":
    case "Eng":
    case "ENG":
    case "en": {
      globalObject.intl.locale = lang;
      globalObject.intl.txt = en;
      break;
    }
    case "jp":
    case "JP":
    case "ja":
    case "JA": {
      globalObject.intl.locale = lang;
      globalObject.intl.txt = ja;
      break;
    }
    default: {
      globalObject.intl.locale = "US";
      globalObject.intl.txt = en;
      break;
    }
  }
  return globalObject.intl;
}

let getLocale = () => {
  const intl = setComponentLocale(getCookie("country"));
  console.log("intl:: ", intl);
};
getLocale();

export default globalObject;
