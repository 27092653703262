/**
 *
 *
 */

import React from "react";

/**
 * [Component] DivisionOverview
 * --
 */
const Ko = ({ list }) => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <img
                src="resources/support/support_3_2.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>
                    <small>"Navigate your way through Saha and Beyond"</small>
                    <br />
                    사하구에 대한 많은 관심에 감사드립니다.
                  </h2>
                </div>
                <p style={{ fontSize: "1.35em" }}>
                  이 페이지는 사하구 및 부산광역시의 다양한 서비스, 기관 및
                  기회를 탐색하는 데 도움이 되는 리소스 목록을 제공하기 위해
                  만들어졌습니다. 각 링크는 해당 기관 또는 단체로 직접
                  연결되므로 필요한 정보나 지원을 원활하고 편리하게 이용할 수
                  있습니다.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2>
              <small>지방정부 및 행정기관</small>
            </h2>
          </div>
          <div className="row">
            {list[0].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>교육기관</small>
            </h2>
          </div>
          <div className="row">
            {list[1].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>비즈니스 및 투자</small>
            </h2>
          </div>
          <div className="row">
            {list[2].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>관광</small>
            </h2>
          </div>
          <div className="row">
            {list[3].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>건강보험</small>
            </h2>
          </div>
          <div className="row">
            {list[4].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
