const HeaderNavStyles = {
  /* ✅ 모바일 메뉴 버튼: 오른쪽 상단에서 조금 내려감 */
  sideMenuButton: {
    position: "fixed",
    top: "3rem", // 🔹 기존보다 조금 아래로 이동
    right: "1rem",
    zIndex: 998,
    background: "#11ab87",
    width: "4.5rem",
    height: "4.5rem",
    border: "none",
    borderRadius: "50%",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.35)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  closeButton: {
    width: "4.5rem",
    border: "none",
    zIndex: 999,
    cursor: "pointer",
  },

  fadeBackground: {
    background: "rgba(0,0,0,0.75)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 980,
  },

  slideRightMenu: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "75%",
    maxWidth: "380px",
    height: "100%",
    zIndex: 999,
    background: "#fff",
    boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.35)",
    overflowY: "auto",
    transform: "translateX(-100%)",
    transition: "transform 0.3s ease-in-out",
  },

  menuHeader: {
    background: "#fff",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #e0e0e0",
  },

  navbarLinks: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },

  dropdownMenu: {
    textAlign: "left",
    padding: 0,
  },

  submenuItem: {
    textAlign: "left",
    padding: "0.75rem 1rem",
    lineHeight: 1.5,
    color: "#333",
  },

  navbarDesktop: {
    padding: 0,
  },

  navbarDesktopContainer: {
    cursor: "pointer",
  },

  topMenu: {
    background: "blue",
    boxShadow: "0px 5px 2px -3px rgba(0,0,0,0.07)",
    position: "absolute",
    width: "100%",
    minHeight: "3.125rem",
    top: "-4.375rem",
    zIndex: 990,
  },

  topMenuNav: {
    color: "#333",
    background: "#fff",
    borderTop: "1px solid #11ab87",
    borderBottom: "1px solid #11ab87",
  },

  megaMenuDesktopSubmenuItem: {
    textAlign: "left",
    padding: "0.75rem 1rem",
    color: "#fff",
    background: "linear-gradient(90deg, #11ab87 0%, #0f9d76 100%)",
    borderTop: "1px solid #11ab87",
    borderBottom: "1px solid #11ab87",
  },

  megaMenuItem: {
    display: "flex",
    alignItems: "center",
    padding: "0.625rem 0",
  },

  megaMenuButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: "0.3125rem 0.625rem",
    fontSize: "1rem",
    transition: "color 0.3s ease",
    display: "flex",
    alignItems: "center",
    gap: "0.3125rem",
    color: "#333",
    textDecoration: "none",
  },

  megaMenuButtonHover: {
    color: "#11ab80",
  },
};

export default HeaderNavStyles;
