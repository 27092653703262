import React from "react";
import styled from "styled-components";

// ✅ 검은 배경 컨테이너 (비디오 크기에 맞춰 자동 조절)
const VideoContainer = styled.div`
  display: inline-block;
  background-color: black;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  text-align: center;
  position: relative; /* 클릭 효과 추가 */
  cursor: pointer; /* 클릭 가능하도록 커서 변경 */
`;

// ✅ 반응형 비디오 (화면 크기에 맞춰 자동 조정)
const VideoBackground = styled.video`
  width: 100%;
  height: auto;
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
  transition: opacity 0.3s ease-in-out; /* 클릭 시 부드러운 변화 */

  &:hover {
    opacity: 0.8; /* 마우스 올릴 때 투명도 조절 */
  }
`;

const FullScreenVideo = () => {
  // ✅ 클릭 시 페이지 이동 함수
  const handleVideoClick = () => {
    window.location.href = `${window.location.origin}/#/kic-overview`;
  };

  return (
    <VideoContainer onClick={handleVideoClick}>
      {/* ✅ MP4 비디오 파일 (반응형 적용) */}
      <VideoBackground autoPlay loop muted playsInline>
        <source
          src={`${window.location.origin}/resources/home/SeobusanSmartValley.mp4`}
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </VideoBackground>
    </VideoContainer>
  );
};

export default FullScreenVideo;
