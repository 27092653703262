/**
 * 다국어 : 영어
 *
 */

export default {
  operation_comment: "Opening Hours : Monday to Friday - 9am to 6p",
  /* Menus */
  why_saha: "Why Saha",
  saha_vision: "Saha Vision",
  development_plan: "Development Plan",
  history_culture: "Allure & Advantage",

  discover_saha: "Discover Saha",
  mayors_greetings: `Mayor' Greetings`,
  organization_structure: "Organization Structure",

  kics: "KIC",
  kic_overview: "KIC Overview",
  investment_incentives: "Investment Incentives",
  kics_progress: "KIC Progress",

  strategic_business_division: "Strategic Business Division.",
  development_overview: "Division overview",
  innovation_tech: "Innovation & Tech",
  rnd_support: "R&D Support",

  saha_life: "Saha Life",
  district_housing: "District & Housing",
  lifestyle_transportation: "Lifestyle & Transportation",
  saha_edu: "Saha EduSphere",

  saha_updates: "Saha Updates",
  saha_district_bulletin: "Saha District Bulletin",
  announcements: "Announcements",
  press_release: "Press Release",
  newsletters: "Newsletters",
  downloads: "Downloads",

  support: "Support",
  location_info: "Location & Info",
  inquiry_form: "Inquiry & Form",
  helpful_links: "Helpful Links",
  // _: '',
};
