import React from "react";

const ExpoBanner = () => {
  const handleBannerClick = () => {
    window.location.href = "/#/kic-progress"; // ✅ 해시 라우팅 경로 직접 지정
  };

  return (
    <section
      className="service-tab-section section bg-gray hover-opacity"
      onClick={handleBannerClick} // ✅ 클릭 이벤트 수정
      style={{
        backgroundColor: "#f0f0f5",
        backgroundImage: `url("/kic_banner.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        padding: 0,
        width: "100%",
        position: "relative",
        cursor: "pointer", // ✅ 클릭 가능한 UI 표시
      }}
    >
      <style>
        {`
          .service-tab-section {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @media (min-width: 750px) {
            .service-tab-section {
              height: 170px;
              background-position: center center;
            }
          }

          @media (max-width: 767px) {
            .service-tab-section {
              height: 75px;
              background-position: top center;
              background-size: auto 100%;
            }
          }
        `}
      </style>
    </section>
  );
};

export default ExpoBanner;
