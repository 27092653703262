/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./HelpfulLinks.ko";
import En from "./HelpfulLinks.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] HelpfulLinks
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie("country");
  const list = [
    [
      {
        link: "https://www.busan.go.kr/eng/index",
        img: "1-1.png",
      },
      {
        link: "https://www.nts.go.kr/english/main.do",
        img: "1-2.png",
      },
      {
        link: "https://www.immigration.go.kr/immigration_eng/index.do",
        img: "1-3.png",
      },
      {
        link: "https://www.hikorea.go.kr/Main.pt",
        img: "1-4.png",
      },
      {
        link: "https://www.bsgangseo.go.kr/bsgangseoEn/main.do",
        img: "1-5.png",
      },
      {
        link: "https://www.sasang.go.kr/eng/index.sasang",
        img: "1-6.png",
      },
    ],
    [
      {
        link: "https://www.pen.go.kr/english/index.pen",
        img: "2-1.png",
      },
    ],
    [
      {
        link: "https://www.investkorea.org/ik-en/index.do",
        img: "3-1.png",
      },
      {
        link: "https://www.expo2030busan.kr/eng/index.do",
        img: "3-2.png",
      },
    ],
    [
      {
        link: "https://www.humetro.busan.kr/english/main.do",
        img: "4-1.png",
      },
      {
        link: "https://english.visitkorea.or.kr/enu/index.kto",
        img: "4-2.png",
      },
      {
        link: "https://bto.or.kr/eng/Main.do",
        img: "4-3.png",
      },
      {
        link: "https://www.visitbusan.net/en/index.do",
        img: "4-4.png",
      },
    ],
    [
      {
        link: "https://www.nhis.or.kr/english/index.do",
        img: "5-1.png",
      },
      {
        link: "https://www.hira.or.kr/eng/main.do",
        img: "5-2.png",
      },
    ],
  ];

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["inquiry_form"]}
        breadcrumb={[lang["support"], lang["inquiry_form"]]}
        backgroundImage={"resources/support/support_3_1.jpg"}
        backgroundPosition={"center 75%"}
      />
      {language !== "KR" ? <En list={list} /> : <Ko list={list} />}
    </>
  );
};

export default PageIndex;
