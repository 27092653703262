import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, GeoJSON, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import styled from "styled-components";

// Create a custom icon with a number label

function createNumberedIcon(number) {
  const svgString = `
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="14" fill="#007bff" />
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#ffffff" font-size="12">
        ${number}
      </text>
    </svg>
  `;

  return L.divIcon({
    className: "custom-icon",
    html: svgString,
    iconSize: [32, 32],
  });
}

const MarkerNumber = styled.div`
  background-color: #007bff; /* Set the background color for the circle */
  color: #ffffff; /* Set the text color */
  width: 32px; /* Set the width of the circle */
  height: 32px; /* Set the height of the circle */
  border-radius: 50%; /* Make it a circle using border-radius */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const styles = {
  cardStyle: {
    // padding: '25px 20px',
    padding: 1,
    boxShadow: "1px 1px 8px 0px rgba(0,0,0,0.25)",
    height: "100%",
    backgroundColor: "#fff",
    borderRadius: 15,
  },
};

// Define the onEachFeature function
const onEachFeature = (feature, layer) => {
  // Your code for handling GeoJSON features goes here
  // For example, you can add a popup to the layer
  if (feature.properties && feature.properties.name) {
    layer.bindPopup(feature.properties.name);
  }
};

const kicLocations = [
  {
    name: "PENTA PLEX 1",
    latitude: 35.089458,
    longitude: 128.968583,
    image: "1_KIC_Overview_pic1-1.png",
  },
  {
    name: "Smart T Tower",
    latitude: 35.086057,
    longitude: 128.971536,
    image: "1_KICOverview_pic3.jpeg",
  },
  {
    name: "Jump Tower",
    latitude: 35.090576,
    longitude: 128.958369,
    image: "1_KICOverview_pic4.jpeg",
  },
  {
    name: "Lead One",
    latitude: 35.090871,
    longitude: 128.962775,
    image: "1_KICOverview_pic5.jpeg",
  },
  {
    name: "SK V1(U1)",
    latitude: 35.087268,
    longitude: 128.969886,
    image: "1_KICOverview_pic6.jpeg",
  },
  {
    name: "PENTA PLEX Metro",
    latitude: 35.089268,
    longitude: 128.973845,
    image: "1_KICOverview_pic6-1.png",
  },
  {
    name: "Jump Tower 2",
    latitude: 35.08972,
    longitude: 128.966602,
    image: "1_KICOverview_pic77.png",
  },
  {
    name: "Sky the Terrace",
    latitude: 35.077802,
    longitude: 128.975444,
    image: "1_KICOverview_pic88.png",
  },
  {
    name: "Upcoming KIC in Sinpyeong-dong",
    latitude: 35.095152,
    longitude: 128.957471,
    image: "1_KICOverview_pic99.png",
  },
  {
    name: "Upcoming KIC in Jangnim-dong",
    latitude: 35.084831,
    longitude: 128.973344,
    image: "1_KICOverview_pic1010.png",
  },
  {
    name: "Upcoming KIC in Jangnim-dong",
    latitude: 35.072409,
    longitude: 128.975634,
    image: "1_KICOverview_pic1111.png",
  },
  {
    name: "Upcoming KIC in Sinpyeong-dong",
    latitude: 35.093056,
    longitude: 128.964365,
    image: "1_KICOverview_pic1212.png",
  },
  {
    name: "Upcoming KIC in Sinpyeong-dong",
    latitude: 35.095656,
    longitude: 128.959762,
    image: "1_KICOverview_pic1313.png",
  },
  {
    name: "Upcoming KIC in Jangnim-dong",
    latitude: 35.071652,
    longitude: 128.978198,
    image: "1_KICOverview_pic1414.png",
  },
];

/**
 * [Component] KICOverview
 * --
 */
const En = ({ list }) => {
  const mapRef = useRef(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.leafletElement;

      const mapMarkers = kicLocations.map((location, index) => {
        const marker = L.marker([location.latitude, location.longitude], {
          icon: createNumberedIcon(index + 1),
        });

        marker.bindPopup(
          `<h3>${location.name}</h3><img src="resources/KIC/${location.image}" alt="${location.name}" width="200" />`,
        );

        // Add a click event listener to open the popup
        marker.on("click", () => {
          console.log("Marker clicked"); // Add this line

          marker.openPopup();
        });

        return marker;
      });

      // Add the markers to the map and store them in the state
      mapMarkers.forEach((marker) => marker.addTo(map));
      setMarkers(mapMarkers);
    }
  }, [mapRef]);

  const handleImageClick = (index) => {
    if (mapRef.current && index >= 0 && index < kicLocations.length) {
      const location = kicLocations[index];
      mapRef.current.flyTo([location.latitude, location.longitude], 14);

      // Scroll to the map container
      const mapContainer = mapRef.current.getContainer();
      window.scrollTo({
        top: mapContainer.offsetTop,
        behavior: "smooth",
      });
      // Programmatically trigger a click event on the marker to open the popup
      const marker = markers[index];
      if (marker) {
        marker.openPopup();
      }
    } else {
      console.error("Map or markers not available.");
    }
  };

  // Define the useEffect to fit the map to the bounds of all markers
  useEffect(() => {
    // Calculate the bounds of all markers
    const bounds = L.latLngBounds(
      kicLocations.map((location) => [location.latitude, location.longitude]),
    );

    if (mapRef.current) {
      // Fit the map to the bounds of all markers
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>
                  The Innovation Drivers of Sinpyeong-JANGNIM Industrial Complex
                  in Saha District
                </h2>
              </div>
              <div className="story-content align-justify">
                <h4 className="tagline">
                  "Investment Opportunities in Saha District's Knowledge
                  Industry Centers (KICs)"
                </h4>
                <p>
                  Saha District is home to Sinpyeong-Jangnim Industrial Complex,
                  a hub for manufacturing enterprises and a key player in the
                  economic growth of Busan. We are constructing a new wave of
                  Knowledge Industry Centers (KICs) to further stimulate the
                  local economy. These complexes offer mixed-use facilities
                  serving various sectors such as manufacturing, information and
                  communication, research and development, and knowledge-based
                  startups. The prime location, excellent transport links, and
                  fully-equipped facilities make these KICs an ideal investment
                  for foreign investors and factory owners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-12"
              style={{
                // background: `url("resources/KIC/0011.jpeg")`,
                borderRadius: 20,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <img
                src="resources/KIC/0011.jpeg"
                width={"95%"}
                style={{
                  borderRadius: 20,
                  border: "0px solid #dadada",
                }}
                alt=""
              />
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>What is a Knowledge Industry Center (KIC)?</h2>
              </div>
              <div className="story-content align-justify">
                <p>
                  A Knowledge Industry Center is a state-of-the-art
                  infrastructure designed to house businesses from diverse
                  sectors such as manufacturing, information and communication,
                  research and development, and knowledge-based startups. These
                  centers, with their mixed-use facilities, provide the perfect
                  environment for businesses to thrive and innovate. They are
                  equipped with office spaces, production facilities, research
                  labs, and living spaces, creating an integrated work-life
                  ecosystem that fosters creativity and productivity.
                  <br />
                  <br />
                  The KICs in Saha District, given their prime location,
                  excellent transport links, and fully-equipped facilities, are
                  an ideal investment opportunity for foreign investors and
                  factory owners.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* row */}
          <div className="row">
            {/* #1 */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>
                  KICs Under Construction in Sinpyeong-Jangnim Industrial
                  Complex
                </h2>
              </div>
            </div>
            {/* #1 */}

            {/* #2 */}
            {list.map((item, index) => (
              <div
                className="col-lg-4 col-md-6"
                key={`kicoverview_${index}`}
                style={{ marginBottom: 25, cursor: "pointer" }} // Add cursor style
                onClick={() => handleImageClick(index)} // Add onClick to handle image click
              >
                <div style={styles.cardStyle}>
                  <div
                    style={{
                      // backgroundImage: `url("resources/discover_saha/${item.image}")`,
                      backgroundImage: `url("resources/KIC/${item.image}")`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      height: 250,
                      borderRadius: 15,
                    }}
                  ></div>
                  <div className="content-block" style={{ padding: 20 }}>
                    <h5>{item.title_en}</h5>
                    <hr />
                    <p style={{ margin: 0 }}>{item.comment_en}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* #2 */}
          </div>
          {/* row */}
        </div>
      </section>

      {/* Leaflet Map */}
      <MapContainer
        center={[35.0892, 128.9686]}
        zoom={14}
        style={{ height: "600px", width: "100%" }}
        ref={mapRef}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {kicLocations.map((location, index) => (
          <Marker
            key={index}
            position={[location.latitude, location.longitude]}
            icon={createNumberedIcon(index + 1)} // Use the custom icon with numbers
          >
            <Popup>
              <div>
                <h4>{location.name}</h4>
                <img
                  src={`resources/KIC/${location.image}`}
                  alt={location.name}
                  width="200"
                />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/KIC/1_KICOverview_pic9.jpg"
                width={"95%"}
                style={{
                  borderRadius: 20,
                  border: "0px solid #dadada",
                }}
                alt=""
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>Attracting Global Talents and Businesses</h2>
              </div>
              <div className="story-content align-justify">
                <p>
                  We are actively inviting foreign investors and factory owners
                  to partake in this golden opportunity. Establishing your
                  business in our KICs will not only benefit from the
                  infrastructural advantages but also contribute to the vibrant
                  knowledge-based economy in Saha District, Busan.
                  <br />
                  <br />
                  For inquiries or additional information about our Knowledge
                  Industry Centers, please contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              style={{ marginBottom: 30 }}
            ></div>
            {list.map((item, index) => (
              <div
                className="col-lg-6 col-md-12"
                key={`kicoverview2_${index}`}
                style={{ marginBottom: 30 }}
              >
                <div
                  style={{
                    ...styles.cardStyle,
                    borderRadius: 10,
                    padding: "25px 25px",
                  }}
                >
                  <div className="row">
                    {/*  */}
                    <div className="col-3">
                      <div
                        style={{
                          width: 110,
                          height: 110,
                          margin: "0 auto",
                          borderRadius: "100%",
                          background: "#f1f1f1",
                          position: "relative",
                          background: `url("resources/KIC/${item.image}")`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                        }}
                      >
                        <div
                          style={{
                            width: 35,
                            height: 35,
                            margin: "0 auto",
                            borderRadius: "100%",
                            background: "#fff",
                            color: "#cf467f",
                            border: "3px solid #cf467f",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {index + 1}
                        </div>
                      </div>
                    </div>
                    <div className="col-9">
                      <h4>{item.title_en}</h4>
                      <div className="story-content align-justify">
                        <p>{item.comment_en}</p>
                        <hr />
                        <p>{item.address_en}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "10px 35px" }}
            >
              <div className="content-block">
                <h3>
                  Knowledge Industry Centers Shaping Saha District's Economy
                </h3>
                <hr />
              </div>
              <div className="story-content align-justify">
                <p>
                  18 forthcoming Knowledge Industry Centers will collectively
                  house an impressive number of businesses and employees,
                  significantly contributing to the regional economy. For
                  instance, the SK V1(U1), with a Gross Floor Area (GFA) of
                  165,648.66㎡, is projected to generate 2,930 jobs upon
                  completion, while the unnamed KIC at Jangnim-dong 488, with a
                  GFA of 93,818.26㎡, is anticipated to create 1,660 jobs. Stay
                  tuned for more updates as these projects progress.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "10px 35px" }}
            >
              <div className="content-block">
                <h3>
                  Building Tomorrow: Creating Hubs of Innovation and Growth
                </h3>
                <hr />
              </div>
              <div className="story-content align-justify">
                <p>
                  At the Saha District Office, we are committed to supporting
                  the advancement of our Knowledge Industry Centers. Our vision
                  is centered around creating dynamic hubs of innovation and
                  growth, ensuring that our resident businesses prosper. From
                  backing the expansion of existing complexes to facilitating
                  the construction of new state-of-the-art facilities, our
                  initiatives are forward-thinking and community-focused. We aim
                  to foster an environment that not only cultivates the growth
                  of individual businesses but also contributes to the overall
                  vitality of our district and its community. Discover the
                  exciting future we are cultivating through our supportive
                  endeavors. Stay tuned for updates on our relentless journey of
                  enabling progress and innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
