import React from "react";
import moment from "moment";
import styled from "styled-components";

// ✅ 전체적으로 홈페이지 스타일 적용
const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    background-color: #f8f8f8;
  }
`;

const HoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const ImageBox = styled.div`
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  margin-bottom: 15px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Tag = styled.div`
  padding: 3px 10px;
  margin-right: 5px;
  border-radius: 12px;
  background: #f3f3f3;
  font-size: 12px;
  font-weight: 500;
  color: #333;
`;

const ReadMoreButton = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #11ab95;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #09976d;
  }
`;

const BlogTitle = styled.h4`
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #222;
  margin-bottom: 10px;
`;

const BlogContent = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const BlogDate = styled.span`
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
`;

const BlogItem = ({ item, index, type = "default", onChooseData }) => {
  const content = item.content.replaceAll(/<[^>]+>.*?/g, "");
  const thumbnailRaw = item.content.match(/src=(["'].*?["'])/g, "");
  const thumbnail = thumbnailRaw ? thumbnailRaw[0].split("src=")[1] : null;

  return (
    <ItemHolder onClick={() => onChooseData(item)} key={`blog-tspt-${index}`}>
      <HoverContainer>
        {thumbnail && <ImageBox backgroundImage={thumbnail}></ImageBox>}

        <ContentContainer>
          <BlogTitle>{item.title ? item.title : "-"}</BlogTitle>

          <BlogDate>{moment(item.date).format("YYYY.MM.DD (HH:mm)")}</BlogDate>

          <BlogContent>
            {item.content
              ? `${content.slice(0, 200)}${content.length > 200 ? "..." : ""}`
              : `-`}
          </BlogContent>

          <TagsContainer>
            {item.tags &&
              item.tags.map((tagItem) => <Tag key={tagItem}>{tagItem}</Tag>)}
          </TagsContainer>

          <ReadMoreButton>Read more</ReadMoreButton>
        </ContentContainer>
      </HoverContainer>
    </ItemHolder>
  );
};

export default BlogItem;
