/**
 *
 *
 */

import React from "react";

/**
 * [Component] PageWrapper
 * --
 */
const PageWrapper = ({ children }) => {
  return (
    <>
      <div className="page-wrapper">
        {children}

        {/* <!-- scroll-to-top --> */}
        {/* <div id="back-to-top" className="back-to-top">
          <i className="fa fa-angle-up"></i>
        </div> */}
      </div>

      <div className="scroll-to-top scroll-to-target" data-target=".header-top">
        <span className="icon fa fa-angle-up"></span>
      </div>
    </>
  );
};

export default PageWrapper;
