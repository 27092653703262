/**
 *
 *
 */

import React from "react";

/**
 * [Component] DivisionOverview
 * --
 */
const En = ({ list }) => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <img
                src="resources/support/support_3_2.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>
                    <small>"Navigate your way through Saha and Beyond"</small>
                    <br />
                    Thank you for your cooperation and interest in Saha
                    District.
                  </h2>
                </div>
                <p className="align-justify">
                  This page is designed to provide a curated list of resources
                  to help you navigate various services, organizations, and
                  opportunities in Saha District and Busan Metropolitan City.
                  <br />
                  Each link leads you directly to the respective authority or
                  organization, offering a smooth and convenient way to access
                  the required information or assistance.
                  <br />
                  <br />
                  We hope you find these links beneficial. Feel free to explore
                  and use them as per your requirements. As always, your
                  feedback is highly appreciated to ensure the constant
                  improvement of our service.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2>
              <small>Local Governments and Authorities</small>
            </h2>
          </div>
          <div className="row">
            {list[0].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>Education</small>
            </h2>
          </div>
          <div className="row">
            {list[1].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>Business and Investment</small>
            </h2>
          </div>
          <div className="row">
            {list[2].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>Tourism</small>
            </h2>
          </div>
          <div className="row">
            {list[3].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <br />
          <br />
          <div className="content-block">
            <h2>
              <small>Health and Insurance</small>
            </h2>
          </div>
          <div className="row">
            {list[4].map((item, index) => (
              <div
                className="col-lg-3 col-md-6"
                key={`helpluingk-${item.link}`}
                style={{
                  marginBottom: 25,
                }}
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <div
                    className="team-person text-left "
                    style={{
                      padding: 25,
                      height: 130,
                      background: "#fff",
                      border: "1px solid #f1f1f1",
                      boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                    }}
                  >
                    <div
                      style={{
                        background: `url("resources/support/logos/${item.img}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
