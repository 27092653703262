/**
 *
 *
 */

import React from "react";

// const styles = {
//   articleTitle: {
//     color: '#333',
//     marginBottom: 15,
//   },
//   articleWarpper: {
//     textAlign: 'center',
//   },
//   articleImage: {
//     width: '100%',
//     height: '100%',
//     maxHeight: 305,
//     minHeight: 235,
//     margin: '0 auto',
//     backgroundSize: 'cover',
//     // backgroundSize: 'cover 100%',
//     border: '1px solid #dadada',
//     borderRadius: 20,
//   },
// };

/**
 * [Component] Sample
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic2.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  height: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <br />
              <br />
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>사하구의 매력과 강점</h2>
                <p>
                  부산의 남서쪽에 위치한 사하구는 역사와 문화가 살아 숨 쉬는
                  곳입니다. 풍부한 유산과 다양한 문화 풍경을 자랑하는 사하구는
                  교육적인 경험과 적극적인 지역사회 활동의 융합을 제공합니다
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container" style={{ maxWidth: 800 }}>
          <div className="row">
            {/*  */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h3 style={{ color: "#333", fontWeight: "bold" }}>
                  사하구의 역사적 의의
                </h3>
                <br />
                <p>
                  사하구는 신석기시대부터 삼한과 가야 시대에 이르는 풍부한
                  역사를 간직하고 있습니다. 신석기 시대의 다대포 조개더미와
                  청동기 시대의 괴정 1동과 괴정 2동 지역의 고고학 유적이 이를
                  반영합니다. 불행하게도 1964년 부산 화력발전소의 확장과 도시
                  개발로 인해 감천동의 고인돌은 더 이상 존재하지 않습니다.
                </p>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic3.jpg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">괴정동 패총</p>
              <br />
              <br />
            </div>
            {/*  */}
            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                1740년 동래부지(동래부의 지리지)에 이 지역은 사천면이 신초랑리,
                구초랑리, 대지리, 목장리, 감천리, 독지리, 장림리, 다대리 등
                9개의 마을로 구성되어 있다고 기록되어 있으며, 또한 이 지역은
                다대진성과 같은 요새를 통해 일본으로부터 방어하는 데 전략적으로
                중요한 곳이었습니다.
                <br />
                <br />
                1866년에 사천면은 사상면과 사하면으로 나누어졌습니다.  “1904년
                경상남도 동래군 가호안"에는 다대리, 장림리, 서평리, 신평리,
                하단리, 평림리, 감천리, 대티리, 괴정리, 당리 등의 여러 마을이
                사하면에 속하고 있습니다. 이들은 모두 현대의 사하구의 일부를
                구성하고 있습니다. 1910년에는 부산부의 사하면이 되었고, 1914년
                행정구역 재편 후에는 암남동, 감천동, 하단동, 신평동, 장림동,
                다대동, 구평동, 당리동, 괴정동 등의 동네들이 동래군 사하면에
                속하게 되었습니다. 암남리는 1936년에 부산부에 편입하여
                분리되었습니다.
              </p>
            </div>
            {/*  */}
            {/*  */}
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic4.jpg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">
                괴정동과 하단동간의 비포장 낙동로를 삼륜자동차가 달리는 모습
              </p>
              <br />
              <br />
            </div>
            {/*  */}

            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                1942년에 이 지역은 부산부에 편입되었고 사하출장소가
                설치되었습니다. 1951년에 부산 서부 출장소의 사하지소로 명칭이
                변경되었고, 1957년에는 서구의 사하출장소가 되었습니다. 1963년
                부산이 직할시로 승격되면서 사하출장소는 1975년에 시 직할
                출장소로 승격되었습니다.1983년에는 사하구가 서구로부터
                분리되었고 북구 관할에 있던 을숙도와 일웅도를 편입하였습니다.
                1995년 부산이 광역시로 승격된 데 따라 이 지역은 공식적으로
                부산광역시 사하구가 되었습니다.
              </p>
            </div>
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic14.jpeg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">1987년 개발중인 하단동 풍경</p>
              <br />
              <br />
            </div>
            {/*  */}

            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                이러한 역사의 여정은 사하구의 정체성을 형성하는 데 크게
                기여하였으며, 이 지역의 풍부한 문화 유산을 형성하는 데 크게
                기여하였습니다.
              </p>
            </div>
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic5.JPG"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">사하구청 제2청사</p>
            </div>
            {/*  */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic15.jpeg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">다대포 해변 일몰 풍경</p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>{"사하구의 문화 & 자연"}</h2>
                <p className="align-justify">
                  사하구는 다채로운 문화와 자연풍경을 함께 제공하고 있습니다.
                  몰운대의 남쪽 끝은 파도의 침식에 의해 형성된 해식동이 발달되어
                  있고, 몰운대는 예로부터 우거진 숲과 기암괴석, 출렁거리는
                  파도와 수려한 모래밭 등 빼어난 경승지로 이름나 있습니다. 또한,
                  그 주변에는 다양한 형태의 작은 무인도들이 흩어져 있어 풍경을
                  한층 더 돋보이게 합니다. 몰운대의 가장 남쪽 끝에는 파도에 의해
                  이루어진 독특한 해안 동굴이 있습니다. 이 지역에는 다대포라는
                  아름다운 모래 해변이 위치하고 있어, 지역 주민과 관광객들에게
                  사랑받고 있습니다.
                </p>
              </div>
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  낙동강 하구는 천혜의 자연환경 뿐만 아니라 문화적으로도 깊이
                  있는 지역입니다. 철새들의 안식처이자 생물다양성의 중요성을
                  교육하는 생태 보전 현장인 낙동강하구에코센터가 자리하고
                  있습니다. 이 아름다운 경관 속에 자리 잡은 을숙도는 낙동강의
                  자연 섬이었던 을숙도입니다. 지금은 탐조객과 생태관광객들의
                  천국으로, 지역 전통을 기념하는 을숙도문화관과 다양한 조류 종을
                  만날 수 있는 을숙도철새공원이 자리하고 있습니다. 을숙도의
                  생태적 웅장함을 보완하는 것은 부산현대미술관이 구현하는 문화적
                  풍요로움 입니다. 이 미술관은 현대 미술의 중요한 허브 역할을
                  하며 국내외 작가들을 소개하는 다양한 전시회를 개최하여 부산의
                  활기찬 문화 현장을 들여다볼 수 있는 독특한 창을 제공합니다.
                  이처럼 이 지역은 자연의 아름다움과 문화적 활기가 완벽하게
                  조화를 이루며 다양한 관심사를 충족시키는 경험을 제공합니다.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic6.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">을숙도문화회관-부산관광공사제공</p>
              <br />
              <br />
              <br />
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic7.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">감천문화마을 천덕수 우물길</p>
              <br />
              <br />
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  이 지역의 문화 경관에 깊이와 색채를 더하는 것은 특별한
                  감천문화마을입니다. '부산의 마추픽추'라고 불리는 이 매혹적인
                  동네는 역사, 문화, 예술이 모자이크처럼 어우러진 곳입니다. 좁고
                  구불구불한 골목과 가파른 계단 하나하나가 미로처럼 얽혀 있어
                  매번 놀라움을 선사합니다. 언덕을 배경으로 다채로운 색상의
                  카드를 쌓아 놓은 것처럼 옹기종기 모여 있는 집들은 각각 생동감
                  넘치는 색채로 칠해져 예술적 창의성과 공동체 정신을 보여줍니다.
                  이 지역은 단순한 주거 지역이 아니라 부산의 정신을 가장
                  매력적으로 포착하고 표현하는 살아 숨 쉬는 캔버스입니다. 이
                  마을은 야외 박물관의 역할을 하며 방문객들에게 이 지역의 독특한
                  예술과 문화를 통해 독특한 여행을 제공합니다. 생동감 넘치는
                  색채와 소박한 매력 속에서 예상치 못한 구석에 숨어 있는
                  설치미술, 벽화, 갤러리를 발견하고 각각 부산의 풍부한
                  문화유산에 대한 독특한 이야기를 들려줍니다. 이처럼
                  감천문화마을은 이 지역의 문화적 활기를 더할 뿐만 아니라 이
                  지역의 창의적 정신과 회복력을 보여주는 증거입니다.
                </p>
              </div>
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  마지막으로 승학산은 사하구의 정기를 상징하는 산입니다.
                  낙동강과 을숙도, 사하구 전경을 한눈에 조망할 수 있어 지역
                  주민들이 등산과 여유로운 산책을 즐기기 위해 즐겨 찾는
                  곳입니다. 자연의 아름다움, 문화의 풍요로움, 역사적 의미 등
                  사하구는 다양한 경험을 선사하는 곳입니다.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic8.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">승학산 억새군락</p>
            </div>
            {/* === #00 === */}

            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic9.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">대동고 펜싱부 창단식</p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>우수한 교육환경 및 특성화 고등학교</h2>
                <p className="align-justify">
                  사하구는 강력한 교육 인프라, 특히 많은 고등학교들에 대한
                  자부심이 있습니다. 사하구에는 우수한 고등학교가 밀집해 있어
                  통학에 큰 불편함이 없습니다. 또한, 남녀공학, 특성화 고등학교
                  등 다양한 종류의 학교가 있습니다.
                  <br />
                  <br />
                  특성화 고등학교는 독특한 커리큘럼과 집중 연구 분야로 이 지역의
                  교육 환경을 더욱 향상시킵니다. 여기에는 외국어 고등학교와 과학
                  고등학교 등이 포함됩니다. 실제로 사하구에는 서울의 다른 지역과
                  비교해도 유례가 없을 정도로 많은 6개의 특성화고등학교가
                  있습니다. 이처럼 다양한 교육기관은 사하구 내 학생들뿐만 아니라
                  사하구의 수준 높은 교육을 위해 통학하는 타구 학생들도 상당수
                  유치하고 있습니다. 사하구는 다양하고 폭넓은 교육 기회를
                  제공하기 위해 어느 것 하나 놓치지 않고 있습니다.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>교통의 중심지로 부상</h2>
                <p className="align-justify">
                  사하구는 가덕도신공항과 부산형급행철도(이하 BuTX)의 동시
                  개발로 서부산의 중추적인 교통 요충지로 급부상하며 흥미로운
                  변신을 거듭하고 있습니다. 친환경 고속 수소 철도인 BuTX는
                  가덕도 국제공항, 명지, 하단, 북항, 센텀시티, 오시리아 등 주요
                  지역을 효과적으로 연결하여 하루 약 11만 4천 명의 통근자를
                  수용할 수 있을 것으로 예상됩니다. 이 전략적 개발의 효과는
                  다양합니다. 이동 시간을 획기적으로 단축하고 신공항과의
                  접근성을 향상시켜 북항까지 15분, 오시리아까지 25분 만에 도달할
                  수 있습니다. 무엇보다 하단역은 부산 강서구와 사상구를 잇는
                  중심역이 될 예정이어서 부산 교통망에서 사하구의 중심성이 더욱
                  강화될 것입니다. 김해공항, 감천항, 남해고속도로, 경부고속도로
                  등 기존 교통망과의 근접성과 더불어 이러한 개발은 부산의
                  사회경제적 지형을 형성하는 데 있어 사하구의 역할을 강화하여
                  부산의 글로벌 위상을 더욱 높여줄 것입니다.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic10.jpeg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">가덕도 신공항 건설</p>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic11.png"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">하단 5일장</p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>미식의 즐거움</h2>
                <p className="align-justify">
                  사하구는 미식의 보고로, 오랜 시간 자리잡은 숨은 맛집이 많아
                  풍부한 미식 명성을 자랑합니다. 이 지역은 활기찬 문화와 역사를
                  반영하는 한국 전통 요리와 세계 각국의 풍미를 특징으로 하는
                  다양하고 흥미로운 식사를 제공합니다. 신선한 농산물과 해산물,
                  다양한 정통 한식을 판매하는 분주한 노점이 있는 하단 5일 시장은
                  이 요리의 활기찬 매력을 더합니다. 사하구에는 시장 외에도 오랜
                  전통을 자랑하는 수많은 식당들이 사하구 곳곳에 흩어져 조용히
                  미식 유산의 자리를 지키고 있어 사하구의 미식 문화는 그 범위를
                  넓혀가고 있습니다. 수십 년 동안 운영해 온 노포들은 정통의 맛과
                  향수가 어우러진 독특한 식사 경험을 제공합니다. 이처럼 사하구
                  미식 여행은 미각의 뿐만 아니라 풍부한 지역 문화와 역사에 대한
                  몰입감 있는 경험을 제공하고 있습니다.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>공동체 정신</h2>
                <p className="align-justify">
                  사하구는 지역 전통과 가치를 반영할 뿐만 아니라 이를 더욱
                  풍요롭게 하는 많은 이니셔티브, 문화 축제 및 커뮤니티 행사에서
                  드러나는 강력한 공동체 정신이 깃들어 있습니다. 미술 전시회,
                  음식 축제, 스포츠 행사 등 이 지역의 활기차고 다양한 문화를
                  담아내는 다양한 지역 전통을 통해 이러한 공동의 정체성과
                  단결심을 더욱 공고히 합니다. 이러한 정신은 조직적인 행사를
                  넘어 주민들이 동네 청소 운동이나 커뮤니티 정원 가꾸기
                  프로젝트와 같은 공동의 일에 적극적으로 참여하여 공유 환경에
                  대한 깊은 헌신을 보여줍니다. 또한 사하구는 지역 모임, 비공식적
                  교류, 교육 활동의 중요한 허브 역할을 하는 커뮤니티 센터와
                  공공장소를 다수 보유하고 있어 사하의 풍부한 문화 유산을
                  공유하고 배우는 것을 장려합니다. 궁극적으로 사하구는 단순한
                  거주지가 아닌 긴밀한 커뮤니티의 모범을 보여주며, 공유된 가치,
                  상호 존중, 강한 소속감을 특징으로 하며, 이는 모두를 포용하고
                  환영하는 환경을 조성하려는 사하구의 탄력적인 성격과 헌신을
                  드러냅니다.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic12.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">다대포 알뜰장터</p>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic13.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">2023년 낙동강 하굿둑 인근 석양</p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                {/* <h2>s</h2> */}
                <h4 className="align-justify italic">
                  사하구는 풍부한 역사, 다양한 문화, 우수한 교육, 활기찬 공동체
                  정신, 미식의 즐거움을 발견할 수 있는 곳으로 여러분을
                  초대합니다. 부산의 사하구의 매혹적인 매력에 빠져보세요.
                </h4>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
