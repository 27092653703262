/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./Greetings.ko";
import En from "./Greetings.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] Greetings
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["mayors_greetings"]}
        breadcrumb={[lang["discover_saha"], lang["mayors_greetings"]]}
        backgroundImage={"resources/discover_saha/0_Mayor_greetings_pic1.jpg"}
        backgroundPosition={"center 55%"}
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default PageIndex;
