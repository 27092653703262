/**
 *
 *
 */

import React from "react";

/**
 * [Component] DistrictHousing
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic2.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>
                  <small>번영하는 미래를 위한 변화 포용</small>
                  <br />
                  15분 내 일상생활이 가능한 ‘살기좋은 사하’
                </h2>
              </div>
              <p className="align-justify">
                사하구는 (1)하단-당리, (2)신평-장림, (3)괴정, (4)감천-구평 등
                4개 권역으로 나뉩니다. 이들 지역은 15분 이내에 모든 생활 필수
                편의시설을 이용할 수 있는 '행복도시 사하'로 개발될 예정입니다.
                <br />
                <br />
                사하구는 전 세계 '15분 도시'의 성공적인 사례를 통해 비전을
                제시하고자 합니다. 이러한 혁신적인 도시 모델은 사람들이 집에서
                도보나 자전거를 타고 가까운 거리에서 필요한 것을 충족할 수 있는
                자급자족이 가능한 지역을 만드는 데 중점을 둡니다. 이러한 설계는
                지속가능성을 촉진하고 삶의 질을 개선하며 강력한 공동체 의식을
                구축하여 지역주민의 만족도를 높이는 것을 목표로 하고 있습니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic3.jpg"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>
                  <small>어린이도서관 건립</small>
                </h2>
              </div>
              <p className="align-justify">
                2023년 4월, 사하구는 다대도서관 내에 대규모 어린이
                복합문화공간을 그랜드 오픈했습니다. 614.31㎥의 넓은 면적에 놀이,
                독서, 디지털 체험, 학습이 조화롭게 어우러진 다양한 활동을
                제공하여 어린이와 부모 모두를 만족시킬 수 있는 공간입니다.
                몰입형 가상현실 스토리텔링과 몰입형 놀이 체험을 할 수 있는
                디지털 학습관, 그림책 읽기와 연계한 코딩 활동을 할 수 있는 전용
                교실, 증강현실(AR) 동화책의 마법을 체험할 수 있는 증강현실(AR)
                존 등이 마련돼 있습니다. 개관 이후 주말에는 매일 1500명 이상의
                방문객이 몰리는 등 압도적인 성공을 거두며 사하구의 소중한
                커뮤니티 자산으로 입지를 굳히고 있습니다
              </p>
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>괴정천 생태 복원 및 대중교통 개선</small>
                </h2>
              </div>
              <p className="align-justify">
                커뮤니티 공간 조성 외에도 주거 하수 처리 등의 문제가 해결하여
                괴정천을 자연 상태의 생태하천으로 복원하는 데도 힘쓰고 있습니다.
                15분 도시라는 개념에는 걷기 좋은 공간뿐만 아니라 대중교통 개선도
                포함됩니다. 사상~하단~녹산선, 송도선 등 도시철도 노선 확충을
                추진하고 있습니다. 제2대티터널 건설도 차질 없이 진행하고
                있습니다.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>역세권 개발 및 청년 창업주택</small>
                </h2>
              </div>
              <p className="align-justify">
                괴정역 등 역세권을 중심으로 복합용도 개발을 추진 중입니다. 주거,
                일자리, 상업, 문화가 공존하는 청년 창업주택을 공급할 계획입니다.
                <br />
                사하구를 더욱 편리하고 문화적으로 풍요로운 삶터로 만들기 위해
                15분 도시 콘셉트에 부합하는 더 많은 계획이 추진 중입니다.
                프로젝트가 진행됨에 따라 더 많은 업데이트를 공유할 수 있기를
                기대합니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic4.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>공공 행정 및 지역 경제 혁신</h2>
              </div>
              <p className="align-justify">
                사하구를 미래 발전의 모델로 만들기 위한 여정을 시작하면서,
                우리는 투명성을 확보하고 계획을 지역사회와 공유하기 위해 최선을
                다하고 있습니다. 전략적 토지 이용 계획, 공공 행정 서비스 강화,
                지역 경제 활성화, 토지 이용 효율성 향상을 중심으로 추진 중인
                구체적인 전략을 알려드립니다.
              </p>
              <br />
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>
                사하구청 신청사 건립: <small>공공 행정 서비스 강화</small>
              </h3>
              <hr />
              <p className="align-justify">
                노후화되고 기능이 저하된 사하구청을 재건축 계획하고 있습니다.
                이를 통해 현대적이고 시설이 잘 갖춰져 있으며 확장하는 지역사회의
                요구에 부응하는 행정 허브를 만드는 것이 목표입니다. 이 계획은
                개보수 뿐만 아니라 확장 계획도 포함하고 있으며, 늘어나는 구청의
                요구 사항을 수용하기 위해 추가 부지를 확보하고 있습니다.
                <br />이 계획은 지역 주민의 접근성과 편의성을 높여 공공 행정
                서비스의 질을 개선하고 더 많은 공공 공간을 제공하기 위해
                마련되었습니다.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>
                대학과 연계한 혁신 지구 조성: <small>지역 경제 활성화</small>
              </h3>
              <hr />
              <p className="align-justify">
                산학협력의 힘을 인식하고 지역 대학, 특히 동아대(하단동)와
                부산보건대(괴정동) 주변 지역에 새로운 활력을 불어넣을
                계획입니다. 이 지역에 혁신 활성화 구역을 조성하고 청년 창업
                프로그램을 지원함으로써 대학과 주변 지역이 공생할 수 있는 환경을
                조성하고자 합니다. 이를 통해 지역 혁신과 경제 활성화를 위한
                지속가능한 모델을 구축하고자 합니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic5.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>전략적 토지 이용 계획을 통한 개발 추진</h2>
              </div>
              <p className="align-justify">
                장기 개발 계획은 토지 이용 계획을 신중하게 수정하여 커뮤니티의
                성장과 미래의 지속 가능성을 촉진하는 균형 잡힌 접근 방식
                중심으로 이루어지고 있습니다.
              </p>
              <br />
              <br />
              <br />
              <br />
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>토지 이용 효율성 향상</h3>
              <hr />
              <p className="align-justify">
                부산 도시계획에 맞춰 하단, 괴정, 감천, 장림 등 중심 지역의 토지
                용도 변경을 추진하고 있습니다. 일부 준주거지역을
                근린상업지역으로 재지정하여 서부산 광역중심의 기반을 강화하고자
                합니다. 용도지역 변경은 토지이용 효율을 최적화하고 우리 지역의
                중심성을 강화하기 위함 입니다
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>지하철 역세권 토지 이용 강화</h3>
              <hr />
              <p className="align-justify">
                다대포해수욕장역, 낫개역, 신장림역 등 주요 지하철역 주변을
                중심으로 토지이용을 강화 하여 지역 활력을 활성화하고 지속가능한
                도시 성장을 도모하고 있습니다. 이들 지역의 잠재력을 주거와 상업
                활동으로 끌어올려 보다 역동적인 사하구를 만드는 데 중점을 두고
                있습니다.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-12 col-md-12">
              <br />
              <br />
              <h3 style={{ color: "#333" }}>
                준공업지역 용도 현실화 및 노후 공장지역 용도 변경
              </h3>
              <hr />
              <p className="align-justify">
                거주민의 생활환경을 개선하기 위해 준공업지역 내 복합용도 및
                부적합 지역을 정비하고 있습니다. 이와 동시에 오래된 공장을 보다
                적합한 지역으로 이전하는 것을 추진하고 있습니다. 이러한 공장이
                주거 환경에 미칠 수 있는 악영향을 완화하고 전반적인 삶의 질을
                개선하는 것이 목표입니다.
                <br />
                이러한 공동의 노력을 통해 구민을 위해 더욱 지속 가능하고 살기
                좋으며 번영하는 사하구를 만들기 위해 노력하고 있습니다. 변화와
                적응을 거듭하며 주민들의 요구와 열망을 최우선으로 생각하며
                사하구민 모두의 밝은 미래를 위해 최선을 다하겠습니다.
                <br />
                <br />
                여러분의 생각, 질문 및 피드백을 항상 환영하며, 지속 가능한
                계획을 추진하며 번영하는 미래를 위한 변화에 함께 준비하겠습니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic6.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
              <br />
              <br />
              <br />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>산책로와 공간 조성을 통한 사하의 매력 강화</h2>
              </div>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>승학산 둘레길 조성</h3>
              <hr />
              <p className="align-justify">
                사하구에서 가장 높은 봉우리인 승학산은 풍부한 자연자원이자 우리
                지역을 상징적으로 대표하는 산입니다.
                <br />
                사하구의 매력을 높이기 위해 구의 상징적 자연자원인 웅장한
                승학산을 중심으로 한 사업을 야심차게 추진하고 있습니다. 현재
                승학산으로 이어지는 산책로가 단절되어 있어 산의 정취를 느끼고자
                하는 보행자들에게 걸림돌이 되고 있다는 점을 인식하고 승학산을
                중심으로 잘 연결되고 연속성 있는 산책로를 조성할 계획입니다.
                <br />
                <br />
                기존 탐방로를 활용하는 것은 물론 접근성, 이용성, 경관 등의
                요소도 고려한 종합적인 탐방로를 조성해 만족스러운 경험을 선사할
                예정입니다. 프로젝트는 등산로 현황에 대한 심층 조사를 시작으로
                계획된 등산로에 대한 자원 매핑, 최적 노선 선정, 발굴된 자원을
                기반으로 한 콘텐츠 개발, 그리고 최종적으로 프로젝트 실행까지
                단계적으로 진행될 예정입니다. 사하구는 승학산의 아름다움을 직접
                체험할 수 있도록 함으로써 주민과 방문객 모두에게 승학산의 매력과
                매력을 증폭시키는 것을 목표로 하고 있습니다.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-6 col-md-12">
              <h3 style={{ color: "#333" }}>
                중심지마실길, 600년회화나무길 조성
              </h3>
              <hr />
              <p className="align-justify">
                우리는 괴정 지역의 독특한 자연과 역사 자원을 활용하여 괴정
                지역을 매력 넘치는 명소로 탈바꿈시키고자 합니다. 사하구의 중심
                거점인 괴정에는 600년 된 회화나무 두 그루와 고즈넉한 공원 등
                특색 있는 명소가 많습니다. 하지만 이러한 명소들 간의 연결성이
                부족하다는 점은 개선이 필요한 부분으로 지적되어 왔습니다.
                <br />
                <br />
                괴정 고유의 자원에 대한 접근성과 활용도를 높일 수 있는
                중심지마실길을 조성해 나갈 것입니다. 이 길은 회화나무를 비롯한
                지역의 특색을 연결하고 괴정의 역사적, 문화적 정체성을 보여줄
                것입니다. 이 프로젝트는 자원 조사, 콘텐츠 개발, 인근 상업
                지역과의 협업, 매력적인 경관 디자인 구현 등 단계적으로 진행될
                예정입니다. 이 프로젝트는 사회-경제적 활력을 불어넣어 지역 내
                명소로서 괴정의 입지를 공고히 할 것으로 기대됩니다.
              </p>
            </div>
            {/* 본문 */}

            {/* 본문 */}
            <div className="col-lg-12 col-md-12">
              <br />
              <br />
              <h3 style={{ color: "#333" }}>사하 바다 둘레길 조성</h3>
              <hr />
              <p className="align-justify">
                사하구의 자연적 매력을 강화하고 지역 사회 참여를 촉진하기 위한
                노력의 일환으로 아름다운 다대포해수욕장과 낙동강 일대를 아우르는
                경치 좋은 해안 산책로를 개발할 계획입니다. 현재 자동차 중심의
                설계로 인해 낙동강변 보행 환경의 한계를 인식하고, 보행 편의성과
                주변 환경의 즐거움을 개선하는 데 초점을 맞추고 있습니다.
                다대포해수욕장에서 낙동강 하구, 을숙도 입구까지 이어지는
                다대로와 강변대로를 주요 대상으로 합니다.
                <br />
                <br />
                단계적 개발을 통해 기존 도로 배치를 보행자 중심으로 재설계하고,
                활기찬 사하바다광장을 조성하며, 그림 같은 산책로와 산책로를
                개발하는 것을 목표로 하고 있습니다. 산책로를 따라 독특한 일몰
                체험 콘텐츠를 개발하는 것도 계획의 일부입니다. 이러한 계획이
                서남권을 대표하는 명품 관광 트레일을 추가할 뿐만 아니라 지역
                경제를 활성화하여 사하를 번영과 활기찬 지역 사회로 강화할
                것이라고 굳게 믿습니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* GRID */}
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12">
              <img
                src="resources/saha_life/1_District_Housing_pic7.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            {/* 이미지 */}
            {/* 본문 */}
            <div className="col-lg-8 col-md-12 mb-12 mb-lg-0">
              <h3 style={{ color: "#333" }}>
                본 계획은 실제 시행과정에서 변경이 있을 수 있습니다.
              </h3>
              <br />
              <p className="align-justify">
                이는 개념적인 표현이며 실제 구현 과정에서 실현 가능성, 재정적
                고려 사항 및 지역 사회 피드백에 따라 변경될 수 있음을 유의하시기
                바랍니다.
              </p>
              <br />
              <br />

              <h3 style={{ color: "#333" }}>
                여러분의 지지와 함께 더욱 살기 좋은 사하를 만들겠습니다.
              </h3>
              <br />
              <p className="align-justify">
                사하구청은 우리 지역을 더욱 살기 좋고, 일하기 좋고, 방문하기
                좋은 곳으로 만들기 위해 최선을 다하고 있습니다. 모두의 더 나은
                삶을 위해 우리 지역을 개선하기 위해 노력하는 사하구청의 노력에
                여러분의 지지와 이해를 부탁드립니다.
              </p>
            </div>
            {/* 본문 */}
          </div>
          {/* GRID */}
        </div>
      </section>
    </>
  );
};

export default Ko;
