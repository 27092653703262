import React, { useState, useEffect } from "react";
import { db } from "firebase.config";
import { setDoc, updateDoc, addDoc, doc } from "firebase/firestore";
import { collection, getDoc, getDocs, get } from "firebase/firestore";

const HeaderTop = () => {
  /* ===== State ===== */
  const [subscribeList, setSubscribeList] = useState("");
  const [email, setEmail] = useState("");
  const [subscribeModal, setSubscribeModal] = useState(false);
  const icons = [
    {
      icon: "fa-linkedin",
      path: "https://www.linkedin.com/company/newsaha",
    },
    {
      icon: "fa-x-twitter", // ✅ 'fa-brands'는 JSX에서 추가
      path: "https://x.com/newsaha2030",
    },
    {
      icon: "fa-threads", // ✅ Threads 추가 (FontAwesome 최신 아이콘 사용)
      path: "https://www.threads.net/@sahadistrictoffice",
    },
    {
      icon: "fa-facebook-f",
      path: "https://www.facebook.com/SahaguOffice",
    },
    {
      icon: "fa-instagram",
      path: "https://www.instagram.com/busan_sahagu",
    },
    {
      icon: "fa-youtube",
      path: "https://www.youtube.com/channel/UCe8iHXVaoZogUcGhZZ5XK1A",
    },
  ];

  const handleSubscribe = async () => {
    try {
      // if (data[email] && data[email] !== undefined) {
      //   alert('이미 구독중인 이메일입니다.');
      //   return;
      // }

      const fdDocument = doc(db, "subscription", email);
      await setDoc(fdDocument, {
        id: email,
        email,
      });

      setSubscribeModal(false);
      setEmail("");
      alert("Subscribed!");
    } catch (e) {
      console.log("E: ", e);
      alert("X");
    }
  };

  useEffect(() => {
    const call = async () => {
      try {
        const fdDocument = collection(db, "subscription");
        const result = await getDocs(fdDocument);
        let list = {};
        result.forEach((doc) => {
          console.log("doc: ", doc.id);
          list[doc.id] = doc.data();
        });

        setSubscribeList(list);
      } catch (e) {
        console.log("Error: ", e);
      }
    };

    call();
  }, []);

  /* ===== Render ===== */
  return (
    <>
      <section className="header-uper" style={{ padding: "20px 0" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-4 col-lg-3">
              <div className="logo">
                <a href="/">
                  <img
                    style={{ width: 175 }}
                    loading="lazy"
                    className="img-fluid"
                    src="logo_png.png"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-8 col-lg-9">
              <div className="right-side">
                {/* <ul className="contact-info pl-0 mb-4 mb-md-0 ">
                  <li className="item text-left">
                    <div className="icon-box">
                      <i className="far fa-envelope"></i>
                    </div>
                    <strong>Email</strong>
                    <br />
                    <a href="mailto:info@medic.com">
                      <span>info@medic.com</span>
                    </a>
                  </li>
                  <li className="item text-left">
                    <div className="icon-box">
                      <i className="fas fa-phone"></i>
                    </div>
                    <strong>Call Now</strong>
                    <br />
                    <span>+ (88017) - 123 - 4567</span>
                  </li>
                </ul> */}
                <div className="link-btn text-center text-lg-right">
                  {/* <a href="contact.html" className="btn-style-one">
                    Appoinment
                  </a> */}
                  {icons.map((item) => (
                    <React.Fragment key={`social-${item.icon}`}>
                      <button
                        className="btn btn-light"
                        style={{
                          borderColor: "#019875",
                          width: 42,
                          height: 39,
                        }}
                      >
                        <a
                          href={item.path}
                          target="_blank"
                          aria-label="facebook"
                          style={{ color: "#019875" }}
                          rel="noreferrer"
                        >
                          <i className={`fab ${item.icon}`}></i>
                        </a>
                      </button>{" "}
                    </React.Fragment>
                  ))}
                  <button
                    className="btn btn-success"
                    style={{
                      background: "#019875",
                      // width: 42,
                      height: 39,
                    }}
                    onClick={() => setSubscribeModal(true)}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {subscribeModal && (
        <>
          <div
            className="fade-in"
            style={{
              background: "rgba(0,0,0,0.75)",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 980,
            }}
            onClick={() => setSubscribeModal(false)}
          ></div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="fade-in"
              style={{
                position: "relative",
                width: "100%",
                maxWidth: 400,
                // top: 50,
                margin: "0 auto",
                zIndex: 999,
                background: "#fff",
                padding: 24,
                boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.35)",
              }}
              onClick={(e) => e.preventDefault()}
            >
              <h4 style={{ marginBottom: 10 }}>Subscribe</h4>

              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your E-mail"
                  style={{ height: 38, marginRight: 5, marginBottom: 0 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />{" "}
                <button
                  class="btn btn-success"
                  type="button"
                  id="button-addon2"
                  onClick={() => handleSubscribe()}
                >
                  Done
                </button>
              </div>
              <p
                style={{
                  margin: 0,
                  marginTop: 10,
                  lineHeight: 1.2,
                  padding: "0 3px",
                  fontSize: "0.85em",
                  color: "#9a9a9a",
                  textAlign: "justify",
                }}
              >
                Enter your email and click OK to receive the latest news from
                Saha through post subscription.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HeaderTop;
