import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getCookie } from "utils";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";
import { BlogList, BlogDetail } from "components/BlogView";
import styled from "styled-components";

const {
  intl: { txt: lang },
} = globalObj;

// ✅ 소개 섹션 스타일
const IntroContainer = styled.div`
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const IntroHeading = styled.h2`
  color: #222;
  font-size: 24px;
  font-weight: bold;
`;

const IntroText = styled.p`
  color: #555;
  font-size: 16px;
`;

// ✅ 소개 컴포넌트
const PageIntroduction = ({ language }) => {
  const introText = {
    EN: {
      heading: "Stay Updated with Saha District's Corporate News",
      text: "Receive newsletters with the latest corporate news and insights from the Saha District.",
    },
    KR: {
      heading: "사하구 기업소식 뉴스레터 받기",
      text: "사하구의 기업소식을 뉴스레터로 받아보세요.",
    },
  };

  const currentLang = introText[language] || introText.EN;

  return (
    <IntroContainer>
      <IntroHeading>{currentLang.heading}</IntroHeading>
      <IntroText>{currentLang.text}</IntroText>
    </IntroContainer>
  );
};

const Newsletters = () => {
  const language = getCookie("country") || "EN"; // 기본값 영어
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { postId } = useParams();
  const [detail, setDetail] = useState(null);

  // ✅ postId가 변경될 때 detail을 업데이트
  useEffect(() => {
    if (postId && !detail) {
      // 데이터를 불러와야 하는 경우
      // API 요청 또는 상태 업데이트 로직을 추가 가능
    }
  }, [postId]);

  const handleChooseData = (item) => {
    setDetail(item);
    navigate(`${pathname}/${item.id}`);
  };

  return (
    <>
      <PageTitle
        title={lang["newsletters"]}
        breadcrumb={[lang["saha_updates"], lang["newsletters"]]}
        backgroundImage={"resources/NewsletterMainpic.jpg"}
        backgroundPosition={"center 40%"}
      />
      <PageIntroduction language={language} />

      {!postId ? (
        <BlogList blogType="newsletter" onChooseData={handleChooseData} />
      ) : (
        <BlogDetail data={detail} blogType="newsletter" postId={postId} />
      )}
    </>
  );
};

export default Newsletters;
