import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form2 from "./Form2"; // Importing the Form2 component

// Elegant styling with styled-components
const Section = styled.section`
  margin: 0;
  padding: 0;
  background-color: #f5f5f4; /* Delicate light gray background */
`;

const ContentWrapper = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 20px;
`;

const Heading = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 18px;
  color: #666;
`;

const ExploreButton = styled.button`
  background-color: #11ab87;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 50%;
  transition:
    background-color 0.3s,
    transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

// KicIntro Component
const KicIntro = ({ language }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowWidth < 768;

  const mobileAdaptationStyle = {
    fontSize: isMobile ? "0.9rem" : "1.0rem",
    padding: isMobile ? "10px" : "20px",
    marginTop: isMobile ? "20px" : "0",
  };
  const headingStyle = {
    fontSize: isMobile ? "1.5rem" : "2.0rem", // Example sizes, adjust as needed
  };

  return (
    <Section className="kic-intro-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contents">
              {language !== "KR" ? (
                <>
                  <ContentWrapper>
                    <Heading style={headingStyle}>
                      Inside a Knowledge Industry Center (KIC)
                    </Heading>
                    <Description style={mobileAdaptationStyle}>
                      Embark on a journey into a Knowledge Industry Center
                      (KIC), a hub hosting diverse sectors like manufacturing,
                      IT, R&D, and startups. Designed as integrated ecosystems,
                      KICs foster creativity and productivity, blending
                      state-of-the-art offices, production facilities, and
                      research labs to harmoniously combine work and life.
                    </Description>
                    <ExploreButton onClick={() => navigate("/kic-overview")}>
                      {language !== "KR" ? "Discover More" : "더 알아보기"}
                    </ExploreButton>
                  </ContentWrapper>
                </>
              ) : (
                <>
                  <ContentWrapper>
                    <Heading style={mobileAdaptationStyle}>
                      지식산업센터(KIC) 소개
                    </Heading>
                    <Description style={mobileAdaptationStyle}>
                      지식산업센터(KIC)는 현대 혁신과 협업의 상징입니다. 각
                      KIC는 제조, 정보 기술, 연구개발, 지식 기반 스타트업 등
                      다양한 분야의 기업을 수용하기 위해 신중하게 설계된 비전
                      있는 공간입니다. 이 센터들은 단순한 인프라가 아니라,
                      창의성, 생산성 및 발전을 촉진하는 통합 생태계로
                      설계되었습니다. 최첨단 사무 공간, 진보된 생산 시설, 최신
                      연구실 및 포괄적인 생활 공간을 갖춘 각 KIC는 일과 생활이
                      조화롭게 어우러져 미래 혁신과 산업 성장의 무대를
                      마련합니다.
                    </Description>
                    <ExploreButton onClick={() => navigate("/kic-overview")}>
                      {language !== "KR" ? "Discover More" : "더 알아보기"}
                    </ExploreButton>
                  </ContentWrapper>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            {/* Form2 component integration */}
            <Form2 />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default KicIntro;
