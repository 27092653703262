/**
 *
 *
 */

import React, { useEffect } from "react";
import { BlogCreateView } from "components/BlogView";
import { useParams } from "react-router-dom";

/**
 * [Component] CreatePost
 * --
 */
const CreatePost = () => {
  /* ===== State ===== */
  const { type } = useParams();
  const getBreadcrumb = (value) => {
    switch (value) {
      case "kic-progress":
        return "KIC지식산업센터 > KIC동향";
      case "news":
        return "Saha뉴스 > 사하뉴스";
      case "newsletter":
        return "Saha뉴스 > 뉴스레터";
      case "download":
        return "Saha뉴스 > 다운로드센터";
      case "CreatePost":
      default:
        return "SAHA라이프 > 생활&교통";
    }
  };
  const breadcrumb = getBreadcrumb(type);
  const isFile = type === "download" ? true : false;
  console.log("type: ", type);
  console.log("isFile: ", isFile);

  /* ===== Hooks ===== */

  /* ===== Render ===== */
  return (
    <>
      <BlogCreateView breadcrumb={breadcrumb} type={type} isFile={isFile} />
    </>
  );
};

export default CreatePost;
