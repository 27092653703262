/**
 *
 *
 */

import React from "react";

/**
 * [Component] Investment Incentives
 * --
 */
const Ko = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <h1>사하구 투자 인센티브</h1>
          <hr />
          <br />
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <img
                src="resources/KIC/kic_ai1.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>
                  <small>사하구에 투자하세요:</small>
                  <br />
                  외국 기업을 위한 재정 지원 및 인센티브
                </h2>
              </div>
              <p>
                사하구는 실질적인 재정 지원과 투자 인센티브를 제공하여 외국인
                투자를 장려하기 위해 노력하고 있습니다. 외국인 투자비율이 30%
                이상이거나 외국인이 주식의 과반수를 보유한 기업 중 업종이
                첨단산업, 산업지원서비스업 또는 부산시 전략산업에 해당하는
                기업에는 다음과 같은 혜택이 제공됩니다.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai2.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>보조금지원 안내</h2>
              </div>

              <div className="row">
                {[
                  [
                    "토지 취득 보조금",
                    "외국인 투자 기업은 토지 매입 가격의 30% 범위 내에서 보조금을 받을 수 있습니다.",
                    "Land Acquisition Subsid",
                  ],
                  [
                    "고용 보조금",
                    "최근 3개월 동안 정규직 근로자를 20명 이상 신규 고용한 외국인 투자기업은 보조금을 받을 수 있습니다. 첨단산업, 산업지원서비스업, 부산시 전략산업의 경우 1인당 최대 50만 원(2억 원 한도)까지 보조금을 지급합니다. 그 외 업종은 1인당 최대 30만 원(1억 원 한도)까지 지원됩니다.",
                    "Employment Subsidy",
                  ],
                  [
                    "교육훈련 보조금",
                    "내국인 근로자 50명 이상을 고용하기 위해 1개월 이상 교육을 실시하는 외국인투자기업은 보조금을 지원받을 수 있습니다. 첨단산업, 산업지원서비스업, 부산시 전략산업의 경우 1인당 최대 50만 원(2억 원 한도)까지 보조금이 지급됩니다. 그 외 업종은 1인당 최대 30만 원(1억 원 한도)까지 지원합니다.",
                    "Training Subsidy",
                  ],
                  [
                    "컨설팅 비용 지원",
                    "외국인 투자비율이 1/3 이상이거나 대주주가 외국인인 경우 컨설팅 비용의 30% 이내(첨단산업, 산업지원서비스, 부산시 전략산업은 최대 2천만 원, 그 외 산업은 최대 1천만 원 한도)에서 보조금을 지원합니다.",
                    "Consulting Fee Support",
                  ],
                ].map((item, index) => (
                  <div
                    className="col-6"
                    key={`iiv-${index}`}
                    style={{ padding: 10 }}
                  >
                    <div
                      style={{
                        padding: 25,
                        background: "#fff",
                        height: "100%",
                        boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <h4 style={{ color: "#333" }}>
                        <small>{item[2]}</small>
                        <br />
                        {item[0]}
                      </h4>
                      <hr />
                      <p>{item[1]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai3.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>세금 인센티브 안내</h2>
              </div>

              <div className="row">
                {[
                  [
                    "세금 면제",
                    "첨단산업, 산업지원서비스업에 해당하거나 외국인투자지역에 입지하는 경우 법인세 및 소득세를 7년간 감면(최초 5년간 100%, 이후 2년간 50%, 외국인투자금액의 70% 한도)받을 수 있습니다. 지방세(취득세, 재산세)의 경우 부산시에서 10년간 감면(최초 7년간 100%, 이후 3년간 50%) 혜택을 제공합니다.",
                    "Tax Exemptions",
                  ],
                  [
                    "국-공유재산 임대료 감면",
                    "외국인투자지역과 국가산업단지의 경우 임대기간 1년 동안 임대료 50%가 적용되며, 토지의 경우 감면율이 50~100%에 달합니다. 산업단지 및 외국인투자지역 내 지방정부 소유 토지의 임대도 동일하게 적용됩니다.",
                    "National/Public Property Lease Fee Reduction",
                  ],
                ].map((item, index) => (
                  <div
                    className="col-6"
                    key={`iiv-${index}`}
                    style={{ padding: 10 }}
                  >
                    <div
                      style={{
                        padding: 25,
                        background: "#fff",
                        height: "100%",
                        boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <h4 style={{ color: "#333" }}>
                        <small>{item[2]}</small>
                        <br />
                        {item[0]}
                      </h4>
                      <hr />
                      <p>{item[1]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* <div style={{ margin: '0 auto', width: '100%', maxWidth: 350 }}>
            <img
              src="resources/KIC/kic_ai4.png"
              width="100%"
              style={{
                borderRadius: 12,
              }}
              alt=""
            />
          </div> */}
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai4.png"
                width="100%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>
                  <small>
                    자세한 내용은 인베스트 코리아와 인베스트 부산에서 확인하실
                    수 있습니다.
                  </small>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {[
              [
                "인베스트 코리아",
                "invest_Korea_logo.png",
                "https://www.investkorea.org/ik-en/index.do",
              ],
              [
                "엔베스트 부산",
                "busan_symbol.png",
                "https://www.investkorea.org/bsn-en/index.do",
              ],
            ].map((item, index) => {
              return (
                <div
                  className="col-4"
                  key={`iiv-${index}`}
                  style={{ padding: 10 }}
                >
                  <div
                    className="hover-opacity"
                    onClick={() => {
                      const openNewWindow = window.open("about:blank");
                      openNewWindow.location.href = item[2];
                    }}
                    style={{
                      padding: 25,
                      background: "#fff",
                      height: "100%",
                      boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <h4 style={{ color: "#333" }}>{item[0]}</h4>
                      <hr />

                      <img
                        src={`resources/KIC/${item[1]}`}
                        width="60%"
                        style={{ borderRadius: 12 }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <iframe
            style={{
              width: "100%",
              height: "100%",
              minHeight: 450,
            }}
            src="https://www.youtube.com/embed/L2QJ93NLQWI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Ko;
