/**
 *
 *
 */

// import React, { useState, useEffect, useCallback } from 'react';
// import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import React from "react";
import { Route, Routes } from "react-router-dom";

import Template from "./Template";
import Home from "./Home";

//import Vision from './Vision';
//import Team from './Team';
import DepartmentOverview from "./DepartmentOverview";
import OrganizationStructure from "./OrganizationStructure";
import InnovationTech from "./InnovationTech";
import InvestmentIncentives from "./InvestmentIncentives";
import KICProgress from "./KICProgress";
import KICOverview from "./KICOverview";
import DistrictHousing from "./DistrictHousing";
import SahaDistrictBulletin from "./SahaDistrictBulletin";

import Greetings from "./Greetings";
import LocationInfo from "./LocationInfo";

import SahaVision from "./SahaVision";
import DevelopmentPlan from "./DevelopmentPlan";
import HistoryCulture from "./HistoryCulture";
import DivisionOverview from "./DivisionOverview";
import Transportation from "./Transportation";
import CreatePost from "./CreatePost";
import InquiryForm from "./InquiryForm";
import HelpfulLinks from "./HelpfulLinks";
//import Announcements from './SahaUpdates/Announcements';
//import PressRelease from './SahaUpdates/PressRelease';
import Newsletters from "./SahaUpdates/Newsletters";
import Downloads from "./SahaUpdates/Downloads";
import SahaEdu from "./SahaEdu";
import Login from "./Login";

import Notfound from "./Notfound";

/**
 * [Component]
 * --
 */
function Routemap() {
  return (
    <>
      <Template>
        <Routes>
          <Route index element={<Home />} />

          <Route path="/saha-vision" element={<SahaVision />} />
          <Route path="/development-plan" element={<DevelopmentPlan />} />
          <Route path="/history-culture" element={<HistoryCulture />} />
          <Route path="/division-overview" element={<DivisionOverview />} />

          {/* 전략사업과 개요 */}
          <Route path="/department-overview" element={<DepartmentOverview />} />
          {/* 인사말 */}
          <Route path="/greetings" element={<Greetings />} />
          {/* 조직구조 */}
          <Route
            path="/organization-structure"
            element={<OrganizationStructure />}
          />
          {/* 혁신 & 기술 */}
          <Route path="/innovation-tech" element={<InnovationTech />} />
          {/* <Route
            path="/lifestyle-transportation"
            element={<InnovationTech />}
          /> */}
          {/* 투자혜택 */}
          <Route
            path="/investment-incentives"
            element={<InvestmentIncentives />}
          />
          {/* 지식산업센터 개요 */}
          <Route path="/kic-overview" element={<KICOverview />} />

          {/* KIC동향 */}
          <Route path="/kic-progress" element={<KICProgress />} />
          <Route path="/kic-progress/:postId" element={<KICProgress />} />

          {/* 지역 & 주거 */}
          <Route path="/district-housing" element={<DistrictHousing />} />
          {/* 라이프스타일 & 교통 */}
          <Route
            path="/lifestyle-transportation"
            element={<Transportation />}
          />
          <Route
            path="/lifestyle-transportation/:postId"
            element={<Transportation />}
          />
          {/* 라이프스타일 & 교통 */}
          <Route path="/saha-edu" element={<SahaEdu />} />
          <Route path="/saha-edu/:postId" element={<SahaEdu />} />

          {/* 위치&세부정보 */}
          <Route
            path="/saha-district-bulletin"
            element={<SahaDistrictBulletin />}
          />
          <Route
            path="/saha-district-bulletin/:postId"
            element={<SahaDistrictBulletin />}
          />
          {/* <Route path="/announcements" element={<Announcements />} />
          <Route path="/press_release" element={<PressRelease />} /> */}
          <Route path="/newsletters" element={<Newsletters />} />
          <Route path="/newsletters/:postId" element={<Newsletters />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/downloads/:postId" element={<Downloads />} />

          {/* 위치&세부정보 */}
          <Route path="/location-info" element={<LocationInfo />} />
          <Route path="/inquiry-form" element={<InquiryForm />} />
          <Route path="/helpful-links" element={<HelpfulLinks />} />

          {/* 위치&세부정보 */}
          <Route path="/posts/create/:type" element={<CreatePost />} />
          {/* 게시글 상세보기 */}
          <Route path="/posts/:postId" element={<Transportation />} />
          {/* Login */}
          <Route path="/auth" element={<Login />} />

          {/* 404 */}
          <Route path="/*" element={<Notfound />} />
        </Routes>
      </Template>
    </>
  );
}

export default Routemap;
