import React, { useEffect, useState } from "react";
import { db } from "firebase.config";
import { setDoc, updateDoc, addDoc, doc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import MyEditor3 from "./MyEditor3";
import moment from "moment";
import { Loading } from "components";
import { useNavigate, useLocation } from "react-router-dom";
import AWS from "aws-sdk";
import { getCookie } from "utils";
import emailjs from "@emailjs/browser";
import queryString from "query-string";
// import { Comment } from 'components/BlogView';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});
const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  region: "ap-northeast-2",
  params: {
    ACL: "public-read",
  },
});
let txt = [];

/**
 * [Component] BlogCreateView1
 * --
 */
const BlogCreateView = ({ breadcrumb, data, type, isFile }) => {
  /* ===== Init ===== */
  const location = useLocation();
  const { postId, mode } = queryString.parse(location.search);
  const isEditMode = postId && mode === "edit" ? true : false;
  const navi = useNavigate();
  const token = getCookie("NEWSAHA_ACCESS_TOKEN");
  const isAuthentication =
    !token || token === null || token === undefined || token === ""
      ? false
      : true;

  const [isPreview, setIsPreview] = useState(false);
  const handlePreview = () => {
    setIsPreview((prev) => !prev);
  };

  /* ===== State ===== */
  const [loadingFlag, setLoading] = useState(false);
  const [subscribeList, setSubscribeList] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    videoLink: "",
    tags: [],
    // type: type, // []
  });
  const [tag, setTag] = useState("");
  const [files, setFiles] = useState([]);

  /* ===== Functions ===== */

  /**
   * onChange
   * --
   */
  const handleChange = (target, value, files = null) => {
    setFormData({
      ...formData,
      [target]: value,
    });
    if (files) {
      setFiles(files);
    }
  };

  /**
   *
   * --
   */
  const sendEmail = (data, id) => {
    const { title } = data;
    let t;
    switch (type) {
      case "edu":
        t = "saha-edu";
        break;
      case "news":
        t = "saha-district-bulletin";
        break;
      case "download":
        t = "downloads";
        break;
      case "transportation":
      case "kic-progress":
      case "newsletter":
      default:
        t = type;
        break;
    }

    const params = {
      post_title: title,
      send_email: subscribeList,
      link: `https://newsaha.net/#/${t}/${id}`,
    };
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_1,
        params,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  /**
   * 저장 함수
   * --
   */
  const handleSave = async () => {
    // handleSaveFiles();
    // setLoading(false);
    // return;

    setLoading(true);
    try {
      // console.log('formData: ', formData);
      // return;

      const { title, content } = formData;
      if (!title || title === "" || !content || content === "") {
        alert("모든 내용을 입력해주세요.");
        return;
      }

      const fFiles = files.map((file) => ({
        id: file.id,
        originalName: file.originalName,
        size: file.size,
        exp: file.exp,
      }));

      /* 
      addDoc: 아이디 임의생성
      setDoc: 아이디 직접생성
      */
      //  등록모드
      if (isEditMode === false) {
        const fdDocument = collection(db, "board", type, "datas");
        const result = await addDoc(fdDocument, {
          ...formData,
          date: moment().format("YYYY-MM-DD HH:mm:ss"),
          files: fFiles,
        });

        if (type === "download" && files.length > 0) {
          handleSaveFiles();
        }
        sendEmail(formData, result.id);
      }
      // 수정모드
      else {
        const fdDocument = doc(db, "board", type, "datas", postId);
        const result = await setDoc(
          fdDocument,
          {
            ...formData,
            updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            files: fFiles,
          },
          { merge: true },
        );
      }
      setLoading(false);
      navi(-1);
    } catch (e) {
      setLoading(false);
      console.log("E: ", e);
      alert("X");
    }
  };

  /**
   * 파일 일괄 저장
   * --
   */
  const handleSaveFiles = async () => {
    setLoading(true);
    const leng = files.length - 1;
    let index = 0;

    for (let file of files) {
      const { id, body } = file;
      const params = {
        ACL: "public-read",
        Body: body,
        Bucket: "store.newsaha",
        Key: `download/${id}`,
        ContentType: body.type,
        // Key: `download/${file.name}`,
      };

      // S3 Push
      s3.putObject(params, async (err, data) => {
        if (err) {
          console.log("err: ", err);
          alert("파일을 업로드 할 수 없습니다.");
        } else {
          // 초기화
          if (leng === index) {
            alert("업로드되었습니다.");
            setLoading(false);
          }
          index = index + 1;
        }
      });
    } // for
  };

  /**
   * 태그 추가 함수
   * --
   */
  const handleAddTag = (removeIndex = null) => {
    const { tags } = formData;
    if (removeIndex === null) {
      handleChange("tags", [...tags, tag]);
      setTag("");
    } else {
      handleChange("tags", [
        ...tags.slice(0, removeIndex),
        ...tags.slice(removeIndex + 1),
      ]);
    }
  };
  //
  const handleTagChange = (newTags) => {
    setFormData((prevFormData) => ({ ...prevFormData, tags: newTags }));
  };

  /* ===== Hooks ===== */
  /**
   *
   */
  useEffect(() => {
    const call = async () => {
      try {
        const fdDocument = collection(db, "board", type, "datas");
        const result = await getDocs(fdDocument);
        let newData = null;

        result.forEach((item) => {
          if (postId === item.id) {
            newData = {
              ...item.data(),
              id: item.id,
            };
          }
        });
        console.log("newData: ", newData);

        setFormData({
          title: newData.title,
          content: newData.content,
          date: newData.date,
          tags: newData.tags,
          videoLink: newData.videoLink ? newData.videoLink : "",
        });
      } catch (e) {
        console.log("Error: ", e);
      }
    };

    call();
  }, [postId]);
  /**
   *
   */
  useEffect(() => {
    const call = async () => {
      try {
        const fdDocument = collection(db, "subscription");
        const result = await getDocs(fdDocument);
        let list = [];
        result.forEach((doc) => {
          list.push(doc.id);
        });

        setSubscribeList(list);
      } catch (e) {
        console.log("Error: ", e);
      }
    };

    call();
  }, []);

  /* ===== Render ===== */
  return (
    isAuthentication && (
      <>
        <Loading visible={loadingFlag} />
        <section
          className="blog-section section style-four style-five"
          style={{
            paddingTop: 30,
          }}
        >
          <div className="container" style={{ maxWidth: 1150 }}>
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-holder">
                    <div
                      className="content-text"
                      style={{
                        padding: "10px 0 0 0 ",
                      }}
                    >
                      {/* 제목 */}
                      <div className="mb-3">
                        {breadcrumb && (
                          <h3>
                            <small style={{ fontSize: "0.5em" }}>
                              {breadcrumb}
                            </small>
                            <br />새 게시글 작성
                          </h3>
                        )}
                        <hr />

                        {/* === 제목 === */}
                        <label for="exampleInputEmail1" className="form-label">
                          제목
                        </label>

                        <input
                          id="exampleInputEmail1"
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          value={formData.title}
                          onChange={(e) =>
                            handleChange("title", e.target.value)
                          }
                        />
                      </div>

                      {/* === 내용 === */}
                      <div className="mb-3">
                        <MyEditor3
                          value={formData.content}
                          fileList={files}
                          isFile={isFile}
                          onChange={(content) =>
                            handleChange("content", content)
                          }
                          onChangeFiles={setFiles}
                          onTagChange={handleTagChange}
                        />
                      </div>

                      {/* === 태그 === */}
                      <div className="mb-3">
                        <label for="exampleInputEmail1" className="form-label">
                          {"Tags"}
                        </label>

                        <div
                          style={{
                            display: "flex",
                            marginTop: 8,
                          }}
                        >
                          {!formData.tags || formData.tags.length < 1 ? (
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                color: "#d1d1d1",
                              }}
                            >
                              No tags registered.
                            </p>
                          ) : (
                            formData.tags.map((item, itemIndex) => (
                              <div
                                key={item}
                                style={{
                                  padding: "0 12px",
                                  paddingLeft: 18,
                                  marginRight: 2.5,
                                  // borderRadius: '100%',
                                  borderRadius: 100,
                                  background: "#f1f1f1",
                                }}
                              >
                                {item}{" "}
                                <button
                                  style={{ marginLeft: 5 }}
                                  className="btn btn-sm"
                                  onClick={() => handleAddTag(itemIndex)}
                                >
                                  X
                                </button>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      {/* 태그목록 */}
                      <div></div>
                    </div>
                  </div>
                  {/* <Comment /> */}
                </div>
              </div>

              <div
                style={{
                  // position: 'fixed',
                  bottom: 0,
                  width: "100%",
                  // borderTop: '1px solid #6a6a6a',
                  padding: "15px 0",
                  zIndex: 990,
                }}
              >
                <div
                  className="container"
                  style={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p style={{ margin: 0 }}>
                      등록을 눌러 게시글 등록을 완료해주세요
                    </p>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={handlePreview}
                    >
                      미리보기
                    </button>{" "}
                    {/* <button className="btn btn-light">미리보기</button>{' '} */}
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleSave}
                    >
                      게시글 등록
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        {/* == === */}
      </>
    )
  );
};

/* DF */
BlogCreateView.defaultProps = {
  breadcrumb: null,
  data: {
    id: null,
    title: null,
    content: null,
    date: null,
  },
};
export default BlogCreateView;
