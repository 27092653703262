/**
 *
 *
 */

import React from "react";

/**
 * [Component] Investment Incentives
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          {/* <h1>사하구 투자 인센티브</h1>
          <hr /> */}
          <br />
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <img
                src="resources/KIC/kic_ai1.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>
                  Invest in Saha:
                  <br />
                  <small>
                    Financial Support and Incentives for Foreign Enterprises
                  </small>
                </h2>
              </div>
              <p>
                Saha District is committed to encouraging foreign investment by
                providing substantial financial support and investment
                incentives. The following benefits are available to businesses
                where the foreign investment ratio is over 30% or the majority
                of shares are held by foreigners, and whose industry falls under
                high-tech industries, industrial support services, or Busan's
                strategic industries.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai2.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>Subsidies</h2>
              </div>

              <div className="row">
                {[
                  [
                    "토지 취득 보조금",
                    "The foreign investment company is eligible for a subsidy within 30% of the land purchase price.",
                    "Land Acquisition Subsid",
                  ],
                  [
                    "고용 보조금",
                    `Foreign investment companies who have employed over 20 new full-time workers in the last three months are eligible for a subsidy. For high-tech industries, industrial support services, or Busan's strategic industries, the subsidy is up to KRW 500,000 per person (with a limit of KRW 200 million). For other industries, the subsidy is up to KRW 300,000 per person (with a limit of KRW 100 million).`,
                    "Employment Subsidy",
                  ],
                  [
                    "교육훈련 보조금",
                    `Foreign investment companies that conduct more than one month of training to employ over 50 domestic workers are eligible for a subsidy. For high-tech industries, industrial support services, or Busan's strategic industries, the subsidy is up to KRW 500,000 per person (with a limit of KRW 200 million). For other industries, the subsidy is up to KRW 300,000 per person (with a limit of KRW 100 million).`,
                    "Training Subsidy",
                  ],
                  [
                    "컨설팅 비용 지원",
                    `If the foreign investment ratio is over 1/3 or the majority shareholder is foreign, a subsidy is provided within 30% of the consulting fee (up to KRW 20 million for high-tech industries, industrial support services, and Busan's strategic industries, and up to KRW 10 million for other industries).`,
                    "Consulting Fee Support",
                  ],
                ].map((item, index) => (
                  <div
                    className="col-6"
                    key={`iiv-${index}`}
                    style={{ padding: 10 }}
                  >
                    <div
                      style={{
                        padding: 25,
                        background: "#fff",
                        height: "100%",
                        boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <h4 style={{ color: "#333" }}>
                        {/* <small>{item[2]}</small>
                        <br /> */}
                        {item[2]}
                      </h4>
                      <hr />
                      <p>{item[1]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai3.png"
                width="95%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>Tax Incentives</h2>
              </div>

              <div className="row">
                {[
                  [
                    "세금 면제",
                    `If your business falls under high-tech industries, industrial support services, or is located in the foreign investment area, you are eligible for corporate and income tax exemptions for 7 years (100% for the first 5 years and 50% for the next 2 years, limited to 70% of the foreign investment). For local taxes (acquisition tax, property tax), Busan City offers 10 years of tax exemptions (100% for the first 7 years and 50% for the next 3 years).`,
                    "Tax Exemptions",
                  ],
                  [
                    "국-공유재산 임대료 감면",
                    `For foreign investment areas and national industrial complexes, a lease rate of 1% applies for a lease term of 50 years, with a reduction rate of 50% to 100% for land. The same applies for the leasing of local government-owned land within industrial complexes and foreign investment areas.`,
                    "National/Public Property Lease Fee Reduction",
                  ],
                ].map((item, index) => (
                  <div
                    className="col-6"
                    key={`iiv-${index}`}
                    style={{ padding: 10 }}
                  >
                    <div
                      style={{
                        padding: 25,
                        background: "#fff",
                        height: "100%",
                        boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <h4 style={{ color: "#333" }}>
                        {/* <small>{item[2]}</small>
                        <br /> */}
                        {item[2]}
                      </h4>
                      <hr />
                      <p>{item[1]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          {/* <div style={{ margin: '0 auto', width: '100%', maxWidth: 350 }}>
            <img
              src="resources/KIC/kic_ai4.png"
              width="100%"
              style={{
                borderRadius: 12,
              }}
              alt=""
            />
          </div> */}
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/KIC/kic_ai4.png"
                width="100%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>
                  <small>
                    For more details, please refer to Invest Korea or Invest
                    Busan
                  </small>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            {[
              [
                "인베스트 코리아",
                "invest_Korea_logo.png",
                "https://www.investkorea.org/ik-en/index.do",
              ],
              [
                "엔베스트 부산",
                "busan_symbol.png",
                "https://www.investkorea.org/bsn-en/index.do",
              ],
            ].map((item, index) => {
              return (
                <div
                  className="col-4"
                  key={`iiv-${index}`}
                  style={{ padding: 10 }}
                >
                  <div
                    className="hover-opacity"
                    onClick={() => {
                      const openNewWindow = window.open("about:blank");
                      openNewWindow.location.href = item[2];
                    }}
                    style={{
                      padding: 25,
                      background: "#fff",
                      height: "100%",
                      boxShadow: "1px 1px 6px 0 rgba(0,0,0,0.2)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      {/* <h4 style={{ color: '#333' }}>{item[0]}</h4>
                      <hr /> */}

                      <img
                        src={`resources/KIC/${item[1]}`}
                        width="60%"
                        style={{ borderRadius: 12 }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <iframe
            style={{
              width: "100%",
              height: "100%",
              minHeight: 450,
            }}
            src="https://www.youtube.com/embed/L2QJ93NLQWI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default En;
