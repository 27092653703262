/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./DevelopmentPlan.ko";
import En from "./DevelopmentPlan.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] DevelopmentPlan
 * --
 */
const DevelopmentPlan = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  // return <En />;
  return (
    <>
      <PageTitle
        title={lang["development_plan"]}
        breadcrumb={[lang["why_saha"], lang["development_plan"]]}
        backgroundImage={"resources/WhySaha/2_DevelopmentPlan_pic1.jpg"}
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default DevelopmentPlan;
