/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./InnovationTech.ko";
import En from "./InnovationTech.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] PageSample
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["innovation_tech"]}
        breadcrumb={[lang["why_saha"], lang["innovation_tech"]]}
        backgroundImage={
          "resources/strategic_business_division/2_InnovationTech_pic1.jpg"
        }
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default PageIndex;
