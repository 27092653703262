import React, { useEffect, useState, useMemo } from "react";
import { db } from "firebase.config";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { Loading } from "components";
import { getCookie } from "utils";
import moment from "moment";
import BlogItem from "./BlogItem";
import styled from "styled-components";

const ITEMS_PER_PAGE = 6;
const PAGE_GROUP_SIZE = 5; // 한 번에 표시할 페이지 개수

/** 섹션 제목 스타일 */
const SectionTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #11ab87;
  display: inline-block;
`;

/** 게시글 작성 버튼 */
const CreatePostButton = styled.button`
  background-color: #ff9800;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    background: #e68900;
  }
`;

/** 전체 컨테이너 스타일 */
const PostContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  padding: 20px 0;
`;

/** 개별 포스트 아이템 스타일 */
const PostItem = styled.div`
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

/** 빠른 이동 버튼 스타일 */
const QuickLinkButton = styled(Link)`
  background-color: #11ab95;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  transition:
    background-color 0.3s,
    transform 0.2s;
  display: inline-block;
  margin: 5px;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }

  &:active {
    background-color: #077a57;
  }
`;

/** 페이지네이션 버튼 스타일 */
const PaginationButton = styled.button`
  background: ${(props) => (props.active ? "#11ab87" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border: 1px solid #11ab87;
  padding: 8px 12px;
  margin: 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;

  &:hover {
    background: #11ab87;
    color: #fff;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

/** 블로그 리스트 컴포넌트 */
const BlogList = ({
  blogType = "download",
  onChooseData = () => {},
  viewType = "grid",
}) => {
  const navigate = useNavigate();
  const token = getCookie("NEWSAHA_ACCESS_TOKEN");
  const isAuthenticated = !!token;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const today = useMemo(() => moment(), []);
  const currentYear = today.year();
  const currentMonth = today.month();
  const [currentPage, setCurrentPage] = useState(1);

  /** Firestore 데이터 가져오기 */
  useEffect(() => {
    const fetchBlogData = async () => {
      setIsLoading(true);
      try {
        const docRef = collection(db, "board", blogType, "datas");
        const snapshot = await getDocs(docRef);

        const formattedData = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
            date: doc.data().date || moment().format(),
          }))
          .sort((a, b) => moment(b.date).unix() - moment(a.date).unix());

        setData(formattedData);
      } catch (e) {
        console.error("Error fetching blog data:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogData();
  }, [blogType]);

  /** 현재 연도 + 현재 월 기사 */
  const currentMonthPosts = useMemo(
    () =>
      data.filter(
        (item) =>
          moment(item.date).year() === currentYear &&
          moment(item.date).month() === currentMonth,
      ),
    [data, currentYear, currentMonth],
  );

  /** 기타(이전 기사) */
  const otherPosts = useMemo(
    () =>
      data.filter(
        (item) =>
          moment(item.date).year() !== currentYear ||
          moment(item.date).month() !== currentMonth,
      ),
    [data, currentYear, currentMonth],
  );

  /** 페이지네이션 */
  const totalPages = Math.ceil(otherPosts.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const itemsForCurrentPage = otherPosts.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  /** 사용자 친화적인 페이지네이션 로직 */
  const getPageNumbers = () => {
    let pages = new Set();
    const startPage =
      Math.floor((currentPage - 1) / PAGE_GROUP_SIZE) * PAGE_GROUP_SIZE + 1;
    const endPage = Math.min(startPage + PAGE_GROUP_SIZE - 1, totalPages);

    pages.add(1);
    if (startPage > 2) pages.add("...");
    for (let i = startPage; i <= endPage; i++) pages.add(i);
    if (endPage < totalPages - 1) pages.add("...");
    pages.add(totalPages);

    return Array.from(pages);
  };

  /** 부모 컴포넌트에서 onChooseData가 전달되지 않은 경우의 처리 */
  if (typeof onChooseData !== "function") {
    console.warn(
      "Warning: onChooseData prop is not a function. Make sure to pass a valid function to handle data selection.",
    );
  }

  return (
    <React.Fragment>
      <section
        className="blog-section style-four section"
        style={{ paddingTop: 55 }}
      >
        <div className="container">
          {/* 게시글 작성 버튼 (로그인한 경우만 보이게) */}
          {isAuthenticated && (
            <div className="text-center">
              <CreatePostButton
                onClick={() => navigate(`/posts/create/${blogType}`)}
              >
                + 게시글 작성
              </CreatePostButton>
            </div>
          )}

          <div className="d-flex flex-wrap justify-content-center mb-4">
            {[
              { title: "Saha Updates", path: "/saha-district-bulletin" },
              { title: "Saha Newsletters", path: "/newsletters" },
              { title: "Discover SAHA", path: "/lifestyle-transportation" },
              { title: "SAHA KICs", path: "/kic-progress" },
              { title: "Saha Education", path: "/saha-edu" },
            ].map((link) => (
              <QuickLinkButton key={link.path} to={link.path}>
                {link.title}
              </QuickLinkButton>
            ))}
          </div>

          {isLoading ? (
            <Loading position="relative" visible={isLoading} />
          ) : (
            <>
              {currentMonthPosts.length > 0 && (
                <>
                  <SectionTitle>
                    {moment().format("MMMM YYYY")} Articles
                  </SectionTitle>
                  <PostContainer>
                    {currentMonthPosts.map((item) => (
                      <PostItem key={item.id}>
                        <BlogItem
                          item={item}
                          onChooseData={onChooseData}
                          type="default"
                        />
                      </PostItem>
                    ))}
                  </PostContainer>
                </>
              )}

              <SectionTitle>Past Articles</SectionTitle>
              <PostContainer>
                {itemsForCurrentPage.map((item) => (
                  <PostItem key={item.id}>
                    <BlogItem
                      item={item}
                      onChooseData={onChooseData}
                      type="default"
                    />
                  </PostItem>
                ))}
              </PostContainer>
            </>
          )}

          {totalPages > 1 && (
            <div className="pagination-container d-flex justify-content-center mt-4">
              <PaginationButton
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </PaginationButton>

              {getPageNumbers().map((page, index) => (
                <PaginationButton
                  key={index}
                  active={page === currentPage}
                  onClick={() =>
                    typeof page === "number" && setCurrentPage(page)
                  }
                  disabled={page === "..."}
                >
                  {page}
                </PaginationButton>
              ))}

              <PaginationButton
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </PaginationButton>
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(BlogList);
