/**
 *
 *
 */

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BUCKET_URL = process.env.REACT_APP_BASE_URL;

/**
 * 쿠키찾기
 * --
 */
export const getCookie = (name, options = null) => {
  const value = window.document.cookie.match(
    "(^|;) ?" + name + "=([^;]*)(;|$)",
  );
  return value ? value[2] : null;
};

/**
 * 쿠키저장
 */
export const setCookie = (name, value, callback = false) => {
  window.document.cookie = `${name}=${value}; path=/`;
  if (callback) callback();
};

/**
 * 쿠키삭제
 */
export const removeCookie = (name, value, d) => {
  const t = new Date();
  d = d === "" ? "" : "; expires=" + t.setDate(t.getDate() + d);

  document.cookie =
    name +
    "=" +
    escape(value) +
    "; path=/" +
    (d ? "; expires=" + t.toGMTString() : "");
};

/**
 * 쿠키삭제
 */
export const deleteCookie = (name, { path, domain }) => {
  if (getCookie(name)) {
    window.document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
};

/**
 * 로그아웃
 * --
 */
export const handleLogout = () => {
  window.document.cookie = `WRTC_ACCESS_TOKEN=;path=/;domain=localhost;;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  window.document.cookie = `WRTC_TEAM_NO=;path=/;domain=localhost;;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  window.document.cookie = `WRTC_PROJECT_NO=;path=/;domain=localhost;;expires=Thu, 01 Jan 1970 00:00:01 GMT`;

  window.location.href = "/";
};

/**
 * guid 생성기
 * --
 */
export const guidGenerator = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

/**
 * API파라미터 Replace함수
 * --
 */
export const parameterToPath = (path, params = null) => {
  if (!params) {
    return path;
  }
  const keys = Object.keys(params);
  let newStr = "";
  for (let key of keys) {
    newStr = path.replace(`:${key}`, params[key]);
  }
  return newStr;
};

/**
 * 연락처 포맷 만들기
 * --
 */
export const stringToPhone = (value, splitUnit = "-") => {
  const length = value.length;
  const cutIndex = Math.round(length / 3);
  return `${value.slice(0, 3)}${splitUnit}${value.slice(
    3,
    3 + cutIndex,
  )}${splitUnit}${value.slice(3 + cutIndex)}`;
};

/**
 * 클립보드 복사
 * --
 */
export const copyLink = (value = null, cb = null) => {
  const someData = value;

  let tempInput = document.createElement("input");
  tempInput.value = someData;

  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);

  if (cb) cb();
};
