import React, { useEffect, useState } from "react";
import { db } from "firebase.config";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { Loading } from "components";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { getCookie } from "utils";
import { useLocation } from "react-router-dom";

const ses = new AWS.SES();
console.log("ses: ", ses);

const BlogDetail = ({ postId, data, blogType }) => {
  /* ===== State & Navigation ===== */
  const navi = useNavigate();
  const location = useLocation();
  const backToListPath = location.pathname.split("/").slice(0, -1).join("/");
  const token = getCookie("NEWSAHA_ACCESS_TOKEN");
  const isAuthentication = token ? true : false;

  const [loadingFlag, setLoading] = useState(false);
  const [data2, setData2] = useState(null);
  const [chooseFile, setChooseFile] = useState(null);

  /* ===== Functions ===== */
  const handleRemove = async () => {
    if (!window.confirm("게시글이 영구삭제됩니다. 진행하시겠습니까?")) {
      return;
    }
    setLoading(true);
    try {
      const fdDocument = doc(db, "board", blogType, "datas", postId);
      await deleteDoc(fdDocument);

      alert("게시글이 삭제되었습니다.");
      navi(-1);
    } catch (e) {
      console.log("[BlogDetail] handleRemove Error: ", e);
    }
    setLoading(false);
  };

  /* ===== useEffect ===== */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fdDocument = collection(db, "board", blogType, "datas");
        const result = await getDocs(fdDocument);
        let newData = null;

        result.forEach((item) => {
          if (postId === item.id) {
            newData = { ...item.data(), id: item.id };
          }
        });

        setData2(newData);
      } catch (e) {
        console.log("[BlogDetail] useEffect Error: ", e);
      }
      setLoading(false);
    };

    window.scrollTo(0, 0);
    if (!data) fetchData();
  }, [postId, blogType]); // 🔹 의존성 배열에 blogType 추가

  const post = data || data2;
  const videoLink = post?.videoLink?.split("/");

  return (
    <>
      <Loading visible={loadingFlag} />
      <section
        className="blog-section section style-four style-five"
        style={{ paddingTop: 30 }}
      >
        <div className="container" style={{ maxWidth: 1150 }}>
          {isAuthentication && (
            <div>
              <button
                className="btn btn-outline-danger"
                onClick={() =>
                  navi(`/posts/create/${blogType}?postId=${postId}&mode=edit`)
                }
              >
                수정
              </button>{" "}
              <button className="btn btn-danger" onClick={handleRemove}>
                삭제
              </button>
            </div>
          )}

          <button
            className="btn btn-outline-primary"
            onClick={() => navi(backToListPath)}
          >
            Back to List
          </button>

          <div className="row">
            <div className="col-lg-12">
              <div className="item-holder">
                {post && (
                  <div
                    className="content-text"
                    style={{ padding: "40px 0 0 0" }}
                  >
                    <h1>{post.title}</h1>

                    <div style={{ display: "flex", marginTop: 8 }}>
                      {post.tags?.length ? (
                        post.tags.map((item) => (
                          <div
                            key={item}
                            style={{
                              padding: "0 12px",
                              marginRight: 2.5,
                              borderRadius: 100,
                              background: "#f1f1f1",
                            }}
                          >
                            {item}
                          </div>
                        ))
                      ) : (
                        <p style={{ margin: 0, padding: 0, color: "#d1d1d1" }}>
                          등록된 태그가 없습니다.
                        </p>
                      )}
                    </div>

                    <hr />
                    <span>{post.date || "----:--:--"}</span>
                    <hr />

                    {videoLink && (
                      <div style={{ width: "100%", textAlign: "center" }}>
                        {post.videoLink && (
                          <iframe
                            width="740"
                            height="385"
                            src={`https://www.youtube.com/embed/${videoLink[videoLink.length - 1]}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    )}

                    <div
                      className="blogContent"
                      style={{ minHeight: 300 }}
                      dangerouslySetInnerHTML={{ __html: post.content }}
                    ></div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr />
          <ul className="list-group" style={{ marginTop: 10 }}>
            {post?.files?.map((file) => {
              const size = (file.size / 1024).toFixed(0);
              const displaySize =
                size >= 1000 ? `${(size / 1000).toFixed(2)}MB` : `${size}KB`;

              return (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={file.id}
                >
                  <div className="ms-2 me-auto">
                    {file.originalName}
                    <div className="fw-bold">{displaySize}</div>
                  </div>
                  <span className="badge rounded-pill">
                    <button
                      className="btn btn-light btn-md"
                      onClick={() => setChooseFile(file)}
                    >
                      Preview
                    </button>{" "}
                    <a
                      target="_blank"
                      href={`https://s3.ap-northeast-2.amazonaws.com/store.newsaha/download/${file.id}`}
                      rel="noopener noreferrer"
                    >
                      <button className="btn btn-light btn-md">Download</button>
                    </a>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      {chooseFile && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0.35)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 888,
          }}
        >
          <div
            style={{
              padding: 0,
              width: "90%",
              background: "#fff",
              minHeight: 450,
              height: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: "10px 25px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h5>Preview</h5>
              <button
                className="btn btn-outline-dark"
                onClick={() => setChooseFile(null)}
              >
                Close
              </button>
            </div>
            <embed
              src={`https://s3.ap-northeast-2.amazonaws.com/store.newsaha/download/${chooseFile.id}`}
              type={`application/${chooseFile.type || "pdf"}`}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

BlogDetail.defaultProps = {
  data: { id: null, title: null, content: null, date: null },
};

export default BlogDetail;
