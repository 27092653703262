/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./HistoryCulture.ko";
import En from "./HistoryCulture.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] HistoryCulture
 * --
 */
const HistoryCulture = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  // return <En />;
  return (
    <>
      <PageTitle
        title={lang["history_culture"]}
        breadcrumb={[lang["why_saha"], lang["history_culture"]]}
        backgroundImage={"resources/WhySaha/3_HistoryCulture_pic1.jpg"}
        backgroundPosition="center 75%"
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default HistoryCulture;
