import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "components/Loading";
import menusData from "./menusData";
import useWindowDimensions from "./useWindowDimensions";
import HeaderNavStyles from "./HeaderNavStyles";
import MobileMenu from "./MobileMenu"; // Include MobileMenu component

const HeaderNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [sideMenu, setSideMenu] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [megaMenuVisible, setMegaMenuVisible] = useState(false);

  const handleSubMenuClick = useCallback(
    (path) => {
      navigate(path);
      setSideMenu(false);
    },
    [navigate],
  );

  useEffect(() => {
    setLoadingFlag(true);
    const call = () => {
      window.scrollTo(0, 0);
      setSideMenu(false);
      setTimeout(() => {
        setLoadingFlag(false);
      }, 350);
    };
    call();
  }, [pathname]);

  const handleMenuItemMouseEnter = useCallback(() => {
    setMegaMenuVisible(true);
  }, []);

  const handleMenuItemMouseLeave = useCallback(() => {
    setMegaMenuVisible(false);
  }, []);

  return (
    <>
      <Loading visible={loadingFlag} />

      {/* ✅ 데스크탑에서는 모바일 메뉴 버튼을 숨김 */}
      {width < 993 && (
        <div style={HeaderNavStyles.sideMenuButton}>
          <button
            className={`btn ${sideMenu ? "btn-dark" : "btn-success"}`}
            style={HeaderNavStyles.sideMenuButton}
            onClick={() => setSideMenu(!sideMenu)}
          >
            {sideMenu ? "Close" : "MENU"}
          </button>
        </div>
      )}

      {sideMenu && width < 993 && (
        <MobileMenu
          sideMenu={sideMenu}
          setSideMenu={setSideMenu}
          handleSubMenuClick={handleSubMenuClick}
        />
      )}

      <nav
        className="navbar navbar-expand-lg navbar-dark"
        style={width < 993 ? HeaderNavStyles.navbarDesktop : {}}
      >
        <div
          className="container"
          style={width >= 993 ? HeaderNavStyles.navbarDesktopContainer : {}}
        >
          <div className="collapse navbar-collapse" id="navbarLinks">
            <ul className="navbar-nav">
              {menusData.map((menu) => (
                <li
                  className={`nav-item ${menu.submenus.length < 1 ? "active" : "dropdown"}`}
                  key={menu.id}
                  onMouseEnter={handleMenuItemMouseEnter}
                  onMouseLeave={handleMenuItemMouseLeave}
                >
                  <button
                    className={`nav-link ${menu.submenus.length > 0 ? "dropdown-toggle" : ""}`}
                    role={menu.submenus.length > 0 ? "button" : ""}
                    data-toggle={menu.submenus.length > 0 ? "dropdown" : ""}
                    aria-haspopup={menu.submenus.length > 0 ? "true" : "false"}
                    aria-expanded={menu.submenus.length > 0 ? "false" : "true"}
                    onClick={
                      menu.submenus.length < 1
                        ? () => handleSubMenuClick(menu.path)
                        : undefined
                    }
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                  >
                    {menu.title}
                  </button>
                  {menu.submenus.length > 0 && (
                    <div
                      style={{
                        ...HeaderNavStyles.megaMenu,
                        display: megaMenuVisible ? "block" : "none",
                      }}
                    >
                      {menu.submenus.map((submenu) => (
                        <div
                          key={submenu.id}
                          style={HeaderNavStyles.megaMenuItem}
                        >
                          <button
                            onClick={() => handleSubMenuClick(submenu.path)}
                            style={HeaderNavStyles.megaMenuButton}
                          >
                            {submenu.title}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HeaderNav;
