import { useEffect, useRef } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faXTwitter,
  faInstagram,
  faLinkedin,
  faThreads,
  faYoutube,
  faBloggerB,
} from "@fortawesome/free-brands-svg-icons";

// ✅ 미리보기 지원되는 SNS
const previewSNS = [
  { name: "Facebook", url: "https://www.facebook.com/SahaguOffice", icon: faFacebook },
  { name: "X (formerly Twitter)", url: "https://twitter.com/newsaha2030", icon: faXTwitter },
  { name: "Instagram", url: "https://www.instagram.com/busan_sahagu/", icon: faInstagram },
];

// ✅ 미리보기가 없는 SNS
const smallSNS = [
  { name: "LinkedIn", url: "https://www.linkedin.com/company/newsaha", icon: faLinkedin },
  { name: "Threads", url: "https://www.threads.net/@sahadistrictoffice", icon: faThreads },
  { name: "YouTube", url: "https://www.youtube.com/channel/UCe8iHXVaoZogUcGhZZ5XK1A", icon: faYoutube },
  { name: "Naver Blog", url: "https://blog.naver.com/sahablog", icon: faBloggerB },
];

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 50px 20px;
  text-align: center;
`;

const Title = styled.a`
  font-size: 24px;
  font-weight: 800;
  color: #222;
  text-decoration: none;
  margin-bottom: 30px;
  display: block;
  transition: color 0.3s ease;

  &:hover {
    color: #11ab87;
  }
`;

const LargeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CardDiv = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.a`
  font-size: 18px;
  font-weight: 600;
  color: #444;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: #0073e6;
    text-decoration: underline;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: #0073e6;
`;

export default function SNSPosts() {
  const facebookRef = useRef(null);

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (!document.getElementById("facebook-jssdk")) {
        const facebookScript = document.createElement("script");
        facebookScript.id = "facebook-jssdk";
        facebookScript.src =
          "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0";
        facebookScript.async = true;
        facebookScript.defer = true;
        facebookScript.onload = () => {
          if (window.FB) {
            window.FB.init({ xfbml: true, version: "v13.0" });
            if (facebookRef.current) {
              window.FB.XFBML.parse(facebookRef.current);
            }
          }
        };
        document.body.appendChild(facebookScript);
      } else if (window.FB && facebookRef.current) {
        window.FB.XFBML.parse(facebookRef.current);
      }
    };

    loadFacebookSDK();
  }, []);

  return (
    <Container>
      <Title href="https://www.linkedin.com/company/newsaha" target="_blank" rel="noopener noreferrer">
        Follow Us on Social Media
      </Title>

      {/* ✅ 1️⃣ 미리보기 지원되는 큰 카드 (Facebook, X, Instagram) */}
      <LargeGrid>
        {previewSNS.map((sns, index) => (
          <CardDiv key={index}>
            <CardTitle href={sns.url} target="_blank" rel="noopener noreferrer">
              <Icon icon={sns.icon} />
              {sns.name}
            </CardTitle>
            {sns.name === "X (formerly Twitter)" ? (
              <TwitterTimelineEmbed sourceType="profile" screenName="newsaha2030" options={{ height: 400, width: 300 }} />
            ) : sns.name === "Facebook" ? (
              <div ref={facebookRef}>
                <div className="fb-page" data-href={sns.url} data-tabs="timeline" data-width="300" data-height="400"></div>
              </div>
            ) : sns.name === "Instagram" ? (
              <iframe
                src="https://www.instagram.com/busan_sahagu/embed/"
                width="300"
                height="400"
                frameBorder="0"
                allowFullScreen
                style={{ borderRadius: "10px" }}
              ></iframe>
            ) : null}
          </CardDiv>
        ))}
      </LargeGrid>

      {/* ✅ 2️⃣ 미리보기 없는 작은 카드 (LinkedIn, Threads, YouTube, Naver) */}
      <SmallGrid>
        {smallSNS.map((sns, index) => (
          <CardDiv key={index} style={{ padding: "15px" }}>
            <CardTitle href={sns.url} target="_blank" rel="noopener noreferrer">
              <Icon icon={sns.icon} />
              {sns.name}
            </CardTitle>
          </CardDiv>
        ))}
      </SmallGrid>
    </Container>
  );
}
