import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * [Component] PageWrapper
 * --
 */
const PageWrapper = ({ children }) => {
  /* ===== State ===== */
  const navi = useNavigate();
  const linkStyle = { color: "#c8c8c8", textDecoration: "none" };

  /* ===== Render ===== */
  return (
    <>
      <footer className="footer-main" style={{ background: "#313131" }}>
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content">
              <div className="col-lg-3 col-md-12">
                <div className="about-widget">
                  <div className="footer-logo">
                    <figure>
                      <a href="/" style={linkStyle}>
                        <img
                          style={{ width: 160 }}
                          loading="lazy"
                          className="img-fluid"
                          src="/logo_png_white.png"
                          alt="medic"
                        />
                      </a>
                    </figure>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <h4 style={{ color: "#fff" }}>Quick Links</h4>
                <hr style={{ borderColor: "#c8c8c8" }} />
                <p style={{ color: "#c8c8c8" }}>
                  {[
                    { title: "About Saha", path: "/history-culture" },
                    {
                      title: "Saha District Bulletin",
                      path: "/saha-district-bulletin",
                    },
                    {
                      title: "Lifestyle & Transportation",
                      path: "/lifestyle-transportation",
                    },
                    { title: "KIC Progress", path: "/kic-progress" },
                    { title: "SahaEdushere", path: "/saha-edu" },
                  ].map((item) => (
                    <React.Fragment key={item.path}>
                      <button
                        onClick={() => navi(item.path)}
                        style={{
                          ...linkStyle,
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          padding: 0,
                        }}
                      >
                        {item.title}
                      </button>
                      <br />
                    </React.Fragment>
                  ))}
                </p>
                <br />
                <h4 style={{ color: "#fff" }}>Saha District Office</h4>
                <hr style={{ borderColor: "#c8c8c8" }} />
                <p>
                  <span style={linkStyle}>
                    49328 ) 12 Nakdongdae-ro 398beon-gil, Saha District, Busan,
                    South Korea
                  </span>
                </p>
              </div>

              <div className="col-lg-4 col-md-12">
                <h4 style={{ color: "#fff" }}>Connect with Us</h4>
                <hr style={{ borderColor: "#c8c8c8" }} />
                <p>
                  <a href="mailto:admin@newsaha.net" style={linkStyle}>
                    admin@newsaha.net
                  </a>
                  <br />
                  <a href="tel:+82512204000" style={linkStyle}>
                    Tel: (+82)51-220-4000
                  </a>
                  <br />
                  <span style={linkStyle}>Fax: (+82)51-220-4269</span>
                  <br />
                  <span style={linkStyle}>
                    Business Hours: Mon-Fri 9:00 AM - 6:00 PM
                  </span>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom" style={{ background: "#3a3a3a" }}>
          <div
            className="container clearfix"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="copyright-text" style={{ flex: 1 }}>
              <p>
                Copyright © 2025 Saha District Office. All rights reserved.
              </p>
            </div>
            <div style={{ flex: 1 }}>
              <ul className="footer-bottom-link">
                {[
                  {
                    icon: "fa-linkedin",
                    path: "https://www.linkedin.com/company/newsaha",
                  },
                  { icon: "fa-x-twitter", path: "https://x.com/newsaha2030" },
                  {
                    icon: "fa-threads",
                    path: "https://www.threads.net/@sahadistrictoffice",
                  },
                  {
                    icon: "fa-facebook-f",
                    path: "https://www.facebook.com/SahaguOffice",
                  },
                  {
                    icon: "fa-instagram",
                    path: "https://www.instagram.com/busan_sahagu",
                  },
                  {
                    icon: "fa-youtube",
                    path: "https://www.youtube.com/channel/UCe8iHXVaoZogUcGhZZ5XK1A",
                  },
                ].map((item) => (
                  <React.Fragment key={`social-${item.icon}`}>
                    <button
                      className="btn btn-light"
                      style={{
                        borderColor: "#c3c3c3",
                        background: "none",
                        width: 40,
                        height: 35,
                      }}
                    >
                      <a
                        href={item.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={item.icon.replace("fa-", "")}
                        style={linkStyle}
                      >
                        <i className={`fab ${item.icon}`}></i>
                      </a>
                    </button>{" "}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PageWrapper;
