import React from "react";
import { getCookie } from "utils";

import FullScreenVideo from "components/FullScreenVideo"; // ✅ 배경 비디오 추가
import AboutSection from "./components/AboutSection";
import ExpoBanner from "./components/ExpoBanner";
import ContentPreview from "./components/ContentPreview";
import KicIntro from "./components/KicIntro";
import FAQKo from "./components/FAQKo";
import Form from "./components/Form";
import SubscribeSection from "./components/SubscribeSection";
import SNSPosts from "components/SNSPosts";

// ✅ 구분선 스타일
const hrStyle = {
  border: "none",
  borderTop: "1px solid #ddd",
  margin: "1px 0",
};

const Home = () => {
  const language = getCookie("country");

  return (
    <>
      {/* ✅ 배경 비디오 (Hero Section) */}
      <FullScreenVideo />

      {/* ✅ About Section */}
      <AboutSection language={language} />

      {/* ✅ Expo Banner */}
      <ExpoBanner />

      {/* ✅ 뉴스 업데이트 */}
      <ContentPreview
        language={language}
        firebasePath="board/news/datas"
        navigationPath="/saha-district-bulletin"
        titleEn="SAHA Updates"
        titleKr="사하 뉴스"
        descriptionEn="Check out the latest news and updates from Saha District."
        descriptionKr="사하구의 최신 소식을 확인하세요."
      />
      <hr style={hrStyle} />

      {/* ✅ 뉴스레터 */}
      <ContentPreview
        language={language}
        firebasePath="board/newsletter/datas"
        navigationPath="/newsletters"
        titleEn="SAHA Newsletters"
        titleKr="SAHA 뉴스레터"
        descriptionEn="Read the latest newsletters from Saha District."
        descriptionKr="사하구 뉴스레터를 확인하세요."
      />
      <hr style={hrStyle} />

      {/* ✅ KIC 동향 */}
      <ContentPreview
        language={language}
        firebasePath="board/kic-progress/datas"
        navigationPath="/kic-progress"
        titleEn="SAHA KICs"
        titleKr="지식산업센터 동향"
        descriptionEn="Stay updated on KIC projects and developments."
        descriptionKr="지식산업센터(KIC) 소식을 받아보세요."
      />
      <hr style={hrStyle} />

      {/* ✅ 라이프스타일 & 교통 */}
      <ContentPreview
        language={language}
        firebasePath="board/transportation/datas"
        navigationPath="/lifestyle-transportation"
        titleEn="Discover SAHA"
        titleKr="라이프스타일 & 교통"
        descriptionEn="Explore Saha's lifestyle, culture, and transport options."
        descriptionKr="사하구의 라이프스타일, 문화, 교통 정보를 확인하세요."
      />
      <hr style={hrStyle} />

      {/* ✅ 교육 정보 */}
      <ContentPreview
        language={language}
        firebasePath="board/edu/datas"
        navigationPath="/saha-edu"
        titleEn="SAHA Education"
        titleKr="SAHA 교육"
        descriptionEn="Learn about Saha's education system and opportunities."
        descriptionKr="사하구의 교육 정보와 기회를 알아보세요."
      />
      <hr style={hrStyle} />

      {/* ✅ SNS 섹션 */}
      <section className="sns-section py-10">
        <SNSPosts />
      </section>

      {/* ✅ 구독 섹션 */}
      <section className="subscribe-section">
        <SubscribeSection />
      </section>

      {/* ✅ Contact Section */}
      <section className="appoinment-section section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <FAQKo />
            </div>
            <div className="col-lg-6">
              <Form />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
