/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./LocationInfo.ko";
import En from "./LocationInfo.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] LocationInfo
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["location_info"]}
        breadcrumb={[lang["support"], lang["location_info"]]}
        backgroundImage={"resources/discover_saha/0_Mayor_greetings_pic0.jpeg"}
        backgroundPosition={"center 55%"}
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default PageIndex;
