/**
 *
 *
 */

import React from "react";

/**
 * [Component] 구청장 인사말
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          zIndex: 10,
          // background: '#f1f1f1',
          color: "#fff",
        }}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-1 col-md-12" /> */}

            <div
              className="col-lg-8 col-md-12"
              style={{
                display: "flex",
                // alignItems: 'center',
                alignItems: "flex-end",
              }}
            >
              <div className="content-block" style={{ width: "100%" }}>
                <br />
                <br />
                <h1
                  style={{
                    border: "none",
                    color: "#2a2a2a",
                    width: "100%",
                    fontSize: "3.35em",
                    // lineHeight: 1,
                  }}
                >
                  Lee Gap-jun
                  <hr
                    style={{ margin: "13px 0 -10px 0", borderColor: "#9a9a9a" }}
                  />
                  <small
                    style={{
                      fontWeight: "600",
                      fontSize: "0.5em",
                      color: "#5a5a5a",
                    }}
                  >
                    Mayor of Saha District, Busan
                  </small>
                </h1>
                <br />
                <p className="align-justify">
                  Welcome to Saha District - a unique fusion of progress and
                  tradition, where innovation blossoms from our rich cultural
                  soil. As the Mayor of Saha, I take immense pride in our
                  vibrant and dynamic district, our hardworking populace, and
                  our unwavering dedication to fostering a prosperous and
                  inclusive community for all. We warmly invite you to
                  experience and be a part of our journey
                  <br />
                  <br />
                </p>
                {/* <h4>Mayor of Saha District, Busan</h4> */}
              </div>
            </div>

            <div
              className="col-lg-4 col-md-12"
              style={{
                width: "100%",
                height: "100%",
                minHeight: 500,
                background: `url("resources/discover_saha/00p.png")`,
                backgroundPosition: "center 100%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section "
        style={{
          boxShadow: "-1px 0px 15px -1px rgba(0,0,0, 0.1)",
          zIndex: 11,
        }}
      >
        <div className="container">
          <div className="content-block">
            <h2>
              <small>Dear Global Friends and Visitors,</small>
              <br />
            </h2>
          </div>
          {/* === Row === */}
          <div className="row">
            <div className="col-lg-6">
              <img
                src="resources/discover_saha/1_Mayors_greetings_pic3.jpg"
                width="100%"
                style={{ borderRadius: 20, border: "0px solid #dadada" }}
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <p className="align-justify">
                I am delighted to welcome you to the exploration of Saha
                District, a sparkling gem nestled where the powerful Nakdonggang
                River greets the sea in the western part of Busan, South Korea.
                <br />
                <br />
                As the Mayor of Saha District, I am deeply honored to be your
                guide to our incredible district, a tapestry of rich history,
                dynamic industrial growth, and awe-inspiring ecological wonders.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          {/* === Row === */}

          {/* === Row === */}
          <div className="row">
            <div className="col-lg-6">
              <p className="align-justify">
                Our roots trace back to the industrial heyday of the 1980s and
                1990s, a time when our revered Sinpyeong-Jangnim Industrial
                Complex emerged as a leader of Busan's vibrant manufacturing
                industry. Specializing in diverse sectors such as plating,
                dyeing, leather, and automobile parts, this complex fueled our
                growth narrative.
                <br />
                <br />
                Today, as traditional industries evolve and the world around us
                transforms, so does Saha District. We're harnessing our
                industrial legacy to kindle new opportunities in burgeoning
                industries, shaping Saha District as a magnet for innovative
                businesses and ground-breaking startups from around the world.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src="resources/discover_saha/1_Mayors_greetings_pic4.JPG"
                width="100%"
                style={{ borderRadius: 20, border: "0px solid #dadada" }}
                alt=""
              />
            </div>
          </div>
          {/* === Row === */}
        </div>
      </section>

      <section
        className="service-overview section "
        style={{
          background: `url("resources/discover_saha/1_Mayors_greetings_pic5.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center 90%",
          padding: "155px 0",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            background: "rgba(0,0,0,0.55)",
            height: "100%",
            top: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h1 style={{ color: "#fff", textAlign: "center" }}>
              Joint Declaration of Cooperation
            </h1>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            {/* = 1 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "15px 25px" }}
            >
              <div className="content-block">
                <h3 style={{ color: "#4a4a4a" }}>
                  Embracing the Era of Nakdong River: Joint Declaration of
                  Cooperation
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic6.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: "0px solid #dadada",
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                Our vision drives us to transform Saha District into the
                "Nakdonggang Techno Valley". This vision is being realized
                through the establishment of 13 Knowledge Industry Centers
                (KICs) within the Sinpyeong-Jangnim area. This hub of innovation
                exemplifies our unwavering commitment to nurturing advanced
                technologies, fostering pioneering startups, and propelling
                Busan's future.
              </p>
            </div>
            {/* = 1 = */}

            {/* = 2 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "15px 25px" }}
            >
              <div className="content-block">
                <h3 style={{ color: "#4a4a4a" }}>
                  Saha District: Where Nature Meets Urban Charm
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic7.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: "0px solid #dadada",
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                But Saha district is more than a thriving industrial hub. We are
                a sanctuary of natural splendor, offering a harmonious blend of
                urban life and serene landscapes. With ecological treasures like
                the Eulsukdo Island and the scenic Dadaepo Beach, we offer
                immense potential for tourism, relaxation, and leisure
                activities. We're dedicated to preserving these assets,
                promoting sustainable development, and providing top-notch
                facilities for both residents and tourists
              </p>
            </div>
            {/* = 2 = */}

            {/* = 3 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "15px 25px" }}
            >
              <div className="content-block">
                <h3 style={{ color: "#4a4a4a" }}>
                  Unleashing the Potential: Saha District's Future of
                  Connectivity
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic8.png"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: "0px solid #dadada",
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                The future holds exciting opportunities for Saha District. The
                upcoming inauguration of the Gadeokdo International Airport in
                2029 and the completion of BuTX, the Busan-type express railway,
                by 2030, promise to greatly enhance our district's
                accessibility. These infrastructural projects will position Saha
                as a crucial transportation hub, both domestically and
                internationally, bringing a myriad of opportunities closer to
                our community.
              </p>
            </div>
            {/* = 3 = */}

            {/* = 4 = */}
            <div
              className="col-lg-6 col-md-12"
              style={{ padding: "15px 25px" }}
            >
              <div className="content-block">
                <h3 style={{ color: "#4a4a4a" }}>
                  Saha District's Vision for a Vibrant Community
                </h3>
                <br />
              </div>
              <div className="">
                <img
                  src="resources/discover_saha/1_Mayors_greetings_pic9.jpg"
                  width="98%"
                  style={{
                    borderRadius: 20,
                    border: "0px solid #dadada",
                    maxHeight: 355,
                  }}
                  alt=""
                />
              </div>
              <br />
              <p className="align-justify">
                As the Mayor of Saha District, my commitment is to enhancing the
                quality of life for everyone who calls our district home. We are
                working tirelessly to improve Saha's lifestyle infrastructure.
                This includes the establishment of multi-purpose cultural
                centers, an indoor sports complex within our industrial
                complexes, and the creation of a scenic pathway. This pathway
                will be specifically designed to provide a captivating vantage
                point for spectators to witness the stunning sunsets over the
                Nakdonggang River, immersing them in the natural beauty of our
                district.
              </p>
            </div>
            {/* = 4 = */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section  bg-gray">
        <div
          className="container"
          style={{ maxWidth: 700, textAlign: "center" }}
        >
          <p className="align-justify">
            I thank you for your interest in Saha District. Whether you are a
            resident, a business owner, a visitor, or someone interested from
            afar, I invite you to discover the wealth of opportunities we offer.
            Together, let's script the next inspiring chapter in Saha District's
            promising journey.
          </p>
          <br />
          <h4>Warm regards,</h4>

          <br />
          <br />
          <h3 style={{ position: "relative" }}>
            <small>Lee Gap-jun, Mayor of Saha District, Busan</small>
            <br />
            {/* 사하구청장,&nbsp;&nbsp;&nbsp;&nbsp;이갑준&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(인) */}
            <small>
              <img
                src="resources/discover_saha/02p.png"
                width={380}
                style={{
                  borderRadius: 20,
                  border: "0px solid #dadada",
                  position: "absolute",
                  right: -150,
                  bottom: -53,
                }}
                alt=""
              />
            </small>
          </h3>
        </div>
      </section>
    </>
  );
};

export default En;
