import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "firebase.config";
import { collection, getDocs } from "firebase/firestore";
import moment from "moment";
import styled from "styled-components";

// ✅ 공통 스타일 적용
const CardDiv = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  padding: 20px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    background-color: #f8f8f8;
  }
`;

const ThumbnailDiv = styled.div`
  width: 100%;
  height: 200px;
  background: ${(props) =>
    props.thumbnail
      ? `url(${props.thumbnail}) center/cover no-repeat`
      : `url('/default_thumbnail.png') center/cover no-repeat`};
  border-radius: 10px;
  margin-bottom: 15px;
`;

const ContentWrapper = styled.div`
  text-align: left;
`;

const BlogTitle = styled.h4`
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #222;
  margin-bottom: 10px;
`;

const BlogContent = styled.p`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 10px;
`;

const BlogDate = styled.span`
  font-size: 12px;
  color: #888;
  margin-bottom: 5px;
`;

const ReadMoreButton = styled.div`
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #11ab95;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #09976d;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CenteredTextDiv = styled.div`
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;

  h3 {
    font-size: 28px;
    font-weight: bold;
    color: #222;
    transition: color 0.3s;
  }

  &:hover h3 {
    color: #11ab87;
  }

  p {
    font-size: 14px;
    color: #666;
  }
`;

const ContentPreview = ({
  language,
  firebasePath,
  navigationPath,
  titleEn,
  titleKr,
  descriptionEn,
  descriptionKr,
}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fdDocument = collection(db, ...firebasePath.split("/"));
        const result = await getDocs(fdDocument);
        const rr = result.docs.map((item) => ({
          id: item.id,
          ...item.data(),
        }));

        const sortedList = rr.sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix(),
        );
        setList(sortedList);
      } catch (e) {
        console.error("Error fetching data:", e);
      }
    };

    fetchData();
  }, [firebasePath]);

  const renderContentCard = (item) => {
    const content = item.content.replace(/<[^>]+>/g, ""); // ✅ replace() 사용
    const truncatedContent =
      content.substring(0, 200) + (content.length > 200 ? "..." : "");

    // ✅ 썸네일 이미지 추출 수정
    const thumbnailRaw = item.content.match(/src=["'](.*?)["']/);
    const thumbnail = thumbnailRaw ? thumbnailRaw[1] : null;

    return (
      <CardDiv
        key={item.id}
        onClick={() => navigate(`${navigationPath}/${item.id}`)}
      >
        <ThumbnailDiv thumbnail={thumbnail} />
        <ContentWrapper>
          <BlogTitle>{item.title}</BlogTitle>
          <BlogDate>{moment(item.date).format("YYYY.MM.DD (HH:mm)")}</BlogDate>
          <BlogContent>{truncatedContent}</BlogContent>
          <ReadMoreButton>Read more</ReadMoreButton>
        </ContentWrapper>
      </CardDiv>
    );
  };

  return (
    <section
      className="feature-section section bg-gray"
      style={{ padding: "50px 0" }}
    >
      <div className="container">
        <CenteredTextDiv
          onClick={() => navigationPath && navigate(navigationPath)}
        >
          <h3>{language !== "KR" ? titleEn : titleKr}</h3>
          <p>{language !== "KR" ? descriptionEn : descriptionKr}</p>
        </CenteredTextDiv>

        {/* ✅ 반응형 그리드 적용 */}
        <GridContainer>
          {list.length === 0 ? (
            <div
              style={{ textAlign: "center", color: "#999", margin: "40px 0" }}
            >
              No data
            </div>
          ) : (
            list.slice(0, 3).map((item) => renderContentCard(item))
          )}
        </GridContainer>

        {/* ✅ "View All" 버튼 */}
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <ReadMoreButton
            onClick={() => navigationPath && navigate(navigationPath)}
          >
            View All
          </ReadMoreButton>
        </div>
      </div>
    </section>
  );
};

export default ContentPreview;
