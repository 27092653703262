import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

const menusData = [
  {
    id: "m-3",
    title: lang["kics"],
    path: "/introduce",
    submenus: [
      {
        id: "s-3-1",
        title: lang["kic_overview"],
        path: "/kic-overview",
      },
      {
        id: "s-3-2",
        title: lang["investment_incentives"],
        path: "/investment-incentives",
      },
      {
        id: "s-3-3",
        title: lang["kics_progress"],
        path: "/kic-progress",
      },
    ],
  },
  {
    id: "m-1",
    title: lang["why_saha"],
    path: "",
    submenus: [
      {
        id: "s-1-1",
        title: lang["saha_vision"],
        path: "/saha-vision",
      },
      {
        id: "s-1-2",
        title: lang["development_plan"],
        path: "/development-plan",
      },
      {
        id: "s-1-3",
        title: lang["history_culture"],
        path: "/history-culture",
      },
      {
        id: "s-4-2",
        title: lang["innovation_tech"],
        path: "/innovation-tech",
      },
    ],
  },
  {
    id: "m-5",
    title: lang["saha_life"],
    path: "/introduce",
    submenus: [
      {
        id: "s-5-1",
        title: lang["district_housing"],
        path: "/district-housing",
      },
      {
        id: "s-5-3",
        title: lang["lifestyle_transportation"],
        path: "/lifestyle-transportation",
      },
      {
        id: "s-5-4",
        title: lang["saha_edu"],
        path: "/saha-edu",
      },
    ],
  },

  {
    id: "m-6",
    title: lang["saha_updates"],
    path: "/introduce",
    submenus: [
      {
        id: "s-6-1",
        title: lang["saha_district_bulletin"],
        path: "/saha-district-bulletin",
      },
      {
        id: "s-6-3",
        title: lang["newsletters"],
        path: "/newsletters",
      },
      {
        id: "s-6-4",
        title: lang["downloads"],
        path: "/downloads",
      },
    ],
  },
  {
    id: "m-7",
    title: lang["support"],
    path: "/introduce",
    submenus: [
      {
        id: "s-7-2",
        title: lang["inquiry_form"],
        path: "/inquiry-form",
      },
      {
        id: "s-7-3",
        title: lang["helpful_links"],
        path: "/helpful-links",
      },
    ],
  },

  {
    id: "m-2",
    title: lang["discover_saha"],
    path: "",
    submenus: [
      {
        id: "s-2-1",
        title: lang["mayors_greetings"],
        path: "/greetings",
      },
      {
        id: "s-2-2",
        title: lang["organization_structure"],
        path: "/organization-structure",
      },
      {
        id: "s-7-1",
        title: lang["location_info"],
        path: "/location-info",
      },
    ],
  },
];

export default menusData;
