/**
 *
 *
 */

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

/**
 * [Component] DivisionOverview
 * --
 */
const En = () => {
  const sahaDistrictOfficeLocation = {
    latitude: 35.10458, // Updated latitude
    longitude: 128.974734, // Updated longitude
  };

  // Define a custom icon using your custom image
  const sahaDistrictOfficeIcon = L.icon({
    iconUrl: "/resources/sahaDistrictOfficeIcon.png", // Path to your custom icon image
    iconSize: [32, 32], // Set the icon size as needed
  });

  // Define a custom icon for The Second Building of the Saha District Office
  const secondBuildingIcon = L.icon({
    iconUrl: "/resources/sahaDistrictOffice2Icon.png", // Path to your custom icon image
    iconSize: [32, 32],
  });

  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      <section
        className="service-overview section"
        style={{ backgroundColor: "#f7f7f7", padding: "40px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2
                  style={{
                    color: "#11ab87",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  Welcome to the Saha District Office's Location and Information
                  Page!
                </h2>
                <p style={{ fontSize: "1.2em", color: "#555" }}>
                  Our office serves as the hub of administrative activities in
                  the Saha District, dedicated to fostering growth and
                  prosperity in our community. This page provides crucial
                  information about our location, contact details, and
                  operational hours for your convenience.
                </p>
                <p style={{ fontSize: "1.2em", color: "#555" }}>
                  We are committed to ensuring Saha District thrives as a
                  vibrant, culturally rich, and modern locale. Your feedback and
                  interaction are essential to us as we continuously strive to
                  improve our services to best serve our community.
                </p>
                <p style={{ fontSize: "1.2em", color: "#555" }}>
                  Feel free to explore, reach out, and engage with us. Your
                  voice is vital in making Saha District an outstanding place to
                  live, work, and visit.
                </p>
                <p style={{ fontSize: "1.2em", color: "#555" }}>
                  Thank you for your interest and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Leaflet Map for Saha District Office */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2
                  style={{
                    color: "#11ab87",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  SAHA DISTRICT OFFICE LOCATION
                </h2>
              </div>
              <div>
                <MapContainer
                  center={[
                    sahaDistrictOfficeLocation.latitude,
                    sahaDistrictOfficeLocation.longitude,
                  ]}
                  zoom={14}
                  style={{
                    width: "100%",
                    height: "100%",
                    minHeight: 500,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  {/* Add a marker for Saha District Office */}
                  <Marker
                    position={[
                      sahaDistrictOfficeLocation.latitude,
                      sahaDistrictOfficeLocation.longitude,
                    ]}
                    icon={sahaDistrictOfficeIcon}
                  >
                    <Popup>
                      <div>
                        <h4
                          style={{
                            color: "#333",
                            fontSize: "1.5em",
                            marginBottom: "10px",
                          }}
                        >
                          Saha District Office
                        </h4>
                        <img
                          src="/resources/sahaDistrictOfficeImage.jpg"
                          alt="Saha District Office"
                          width="200"
                        />
                        <p style={{ fontSize: "1.2em", color: "#555" }}>
                          12, Nakdong-daero 398beon-gil, Saha-gu, Busan,
                          Republic of Korea
                        </p>
                      </div>
                    </Popup>
                  </Marker>

                  {/* Add a marker for The Second Building of the Saha District Office */}
                  <Marker
                    position={[35.095013, 128.961512]} // Updated coordinates for the second building
                    icon={secondBuildingIcon}
                  >
                    <Popup>
                      <div>
                        <h4
                          style={{
                            color: "#333",
                            fontSize: "1.5em",
                            marginBottom: "10px",
                          }}
                        >
                          The Second Building of the Saha District Office
                        </h4>
                        <img
                          src="/resources/sahaDistrictOffice2buildingImage.jpeg"
                          alt="Second Building"
                          width="200"
                        />
                        <p style={{ fontSize: "1.2em", color: "#555" }}>
                          185, Hasinjungang-ro, Saha-gu, Busan, Republic of
                          Korea
                        </p>
                      </div>
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </div>
          </div>
          {/* Display addresses below the map */}
          <div className="addresses" style={{ marginTop: "25px" }}>
            <div className="address">
              <h4
                style={{
                  color: "#333",
                  fontSize: "1.5em",
                  marginBottom: "10px",
                }}
              >
                SAHA DISTRICT OFFICE
              </h4>
              <p style={{ fontSize: "1.2em", color: "#555" }}>
                Address: 12 Nakdongdae-ro 398beon-gil, Saha District, Busan,
                49328, South Korea
              </p>
            </div>
            <div className="address">
              <h4
                style={{
                  color: "#333",
                  fontSize: "1.5em",
                  marginBottom: "10px",
                }}
              >
                THE SECOND BUILDING OF THE SAHA DISTRICT OFFICE
              </h4>
              <p style={{ fontSize: "1.2em", color: "#555" }}>
                Address: 185, Hasinjungang-ro, Saha-gu, Busan, Republic of Korea
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="service-overview section"
        style={{ backgroundColor: "#f7f7f7", padding: "40px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img
                src="/resources/DangniStation.png"
                width="100%"
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
                }}
                alt="Dangni Station"
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2
                  style={{
                    color: "#333",
                    fontSize: "2em",
                    marginBottom: "20px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>
                    Saha District Office
                  </small>
                </h2>
              </div>
              <p style={{ fontSize: "1.2em", color: "#555" }}>
                Discover the location of Saha District Office:
              </p>
              <p
                style={{ fontSize: "1.4em", fontWeight: "bold", color: "#333" }}
              >
                12 Nakdongdae-ro 398beon-gil, Saha District, Busan, 49328, South
                Korea
              </p>
              <div className="content-block" style={{ marginTop: "30px" }}>
                <h2
                  style={{
                    color: "#333",
                    fontSize: "1.8em",
                    marginBottom: "10px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>
                    Contact Information
                  </small>
                </h2>
              </div>
              <div style={{ fontSize: "1.2em", color: "#555" }}>
                <p>
                  <i
                    className="fas fa-phone"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Phone:</span>{" "}
                  051-220-4000
                </p>
                <p>
                  <i
                    className="fas fa-fax"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Fax:</span> 051-220-4269
                </p>
                <p>
                  <i
                    className="fas fa-envelope"
                    style={{ marginRight: "10px", color: "#11ab87" }}
                  ></i>
                  <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                  admin@newsaha.net
                </p>
              </div>
              <div className="content-block" style={{ marginTop: "30px" }}>
                <h2
                  style={{
                    color: "#333",
                    fontSize: "1.8em",
                    marginBottom: "10px",
                  }}
                >
                  <small style={{ color: "#11ab87" }}>Office Hours</small>
                </h2>
              </div>
              <p style={{ fontSize: "1.2em" }}>
                <b style={{ color: "#333" }}>Hours:</b> Open from 9:00 AM to
                6:00 PM
              </p>
              <p style={{ fontSize: "1.2em" }}>
                <b style={{ color: "#333" }}>Midday Break:</b> 12:00 PM to 1:00
                PM
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2
              style={{
                color: "#11ab87",
                fontSize: "2em",
                marginBottom: "20px",
              }}
            >
              Public Transportation Guide
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              {/* Bus Information */}
              <>
                <h3>
                  <i className="fas fa-bus"></i> By Bus:
                </h3>
                <ul>
                  <li>
                    <b>11:</b> Yeongseon-dong ↔ Dadaepo
                  </li>
                  <li>
                    <b>113:</b> Dong Sam-jungri ↔ Jangnim Industrial Complex
                  </li>
                  <li>
                    <b>2:</b> Busan Station ↔ Dadaepo
                  </li>
                  <li>
                    <b>16:</b> Gudeok Sports Complex ↔ Eomgung
                  </li>
                  <li>
                    <b>520:</b> Yongwon ↔ Hanseong Girin Apartment
                  </li>
                  <li>
                    <b>58-2(Night):</b> Yongwon ↔ Saha District Office
                  </li>
                  <li>
                    <b>123:</b> Seo-gu District Office ↔ Gimhae Gusan-dong
                  </li>
                  <li>
                    <b>126:</b> Chungmu-dong ↔ Hwamyeong-dong
                  </li>
                  <li>
                    <b>138:</b> Yongdang-dong ↔ Jangnim-dong
                  </li>
                  <li>
                    <b>1000(Night):</b> Seomyeon ↔ Dadaepo
                  </li>
                  <li>
                    <b>1001(Night):</b> Cheonggang-ri ↔ Hadan-dong
                  </li>
                </ul>
              </>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* Subway Information */}
              <>
                <h3>
                  <i className="fas fa-subway"></i> By Subway:
                </h3>
                <p>Disembark at Dangni Station and exit through Exit 5.</p>
              </>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* Parking Information */}
              <>
                <h3>
                  <i className="fas fa-car"></i> Parking Information
                </h3>
                <p>
                  Please note that parking can be quite challenging within the
                  premises of the Saha District Office. Visitors are advised to
                  utilize the public parking area in front of the office or, for
                  convenience, use public transportation.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
