import React, { useState } from "react";
import styled from "styled-components";
import { db } from "firebase.config";
import { setDoc, doc } from "firebase/firestore";

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto 20px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ContainerBase = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const SubscribeContainer = styled(ContainerBase)``;

const EmailInquiryContainer = styled(ContainerBase)`
  background: #f5fffa;
`;

const Heading = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
`;

const SubscribeButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 50%;
  transition:
    background-color 0.3s,
    transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

const SubscribeModal = styled.div`
  width: 100%;

  input[type="email"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 90%;
  }

  .input-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const InquiryButton = styled(SubscribeButton)`
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
`;

const isEmailValid = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [subscribeModal] = useState(true);
  const [subscribed, setSubscribed] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const fdDocument = doc(db, "subscription", email);
      await setDoc(fdDocument, {
        id: email,
        email,
      });

      setEmail("");
      setSubscribed(true);
    } catch (e) {
      console.log("Error: ", e);
      alert("Subscription failed.");
    }
  };

  return (
    <SectionContainer>
      <SubscribeContainer>
        <Heading>
          Stay Informed with the Latest Updates from Saha District
        </Heading>
        <SubscribeModal isVisible={subscribeModal}>
          <form onSubmit={(e) => handleSubscribe(e)}>
            <div className="input-button-container">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {!subscribed ? (
                <SubscribeButton type="submit">Subscribe</SubscribeButton>
              ) : (
                <p>Thank you for subscribing!</p>
              )}
            </div>
          </form>
        </SubscribeModal>
      </SubscribeContainer>

      <EmailInquiryContainer>
        <Heading>Have Questions?</Heading>
        <p>Contact us directly for any inquiries or assistance.</p>
        <InquiryButton
          onClick={() => (window.location.href = "mailto:admin@newsaha.net")}
        >
          Email Inquiry
        </InquiryButton>
      </EmailInquiryContainer>
    </SectionContainer>
  );
};

export default SubscribeSection;
