/**
 *
 *
 */

import React from "react";
import { PageTitle } from "components";

/**
 * [Component] Sample
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>Saha District: The Next Frontier for Industry 4.0</h2>
                <br />
              </div>
            </div>

            {/* <div className="col-lg-5 col-md-12">
              <div style={{ textAlign: 'center' }}>
                <div
                  style={{
                    width: '95%',
                  }}
                >
                  <img
                    src="resources/WhySaha/2_DevelopmentPlan_pic2.jpg"
                    width="90%"
                    style={{ borderRadius: 20 }}
                    alt=""
                  />
                </div>
              </div>
            </div> */}

            <div className="col-lg-12 col-md-12">
              <hr style={{ marginTop: 0 }} />
              <h3>
                Embarking on a New Era: Overview of Saha's Development Plan
              </h3>
              <hr />
              <p style={{ textAlign: "justify" }}>
                In the ever-evolving panorama of technology and industry, the
                Saha District of Busan sets its sights on a future that
                transcends the conventional - transitioning into a pivotal hub
                for the Fourth Industrial Revolution. All the while, it
                maintains its steadfast commitment to elevating the living
                standards of its diverse population, including local and
                international residents.
                <br />
                <br />
                Two key strategic initiatives underpin this ambition. The first,
                the Saha Development Plan 2030, is a visionary roadmap for
                transforming the Saha district into an engine of exponential
                growth. It seeks to fortify the district's capabilities in
                advanced technologies and create a thriving ecosystem for
                businesses and residents alike.
                <br />
                <br />
                The second plan, the Saha Development Concept in Connection with
                the New Gadeokdo Airport, focuses on harnessing the power of
                aviation to broaden Saha's horizons. By establishing stronger
                connections with the new airport, Saha can enhance its
                connectivity, accessibility, and appeal, thereby attracting more
                international visitors and investments. Together, these plans
                aim to improve the quality of life for all residents while
                positioning Saha as a key node in the global industrial and
                aviation network.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section bg-gray"
        style={{
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundImage: `url("resources/WhySaha/2_DevelopmentPlan_pic3.jpg")`,
          opacity: 0.75,
        }}
      >
        <br />
        <br />
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* == */}

            <div className="col-md-12 col-lg-7">
              <hr />
              <h2>
                1. Transforming Saha, Busan Into an Innovation and Advanced
                Manufacturing Hub
              </h2>
              <hr />

              <p style={{ textAlign: "justify" }}>
                Spearheading this transformation is the redevelopment of the
                Sinpyeong-Jangnim Industrial Complex. Covering a total land area
                of 156,703㎡ and a floor area of 1,069,040㎡, this industrial
                park is being reinvented as a dynamic hub for innovation and
                advanced manufacturing. The plan includes enhancements to
                infrastructure, a move towards less environmentally sensitive
                industries, and the establishment of a dedicated research and
                development function within the complex.
                <br />
                <br />
                Strategic initiatives include the construction of an Innovation
                Support Center, cloud services for industrial complexes, and the
                establishment of a marine biocluster. Alongside these
                advancements, Saha is working diligently to attract global
                talent and businesses. The district is investing in enhancing
                the living and working environment, building indoor sports
                facilities, creating cultural creative spaces, urban forests,
                and residential housing.
              </p>
              <br />
            </div>
            <div className="col-md-12 col-lg-5"></div>

            <div className="col-md-12 col-lg-5"></div>
            <div className="col-md-12 col-lg-7">
              <hr />
              <h2>2. Capitalizing on Saha's Strategic Location</h2>
              <hr />

              <p style={{ textAlign: "justify" }}>
                Saha's prime location and robust transportation network give it
                a significant competitive edge. The district is set to play a
                crucial role in the maritime industry, with the creation of a
                marine bioindustry cluster.
                <br />
                <br />
                The impending opening of Gadeokdo New Airport, along with
                forthcoming transportation improvements including an express
                rail link between the airport and downtown Busan, the connection
                between Hadan and Noksan, and the establishment of new subway
                lines connecting Hadan and Sasang, solidify Saha's position as a
                pivotal transportation hub in the region.
              </p>
              <br />
            </div>

            <div className="col-md-12 col-lg-7">
              <hr />
              <h2>
                3. Enhancing the Living Environment for a Global Community
              </h2>
              <hr />

              <p style={{ textAlign: "justify" }}>
                Understanding the importance of an inclusive, multicultural
                community, the district is actively working to enhance the
                living conditions for its diverse population, including foreign
                residents. The creation of comfortable residential housing,
                green urban spaces, and an increased array of recreational
                facilities promise a vibrant and fulfilling lifestyle that
                attracts international talent.
              </p>
              <br />
            </div>
            <div className="col-md-12 col-lg-5"></div>

            <div className="col-md-12 col-lg-5"></div>
            <div className="col-md-12 col-lg-7">
              <hr />
              <h2>4. Saha District: A Sustainable Future Beckons</h2>
              <hr />

              <p style={{ textAlign: "justify" }}>
                The Saha district is unwavering in its pursuit of becoming a
                significant pillar of the Fourth Industrial Revolution,
                fostering a thriving, diverse community, and offering a
                lifestyle that aligns with its ambitious economic aspirations.
                We look forward to shaping a prosperous and sustainable future,
                together with you.
              </p>
              <br />
            </div>

            {/* == */}
          </div>
        </div>
      </section>

      {/*  */}
      <section
        className="service-overview section "
        style={{
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          backgroundImage: `url("resources/WhySaha/2_DevelopmentPlan_pic4.jpg")`,
          opacity: 0.85,
        }}
      >
        <br />
        <br />
        <br />
        <br />
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>Saha District's Forward-Thinking Strategy</h2>
                <br />
              </div>
            </div>

            {/* 카드 */}
            {[
              [
                "An Integral Part of a Mega City: Busan-Ulsan-Gyeongnam",
                `As the driving force behind the second-largest megacity in South Korea, Saha District plays a crucial role in the collaboration between Busan, Ulsan, and South Gyeongsang Province. This partnership aims to create a cross-municipal megacity with a population exceeding 10 million. It places a special emphasis on knowledge-based industries and advanced technology, nurturing an urban industrial environment. To support this burgeoning industry, Saha combines residential, commercial, business, and cultural functions, creating a dynamic blend of spaces for work, living, and culture. With its abundant tourism resources, Saha aspires to become a leading destination for both domestic and foreign tourists, establishing itself as a marine tourism hotspot within the megacity.`,
              ],
              [
                `The Air-Bridged City: Enhancing Accessibility and Connectivity`,
                `With the development of the new Gadeokdo Airport and improvements to the Busan Metropolitan Transport system, Saha District aims to enhance accessibility and connection for all. The district's emphasis on a multi-level public transportation transfer system and the development of innovative traffic systems promise a more convenient and pleasant travel experience.`,
              ],
              [
                `A Vibrant Green City: Promoting a Healthy Life in Harmony with Nature`,
                `By creating extensive park networks, and riverine, and coastal waterfront spaces, Saha District is committed to providing its citizens with a healthy and natural living environment. The district also prioritizes the utilization of open spaces in underutilized areas and within urban planning facilities to enhance its green footprint and provide tranquil resting spaces.`,
              ],
              [
                `A City of Comfort and Joy: Enhancing the Quality of Daily Life`,
                `Saha District strives to meet the diverse needs of its population by providing an array of convenience facilities tailored to its demographics and geographical features. By ensuring urban regeneration and securing life-sustaining facilities, the district aims to provide a high-quality living environment where residents can thoroughly enjoy their daily lives.`,
              ],
              [
                `The Beacon of Innovation and Education`,
                `Saha District prides itself as a hotspot for innovation and education. The district fosters an environment conducive to technological advancement and creativity, drawing leading tech companies and startups to set roots in the area. Coupled with the presence of top-tier educational institutions, it provides a nurturing ground for the next generation of leaders, thinkers, and innovators. By encouraging an intersection of education and industry, Saha District envisions becoming a leading global hub for technological innovation and academic excellence.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                style={{ marginBottom: 18 }}
                key={`askldj${index}`}
              >
                <div
                  className="align-justify"
                  style={{
                    background: "#fff",
                    boxShadow: "1px 1px 15px -1px rgba(0,0,0,0.1)",
                    height: "100%",
                    padding: 25,
                    // borderRadius: 13,
                  }}
                >
                  <h4>{item[0]}</h4>
                  <hr />
                  <p>{item[1]}</p>
                </div>
              </div>
            ))}
            {/* 카드 */}
            {/* 카드 */}
            <div className="col-lg-6 col-md-6" style={{ marginBottom: 18 }}>
              <div
                className="align-justify"
                style={{
                  background: "#fff",
                  boxShadow: "1px 1px 15px -1px rgba(0,0,0,0.1)",
                  height: "100%",
                  padding: "20px 16px",
                  borderRadius: 13,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#11ab87",
                }}
              >
                <h4>
                  <b>
                    Step forward and lead the way to shape the new future for
                    Saha District
                  </b>
                </h4>
              </div>
            </div>
            {/* 카드 */}
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
