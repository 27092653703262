import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { Loading } from "components";
import { getCookie } from "utils";

const ContactContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  margin: 20px;
`;

const ContactTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ContactInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const ContactTextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
`;

const ContactButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition:
    background-color 0.3s,
    transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const language = getCookie("country");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = async () => {
    const { name, email, message } = formData;
    if (!name || !email || !message) {
      alert("Please enter all details");
      return;
    }

    setLoading(true);

    try {
      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2,
        formData,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      );

      console.log(result.text);
      alert("Complete!");
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error(error.text);
      alert("Failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Loading visible={loading} />
      <ContactContainer>
        <ContactTitle>
          {language !== "KR" ? "General Inquiries" : "일반 문의"}
        </ContactTitle>
        <ContactForm>
          <ContactInput
            type="text"
            name="name"
            placeholder={language !== "KR" ? "Name" : "이름"}
            value={formData.name}
            onChange={handleChange}
          />
          <ContactInput
            type="email"
            name="email"
            placeholder={language !== "KR" ? "Email" : "이메일"}
            value={formData.email}
            onChange={handleChange}
          />
          <ContactTextArea
            name="message"
            placeholder={language !== "KR" ? "Your Message" : "문의 내용"}
            value={formData.message}
            onChange={handleChange}
          />
          <ContactButton onClick={sendEmail}>
            {language !== "KR" ? "Submit Now" : "제출하기"}
          </ContactButton>
        </ContactForm>
      </ContactContainer>
    </>
  );
};

export default Form;
