import React, { useRef, useMemo, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import { guidGenerator } from "utils";
import "react-quill/dist/quill.snow.css";
import AWS from "aws-sdk";
import PropTypes from "prop-types";

//const AWS_S3_BUCKET = 'store.newsaha';
//const MAX_FILE_SIZE_MB = 5;

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "align",
  "image",
  "link",
  "video",
  "formula",
  "code",
  "color",
  "background",
  "script",
  "indent", // Added these items
];

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  region: "ap-northeast-2",
  params: { ACL: "public-read" },
});

const FileList = ({ fileList, handleRemoveFile }) => (
  <ul className="list-group" style={{ marginTop: 10 }}>
    {fileList.map((file, fileIndex) => (
      <li
        className="list-group-item d-flex justify-content-between align-items-center"
        key={`aslwps${fileIndex}`}
      >
        <div className="ms-2 me-auto">
          {file.body.name} ({file.body.size} bytes)
          <div className="fw-bold">{file.body.type}</div>
        </div>
        <span className="badge rounded-pill">
          <button
            className="btn btn-outline-danger btn-md"
            onClick={() => handleRemoveFile(fileIndex)}
          >
            삭제
          </button>
        </span>
      </li>
    ))}
  </ul>
);

const TagInput = ({ tags, onTagChange, allTags }) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        const newTag = e.target.value.trim();
        if (!newTag || tags.includes(newTag) || !allTags.includes(newTag)) {
          // TODO: Use a subtle UI feedback instead of alert
          alert("This tag already exists or is not valid.");
          return;
        }
        const newTags = [...tags, newTag];
        onTagChange(newTags);
        e.target.value = "";
      }
    },
    [tags, onTagChange, allTags],
  );

  return (
    <input type="text" placeholder="Add a tag" onKeyDown={handleKeyDown} />
  );
};

const TagList = ({ tags, removeTag }) => {
  return (
    <div>
      {tags.map((tag) => (
        <span
          key={tag}
          style={{
            marginRight: "8px",
            padding: "4px",
            border: "1px solid",
            borderRadius: "4px",
          }}
        >
          {tag}
          <button onClick={() => removeTag(tag)} style={{ marginLeft: "4px" }}>
            x
          </button>
        </span>
      ))}
    </div>
  );
};

const AllTagsDisplay = () => {
  const randomFontSize = useCallback(() => {
    const sizes = ["14px", "16px", "18px", "20px", "22px", "24px"];
    return sizes[Math.floor(Math.random() * sizes.length)];
  }, []);

  return (
    <div style={{ marginTop: "16px" }}>
      {/* TAGS has been removed, so you might want to modify this section as required */}
      {/* Placeholder code without TAGS: */}
      <span>Please add a tag system if required</span>
    </div>
  );
};

const MyEditor = ({
  value,
  isFile,
  onChange,
  fileList,
  onChangeFiles,
  onTagChange,
}) => {
  const quillRef = useRef(null);

  const [tags, setTags] = useState([]);

  const randomFontSize = () => {
    const sizes = ["14px", "16px", "18px", "20px", "22px", "24px"];
    return sizes[Math.floor(Math.random() * sizes.length)];
  };

  const linkHandler = () => {
    let href = prompt("Enter link URL:");
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (href) {
      editor.insertText(range.index, href, "link", href);
    }
  };

  const removeTag = (tagToRemove) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    if (onTagChange) {
      onTagChange(newTags);
    }
  };

  const handleChangeFiles = (e) => {
    const { files, value } = e.target;
    const newFile = files[0];
    if (value === "" || newFile === undefined) {
      alert("No file selected. Please select a valid file.");
      return;
    }

    const maxSizeMB = 5; // Max size in MB
    if (newFile.size > maxSizeMB * 1024 * 1024) {
      alert(`File size exceeds ${maxSizeMB}MB. Please upload a smaller file.`);
      return;
    }

    onChangeFiles((prev) => {
      const fName = newFile.name.split(".");
      const exp = fName[fName.length - 1];
      return [
        ...prev,
        {
          id: `${guidGenerator()}.${exp}`,
          originalName: newFile.name,
          size: newFile.size,
          exp,
          body: newFile,
        },
      ];
    });
    document.getElementById("formFileLg").value = "";
  };

  const handleRemoveFile = (idx) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      onChangeFiles((prev) => [...prev.slice(0, idx), ...prev.slice(idx + 1)]);
    }
  };

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    // Define the event listener function.
    const handleFileChange = async () => {
      const file = input.files[0];
      if (!file) {
        alert("No image selected. Please select a valid image.");
        return;
      }

      // Compress or resize image if necessary (consider using a library for this)

      const fName = file.name.split(".");
      const exp = fName[fName.length - 1];
      const key = guidGenerator();
      const imgUrl = `https://s3.ap-northeast-2.amazonaws.com/store.newsaha/download/${key}.${exp}`;

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: "store.newsaha",
        Key: `download/${key}.${exp}`,
        ContentType: `image/${exp}`,
      };

      s3.putObject(params, async (err, data) => {
        if (err) {
          console.error("Error uploading to AWS S3:", err);
          alert("Failed to upload the image. Please try again.");
          return;
        }

        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        editor.insertEmbed(range.index, "image", imgUrl);
        editor.setSelection(range.index + 1);
      });

      // IMPORTANT: Remove the event listener to prevent memory leaks.
      input.removeEventListener("change", handleFileChange);
    };

    // Attach the event listener.
    input.addEventListener("change", handleFileChange);

    input.click();
  };

  const youtubeEmbedHandler = () => {
    const url = prompt("Enter the YouTube URL:");
    if (!url) {
      alert("No URL entered. Please provide a valid YouTube URL.");
      return;
    }

    const match = url.match(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/,
    );

    if (!match) {
      alert("Invalid YouTube URL. Please enter a valid YouTube URL.");
      return;
    }
    const embedCode = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${match[1]}" frameborder="0" allowfullscreen></iframe>`;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    editor.clipboard.dangerouslyPasteHTML(range.index, embedCode);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ["link", "image", "video", { youtube: "YouTube" }],
          [{ size: ["small", false, "large", "huge"] }], // Custom sizes
          [{ color: [] }, { background: [] }], // font color and background color
          [{ script: "sub" }, { script: "super" }], // subscript/superscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        ],
        handlers: {
          image: imageHandler,
          youtube: youtubeEmbedHandler,
          link: linkHandler,
        },
      },
      clipboard: { matchVisual: false },
    }),
    [],
  );

  return (
    <>
      {isFile && (
        <>
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Attached File
          </label>
          <div>
            <input id="formFileLg" type="file" onChange={handleChangeFiles} />
          </div>
          <ul className="list-group" style={{ marginTop: 10 }}>
            {fileList &&
              fileList.length > 0 &&
              fileList.map((file, fileIndex) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center"
                  key={`aslwps${fileIndex}`}
                >
                  <div className="ms-2 me-auto">
                    {file.body.name} ({file.body.size} bytes)
                    <div className="fw-bold">{file.body.type}</div>
                  </div>
                  <span className="badge rounded-pill">
                    <button
                      className="btn btn-outline-danger btn-md"
                      onClick={() => handleRemoveFile(fileIndex)}
                    >
                      Delete
                    </button>
                  </span>
                </li>
              ))}
          </ul>
          <br />
        </>
      )}

      <div>
        <input
          type="text"
          placeholder="Add a tag"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              const newTag = e.target.value.trim().toLowerCase(); // Convert the newTag to lowercase

              if (!newTag) {
                alert("Please enter a valid tag.");
                return;
              }

              // Checking if the tag already exists in the 'tags' array
              if (tags.map((tag) => tag.toLowerCase()).includes(newTag)) {
                alert("This tag already exists.");
                return;
              }

              const newTags = [...tags, newTag];
              setTags(newTags);
              if (onTagChange) {
                onTagChange(newTags);
              }

              e.target.value = "";
            }
          }}
        />
      </div>

      <div>
        {tags.map((tag) => (
          <span
            key={tag}
            style={{
              marginRight: "8px",
              padding: "4px",
              border: "1px solid",
              borderRadius: "4px",
            }}
          >
            {tag}
            <span
              onClick={() => removeTag(tag)}
              style={{ marginLeft: "4px", cursor: "pointer" }}
            >
              x
            </span>
          </span>
        ))}
      </div>

      <label htmlFor="exampleFormControlTextarea1" className="form-label">
        본문
      </label>
      <div>
        <ReactQuill
          theme="snow"
          ref={quillRef}
          value={value}
          onChange={onChange}
          formats={formats} // <-- Use the 'formats' variable here
          modules={modules}
          style={{ height: 680 }}
        />
      </div>
      <br />
      <br />
    </>
  );
};

MyEditor.propTypes = {
  value: PropTypes.string.isRequired, // Example of making a prop required. Adjust as per your needs.
  type: PropTypes.string,
  isFile: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      originalName: PropTypes.string,
      size: PropTypes.number,
      exp: PropTypes.string,
      body: PropTypes.object,
    }),
  ),
  onChangeFiles: PropTypes.func,
  onTagChange: PropTypes.func,
};

export default MyEditor;
