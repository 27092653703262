/**
 *
 *
 */

import React from "react";

// const styles = {
//   articleTitle: {
//     color: '#333',
//     marginBottom: 15,
//   },
//   articleWarpper: {
//     textAlign: 'center',
//   },
//   articleImage: {
//     width: '100%',
//     height: '100%',
//     maxHeight: 305,
//     minHeight: 235,
//     margin: '0 auto',
//     backgroundSize: 'cover',
//     // backgroundSize: 'cover 100%',
//     border: '1px solid #dadada',
//     borderRadius: 20,
//   },
// };

/**
 * [Component] Sample
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic2.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  height: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <br />
              <br />
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>WELCOME TO THE ALLURE & ADANTAGE OF SAHA DISTRICT</h2>
                <p>
                  Located in the southwestern part of Busan, South Korea, Saha
                  District is a place where history and culture come alive. With
                  its rich heritage and diverse cultural landscapes, Saha
                  District offers an enriching blend of educational experiences
                  and engaging community initiatives.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container" style={{ maxWidth: 800 }}>
          <div className="row">
            {/*  */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h3 style={{ color: "#333", fontWeight: "bold" }}>
                  Historical Significance of Saha District
                </h3>
                <br />
                <p>
                  Saha District, or Saha-gu in Korean, has a rich history dating
                  from the New Stone Age through the Samhan and Gaya periods.
                  Archaeological sites, such as the Neolithic Dadaepo Shell
                  Mound and the Bronze Age Goejeong 1-dong and Goejeong 2-dong
                  Sites, reflect this. Unfortunately, the Dolmen in
                  Gamcheon-dong no longer exists due to urban development and
                  the expansion of Busan Thermal Power Plant in 1964.
                </p>
              </div>
            </div>
            {/*  */}
            {/*  */}
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic3.jpg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">
                The Shell Mound relic in Goejeong 1-dong, Saha District, an
                archaeological site from the Bronze Age to the Three Kingdoms
                period of Korea.
              </p>
              <br />
              <br />
            </div>
            {/*  */}
            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                The records of Dongnae-bu from 1740 mention this area,
                Sacheon-myeon, as consisting of nine villages, such as
                Sinchorang-ri, Guchorang-ri, Daeji-ri, Mokjang-ri, Gamcheon-ri,
                Dokji-ri, Jangnim-ri, and Dadae-ri. The area was strategically
                important for defense against Japan, with fortifications like
                the Dadaejin Fortress.
                <br />
                <br />
                In 1866, Sacheon-myeon split into Sasang-myeon and Saha-myeon.
                The 1904 "Register of Households in Dongnae-gun,
                Gyeongsangnam-do" lists various villages in Saha-myeon,
                including Dadae-ri, Jangnim-ri, Seopyeong-ri, Sinpyeong-ri,
                Hadan-ri, Pyeongrim-ri, Gamcheon-ri, Daeti-ri, Goejeong-ri, and
                Dangri. These now form part of the modern Saha District. In
                1910, it became Saha-myeon of Busan-bu, and after an
                administrative reorganization in 1914, neighborhoods such as
                Amnam-dong, Gamcheon-dong, Hadan-dong, Sinpyeong-dong,
                Jangnim-dong, Dadae-dong, Gupyung-dong, Dangri-dong, and
                Goejeong-dong came under Saha-myeon in Dongrae-gun. Amnam-ri
                joined Busan-bu in 1936 and was separated.
              </p>
            </div>
            {/*  */}
            {/*  */}
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic4.jpg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">
                Three-wheeled cars speeding along the unpaved Nakdong-ro road
                between Goejeong-dong and Hadan-dong.
              </p>
              <br />
              <br />
            </div>
            {/*  */}

            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                In 1942, the area joined Busan-bu, and a Saha office was
                established. This office was renamed the Saha office of Busan
                Western Office in 1951 and became the Saha branch of Seo-gu in
                1957. Busan's elevation to a directly controlled city in 1963
                led to the office being upgraded in 1975. In 1983, Saha-gu
                separated from Seo-gu and incorporated Eulsukdo Island and
                Ilwoongdo Island, previously under Buk-gu. With Busan's
                elevation to a Metropolitan City in 1995, the area officially
                became Saha-gu, Busan Metropolitan City.
              </p>
            </div>
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic14.jpeg"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">
                Hadan-dong in 1987: A Neighborhood Under Development
              </p>
              <br />
              <br />
            </div>
            {/*  */}

            {/*  */}
            <div className="col-lg-12 col-md-12">
              <p>
                This historical journey has shaped the identity of the Saha
                District and significantly contributed to its rich cultural
                heritage.
              </p>
            </div>
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center" }}
            >
              <img
                src="resources/WhySaha/3_HistoryCulture_pic5.JPG"
                width="90%"
                style={{
                  borderRadius: 20,
                  width: "100%",
                  maxWidth: 580,
                }}
                alt=""
              />
              <p className="italic">
                Saha District Office Second Building at Sinpyeong Station Exit 4
              </p>
            </div>
            {/*  */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic15.jpeg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">Tranquil Beauty: Sunset at Dadaepo Beach</p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Cultural and Natural Vibrancy of Saha District</h2>
                <p className="align-justify">
                  Saha District is renowned for its vibrant cultural scene. One
                  of its standout attractions is Molundae. Molundae has been
                  celebrated throughout history for its verdant forests,
                  peculiar rock formations, undulating waves, and beautiful
                  sandy patches. Moreover, its scenic charm is enhanced by the
                  various shaped, small uninhabited islands scattered around it.
                  At the southernmost tip of Molundae, there's a striking sea
                  cave formed by wave erosion. Just behind this area lies the
                  exquisite sandy beach of Dadaepo, a favorite spot for both
                  locals and tourists.
                </p>
              </div>
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  The district is not only rich in natural beauty but also
                  steeped in cultural depth. It houses the Nakdonggang River
                  Estuary Eco-Center, an ecological preservation site that
                  serves as a sanctuary for migratory birds and educates
                  visitors on the importance of biodiversity. Nestled in this
                  scenic landscape is Eulsukdo Island, which was once a natural
                  island in the Nakdonggang River. Now, it's a paradise for
                  birdwatchers and nature enthusiasts, housing the Eulsukdo
                  Cultural Center which celebrates local traditions, and the
                  Eulsukdo Migratory Bird Park, which offers encounters with
                  diverse bird species. Complementing the district's ecological
                  grandeur is the cultural richness embodied by the Museum of
                  Contemporary Art, Busan. This museum serves as a critical hub
                  for modern art, hosting a wide variety of exhibitions that
                  showcase both Korean and international artists, thereby
                  offering a unique window into the vibrant cultural scene in
                  Busan. Thus, the district exemplifies the perfect blend of
                  natural beauty and cultural vibrancy, offering experiences
                  that cater to a myriad of interests.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic6.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">Eulsukdo Cultural Centre by BTO</p>
              <br />
              <br />
              <br />
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic7.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">
                Cheon Deok Su's Well: A Hidden Gem in Gamcheon Culture Village
              </p>
              <br />
              <br />
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  Adding further depth and color to the district's cultural
                  landscape is the extraordinary Gamcheon Culture Village. This
                  captivating neighborhood, fondly referred to as the 'Machu
                  Picchu of Busan,' is a mosaic of history, culture, and art.
                  Each narrow, winding alley and steep stairway is part of a
                  mesmerizing labyrinth, leading to a delightful surprise at
                  every turn. The homes here, stacked together like a deck of
                  colorful cards against the hillside, are each painted in a
                  vibrant palette, showcasing artistic creativity and community
                  spirit. This area is more than just a residential quarter;
                  it's a living, breathing canvas that captures and expresses
                  the spirit of Busan in the most charming way. The village
                  serves as an open-air museum, offering visitors a unique
                  journey through the district's distinct art and culture.
                  Amidst the vibrant hues and rustic charm, one discovers art
                  installations, murals, and galleries tucked away in unexpected
                  corners, each telling a unique story of the city's rich
                  cultural heritage. The Gamcheon Culture Village, thus, not
                  only enriches the district's cultural vibrancy but also serves
                  as a testament to the district's creative soul and resilience.
                </p>
              </div>
            </div>
            {/* === #00 === */}

            {/* === #00 === */}
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <p className="align-justify">
                  Lastly, Seunghaksan Mountain stands as a symbol of Saha
                  District's spirit. Offering panoramic views of the Nakdonggang
                  River, Eulsukdo Island, and the district's landscape, it
                  remains a popular destination for hiking and leisurely strolls
                  among locals. Whether for its natural beauty, cultural
                  richness, or historical significance, Saha District truly
                  offers a diverse array of experiences.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic8.JPG"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">
                The silver grass field of Seunghaksan Mountain
              </p>
            </div>
            {/* === #00 === */}

            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic9.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">
                The Busan Daedong High School Fencing Team was founded on March
                11, 2023
              </p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Educational Excellence & Specialized High Schools</h2>
                <p className="align-justify">
                  Saha District prides itself on its strong educational
                  infrastructure, particularly its vast number of high schools.
                  The district ensures that students face no major inconvenience
                  when commuting, thanks to the high density of these
                  institutions. Moreover, there's a diverse array of schools
                  available, including all-boys and all-girls schools,
                  co-educational schools, as well as specialized high schools.
                  <br />
                  <br />
                  The specialized high schools further enhance the district's
                  educational landscape with their unique curriculums and
                  focused areas of study. These include the likes of foreign
                  language high schools and science high schools. In fact, Saha
                  District is home to six specialized high schools, an
                  unprecedented number even when compared to districts in Seoul.
                  This wide selection of educational institutions not only
                  caters to students within Saha District but also draws a
                  significant number of students from other districts who
                  commute to Saha for its high-quality education. The district
                  truly leaves no stone unturned when it comes to offering a
                  diverse and comprehensive range of educational opportunities.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Emerging as a Transportation Hub</h2>
                <p className="align-justify">
                  Saha District is in the midst of an exciting transformation,
                  rapidly becoming a pivotal transportation hub in western
                  Busan, thanks to the concurrent development of Gadeokdo
                  International Airport and the Busan-type Express Railway, or
                  BuTX. The BuTX, an eco-friendly, high-speed hydrogen railway,
                  is expected to cater to about 114,000 daily commuters,
                  effectively linking key locations like Gadeokdo International
                  Airport, Myeongji, Hadan, North Port, Centum City, and Osiria.
                  The impact of this strategic development is manifold; it
                  drastically reduces travel times and enhances accessibility to
                  the new airport, with North Port reachable in just 15 minutes
                  and Osiria in 25 minutes. Crucially, Hadan Station is poised
                  to become a central station connecting the Gangseo and Sasang
                  Districts of Busan, further reinforcing Saha District's
                  emerging centrality in Busan's transportation network.
                  Alongside its proximity to existing transport links like
                  Gimhae Airport, Gamcheon Port, Namhae Expressway, and Gyeongbu
                  Expressway, these developments fortify Saha District's role in
                  shaping Busan's socio-economic landscape, further elevating
                  the city's global standing.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic10.jpeg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">The Construction of Gadeokdo New Airport</p>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic11.png"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">
                The Hadan Fifth-Day Market operates six times a month,
                specifically on dates ending with "2" and "7": the 2nd, 7th,
                12th, 17th, 22nd, and 27th.
              </p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Culinary Delights</h2>
                <p className="align-justify">
                  Saha District is a treasure trove of culinary delights,
                  boasting a multitude of hidden gems and eateries that have
                  withstood the test of time, contributing to its rich
                  gastronomic reputation. The district offers a diverse and
                  exciting range of dining options, featuring traditional Korean
                  dishes and international flavors, that reflect its vibrant
                  cultural and historical tapestry. The Hadan Fifth-Day Market,
                  with its bustling stalls offering fresh produce, seafood, and
                  a vast array of authentic Korean fare, serves as a vibrant
                  backdrop to this culinary narrative. Yet, the district's
                  culinary scene extends beyond the market, with numerous
                  time-honored restaurants scattered throughout Saha, quietly
                  keeping their place in the culinary heritage. These
                  long-standing establishments, some of which have been in
                  operation for decades, offer unique dining experiences that
                  blend authentic flavors with a dash of nostalgia. As such, a
                  culinary journey through Saha District offers not just a feast
                  for the palate, but also an immersive experience into its rich
                  local culture and history.
                </p>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>Community Spirit</h2>
                <p className="align-justify">
                  Saha District is imbued with a powerful sense of community
                  spirit, manifest in the myriad of initiatives, cultural
                  festivals, and community events that not only echo but also
                  enrich the area's local traditions and values. This sense of
                  shared identity and unity is further solidified through an
                  array of local traditions such as art exhibitions, food
                  festivals, and sporting events, all of which encapsulate the
                  area's vibrant and diverse culture. This spirit extends beyond
                  organized events, with residents actively participating in
                  communal affairs like neighborhood clean-up drives and
                  community gardening projects, demonstrating a profound
                  commitment to their shared environment. The district also
                  hosts a plethora of community centers and public spaces that
                  serve as vital hubs for local gatherings, informal
                  interactions, and educational activities, promoting the
                  sharing and learning of Saha's rich cultural heritage.
                  Ultimately, Saha District exemplifies a tight-knit community,
                  not merely a place of residence, characterized by shared
                  values, mutual respect, and a strong sense of belonging, a
                  testament to its resilient character and commitment to
                  fostering an inclusive and welcoming environment for all.
                </p>
              </div>
            </div>

            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic12.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">Flea Market at Dadaepo</p>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>

      {/* ===== ===== */}
      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/WhySaha/3_HistoryCulture_pic13.jpg"
                style={{
                  borderRadius: 20,
                  width: "90%",
                  // height: '90%',
                  minHeight: 270,
                }}
                alt=""
              />
              <p className="italic">
                Tranquil Sunset at Nakdonggang Estuary Bank in 2023
              </p>
            </div>
            {/* 내용 */}
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                {/* <h2>s</h2> */}
                <h4 className="align-justify italic">
                  Saha District invites you to discover its rich history,
                  diverse culture, educational excellence, vibrant community
                  spirit, and culinary delights. Join us as we delve into the
                  captivating charm of this remarkable district in Busan.
                </h4>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12"></div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
