import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { Loading } from "components";
import { getCookie } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";

const defaultValue = {
  name: "",
  type: "",
  email: "",
  phone: "",
  company: "",
  content: "",
};

// ✅ 전체 컨테이너 크기 수정
const ContactContainer = styled.div`
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 550px; /* ✅ 크기 조정 */
  font-family: "Noto Sans KR", sans-serif;
`;

// ✅ 제목 스타일
const ContactTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  color: #222;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
    color: #11ab87;
  }
`;

// ✅ 입력 폼 스타일
const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

// ✅ 입력 필드 스타일
const ContactInput = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  background-color: #f9f9f9;
  transition: border 0.3s ease-in-out;

  &:focus {
    border: 1px solid #11ab87;
    outline: none;
  }
`;

const ContactSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  background-color: #ffffff;
`;

const ContactTextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  height: 120px;
  font-size: 14px;
  background-color: #f9f9f9;
`;

const ContactButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  transition:
    background-color 0.3s,
    transform 0.2s ease-in-out;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

const Form2 = () => {
  const language = getCookie("country");
  const [loadingFlag, setLoading] = useState(false);
  const [data, setData] = useState(defaultValue);
  const [selectedType, setSelectedType] = useState("");

  const onChange = (name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = () => {
    setLoading(true);
    const { name, type, email, phone, company, content } = data;

    if (!name || !type || !email || !phone || !company || !content) {
      alert(
        language !== "KR"
          ? "Please enter all details"
          : "모든 정보를 입력해주세요.",
      );
      setLoading(false);
      return;
    }

    const params = { name, type, email, phone, company, content };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID_2,
        params,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        () => {
          setLoading(false);
          alert(language !== "KR" ? "Submission Complete!" : "제출 완료!");
          setData(defaultValue);
          setSelectedType("");
        },
        () => {
          alert(language !== "KR" ? "Submission Failed" : "제출 실패");
          setLoading(false);
        },
      );
  };

  return (
    <>
      <Loading visible={loadingFlag} />
      <ContactContainer>
        <ContactTitle>
          <FontAwesomeIcon icon={faHandshake} />{" "}
          {language !== "KR" ? "Investment Inquiry" : "투자 문의"}
        </ContactTitle>
        <ContactForm>
          <ContactInput
            type="text"
            name="name"
            placeholder={language !== "KR" ? "Name" : "이름"}
            value={data.name}
            onChange={(e) => onChange("name", e.target.value)}
          />
          <ContactInput
            type="email"
            name="email"
            placeholder="Email"
            value={data.email}
            onChange={(e) => onChange("email", e.target.value)}
          />
          <ContactInput
            type="text"
            name="phone"
            placeholder={language !== "KR" ? "Phone" : "전화번호"}
            value={data.phone}
            onChange={(e) => onChange("phone", e.target.value)}
          />
          <ContactSelect
            value={selectedType}
            onChange={(e) => onChange("type", e.target.value)}
          >
            <option value="" disabled>
              {language !== "KR" ? "Select an Option" : "옵션 선택"}
            </option>
            <option value="Investment">
              {language !== "KR" ? "Investment" : "투자"}
            </option>
            <option value="Venture Capital">
              {language !== "KR" ? "Venture Capital" : "벤처 캐피탈"}
            </option>
            <option value="Mergers and Acquisitions">
              {language !== "KR" ? "Mergers and Acquisitions" : "합병과 인수"}
            </option>
            <option value="Other">
              {language !== "KR" ? "Other" : "기타"}
            </option>
          </ContactSelect>
          <ContactInput
            type="text"
            name="company"
            placeholder={language !== "KR" ? "Affiliation" : "소속"}
            value={data.company}
            onChange={(e) => onChange("company", e.target.value)}
          />
          <ContactTextArea
            name="content"
            placeholder={language !== "KR" ? "Your Message" : "문의 내용"}
            value={data.content}
            onChange={(e) => onChange("content", e.target.value)}
          />
          <ContactButton type="button" onClick={sendEmail}>
            {language !== "KR" ? "Submit Now" : "제출하기"}
          </ContactButton>
        </ContactForm>
      </ContactContainer>
    </>
  );
};

export default Form2;
