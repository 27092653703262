/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./DivisionOverview.ko";
import En from "./DivisionOverview.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] DivisionOverview
 * --
 */
const DivisionOverview = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["development_overview"]}
        breadcrumb={[lang["discover_saha"], lang["development_overview"]]}
        backgroundImage={
          "resources/strategic_business_division/1_DivisionOverview_pic1.jpg"
        }
        backgroundPosition={"center 45%"}
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default DivisionOverview;
