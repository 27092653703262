import React, { useState, useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import Routemap from "./pages"; // ✅ 수정 완료
import { getCookie, setCookie } from "./utils"; // ✅ 수정 완료
// import { setComponentLocale } from './locale/intlGlobals';  // ✅ 필요 시 활성화
import "./App.css"; // ✅ 수정 완료

const cookieCountry = getCookie("country");

function App() {
  /* ===== Initial ===== */
  const [country] = useState(cookieCountry);

  /* ===== Hooks ===== */
  /**
   *
   */
  useEffect(() => {
    const call = async () => {
      setCookie("country", "US");
      // try {
      //   const prevIp = getCookie('ip');

      //   const result = await axios.get('https://ipinfo.io');
      //   const { status, data } = result;
      //   if (status !== 200) throw status;
      //   const {
      //     // timezone,
      //     country,
      //     ip,
      //   } = data;

      //   if (prevIp !== ip) {
      //     setCookie('country', country ? country : 'US');
      //     setCookie('ip', ip);
      //     setCountry(country);
      //   }
      // } catch (e) {
      //   console.log('e: ', e);
      // }
    };
    call();
  }, []);

  /**
   *
   */
  useEffect(() => {
    setCookie("country", country ? country : "US");
    //const r = setComponentLocale(country);
  }, [country]);

  /* ===== Render ===== */
  return (
    <Router>
      <Routemap />
    </Router>
  );
}

export default App;
