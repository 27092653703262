/**
 *
 *
 */

import React from "react";

/**
 * [Component] InnovationTech
 * --
 */
const En = () => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>
                  Pioneering Industries in Saha District
                  <br />
                  <small>Empowering Transformation and Innovation</small>
                </h2>
                <p className="align-justify">
                  In the heart of Saha District, we're relentlessly reshaping
                  the landscape of our core industries — from manufacturing to
                  knowledge-based and ICT industries. Explore how we're
                  integrating smart technologies, innovative practices, and
                  strategic infrastructure to power our leap into the future.
                </p>
              </div>
            </div>
            {/* 이미지 */}
            <div className="col-lg-4 col-md-12" style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "95%",
                  height: "93%",
                  margin: "0 auto",
                  backgroundSize: "cover",
                  border: "1px solid #dadada",
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic2.jpg")`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* === #1 === */}
            {/* 본문 */}
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>
                  <small>Factory Manufacturing:</small>
                  <br />
                  The Core of the Knowledge-Based and Advanced Industries
                </h2>
                <p className="align-justify">
                  Saha District has always been a major hub for factory
                  manufacturing in South Korea. Over the years, it has gradually
                  shifted its focus towards knowledge-based and advanced
                  industries, evolving into a pivotal center for these sectors.
                  As the backbone of the Knowledge Industry Center, factory
                  manufacturing in the Saha District plays a crucial role in
                  driving the growth of these industries. It provides the
                  necessary infrastructure, skilled workforce, and innovative
                  spirit that are key to the development and prosperity of the
                  knowledge-based and advanced industries.
                </p>
              </div>
            </div>
            {/* 본문 */}

            {/* 이미지 */}
            <div className="col-lg-5 col-md-12" style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "95%",
                  height: "93%",
                  minHeight: 250,
                  margin: "0 auto",
                  backgroundSize: "cover",
                  border: "1px solid #dadada",
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic3.jpg")`,
                }}
              ></div>
            </div>
            {/* 이미지 */}
            <div className="col-12">
              <br />
              <br />
              <br />
            </div>
            {/* === #1 === */}

            {/* === #2 === */}

            {/* 본문 */}
            {[
              [
                "The Power of Manufacturing",
                "#1",
                "Manufacturing is one of the major resident industries within the Knowledge Industry Center. As an industry that transforms raw materials into new products, it serves as a pivotal engine for economic growth. By supporting the growth of the manufacturing sector, the Knowledge Industry Center contributes to the activation of the local economy.",
              ],
              [
                "Knowledge and ICT Industries: An Integrated Approach",
                "#2",
                "The Knowledge Industry Center also has a strong affiliation with knowledge-based and ICT industries. Knowledge industries are based on the utilization of knowledge and information, whereas ICT industries are rooted in computer and communication technology. By providing spaces conducive to these industries, the Knowledge Industry Center champions their growth and development.",
              ],
              [
                "Driving Regional Economy and Job Creation",
                "#3",
                `By facilitating the growth of the manufacturing, knowledge-based, and ICT industries, the Knowledge Industry Center significantly contributes to invigorating the local economy. It has established itself as a cornerstone of the regional economy, playing an integral role in job creation and economic growth. The center welcomes various sectors such as manufacturing, knowledge industries, ICT, and R&D, as specified in the 'Act on Industrial Cluster Development and Factory Establishment.'`,
              ],
              [
                "Advantages of the Knowledge Industry Center",
                "#4",
                "Knowledge Industry Center is a multifunctional complex welcoming diverse sectors, such as manufacturing, knowledge industries, ICT, and R&D. It provides a plethora of support facilities for resident companies, along with fiscal and financial benefits. The center plays a significant role in job creation and economic growth, making it a pivotal player in the regional economy.",
              ],
              [
                "Establishment of Nakdonggang River Techno Valley",
                "#5",
                `Aiming for the reformation into an advanced industrial complex, the development of Nakdonggang Techno Valley embodies the shift in industrial structure and attraction of high value-added industries. By fostering a multifaceted industrial space, this venture looks forward to shaping the valley into a growth hub driven by advanced industries. The evolution of Nakdonggang River Techno Valley is a testament to Saha District's commitment to innovation, promising a prosperous future for both its industries and residents.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`sbt-${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: "#fff",
                    padding: 25,
                    height: "100%",
                  }}
                >
                  <div className="content-block">
                    <h4 style={{ color: "#333" }}>
                      {/* <small>{item[1]}</small> */}
                      {item[1]}
                      <br />
                      {item[0]}
                    </h4>
                    <hr />
                    <p className="align-justify">{item[2]}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* 본문 */}

            {/* === #2 === */}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-4 col-md-12">
              <div className="content-block">
                <h2>
                  <small>
                    The Knowledge Industry Center offers numerous benefits
                  </small>
                </h2>
              </div>
            </div>
            <div className="col-1"></div>
            {/* 이미지 */}
            <div className="col-lg-7 col-md-12">
              <h6>
                ✔️ Ensures an appealing work environment, improving work
                efficiency.
                <hr />
                ✔️ Fosters advanced industrial clusters, stimulating technology
                and information exchange, and enhancing productivity.
                <hr />
                ✔️ Houses diverse community facilities, leisure facilities, and
                cultural spaces.
                <hr />
                ✔️ Receives proactive government support.
                <hr />
                ✔️ Contributes to regional job creation and economic growth.
              </h6>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-12 col-md-12">
              <div className="content-block">
                <h2>
                  Hydrogen Industry: Powering the Future with Hydrogen Energy
                </h2>
              </div>
              <p className="align-justify">
                At the heart of Saha District, we're harnessing the power of
                hydrogen, one of the cleanest and most efficient energy sources
                on the planet. As a future-oriented energy source, hydrogen is a
                key instrument in carbon neutrality and holds high potential for
                various innovative industries. We're committed to spearheading
                the hydrogen economy and transforming our region into a
                hydrogen-based city.
              </p>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {/* 내용 */}
            {[
              [
                "Proactive Approach to Hydrogen Economy",
                `In response to the domestic and international climate surrounding the hydrogen industry, Saha District is working proactively to ensure that the hydrogen economy can become a new growth engine for our region. We're focusing on creating a robust hydrogen ecosystem, and expanding the production and supply infrastructure.`,
              ],
              [
                "Establishing a Hydrogen Ecosystem Business",
                `Our emphasis in establishing a 'Hydrogen Ecosystem Business' is on enhancing the competitiveness of hydrogen companies, fostering the development of hydrogen technology, supporting quality certification and inspection of hydrogen products, and training talents for the hydrogen industry. Saha District is also preparing to stay ahead in the hydrogen market by focusing on the development of core technologies based on fuel cells, liquid hydrogen, and ammonia.`,
              ],
              [
                "Expanding the Reach of Hydrogen Energy",
                `In our efforts to promote a 'citizen-friendly hydrogen economy,' we're driving forward with the creation of a hydrogen-based green city and green industrial complex. We're putting our energies into expanding the use of hydrogen cars and fuel cells, with the vision of making hydrogen a primary energy source for housing, transportation, and other primary areas of our city.`,
              ],
              [
                "Building a Citizen-Friendly Green City",
                `We're dedicated to expanding the distribution of hydrogen fuel cells in homes throughout the Saha District, and enhancing the application of hydrogen energy in various fields, such as smart farms. Our ultimate goal is to create a citizen-friendly green city, Saha, powered by hydrogen energy.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6 align-justify"
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: "#fff",
                    padding: 25,
                    height: "100%",
                  }}
                >
                  <h4 style={{ color: "#333" }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div style={{ maxWidth: 700, textAlign: "center", margin: "0 auto" }}>
            <h4>
              Discover with us how we're integrating hydrogen energy solutions
              into our industries and infrastructures, and embracing the
              potential of this future-oriented energy source.
            </h4>
          </div>
        </div>
      </section>

      {/*  */}
      {/* <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">

            <div className="col-lg-8 col-md-12">
              <div className="content-block">
                <h2>
                  <small>Redefining Industries with Robotics</small>
                  <br />
                  Saha District's Robotics Industry: An Emerging Game
                </h2>
              </div>
              <p className="align-justify">
                In Saha District, we're at the forefront of integrating robotics
                into a wide range of industries. Harnessing this revolutionary
                technology, we're redefining processes, increasing efficiency,
                and opening up new possibilities.
              </p>
            </div>

            <div className="col-lg-4 col-md-12" style={{ textAlign: 'center' }}>
              <div
                style={{
                  width: '95%',
                  height: '93%',
                  minHeight: 200,
                  margin: '0 auto',
                  backgroundSize: 'cover',
                  border: '1px solid #dadada',
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic5.jpg")`,
                }}
              ></div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {[
              [
                'The Power of Robotics',
                'The robotics industry involves the development and production of robots - machines designed to assist or replace human tasks. Deployed across sectors such as manufacturing, healthcare, services, and agriculture, these powerful tools are experiencing rapid growth thanks to continuous advancements in technology.',
              ],
              [
                'The Role of Automation',
                'A critical aspect of robotics, automation refers to the use of machines and equipment to automate human tasks. With potential benefits including productivity improvements, quality enhancements, and labor cost savings, automation finds application across a host of industries and its use is set to rise exponentially in the future.',
              ],
              [
                'Knowledge Industry Center: A Hub for Robotics',
                'Knowledge Industry Centers in Saha District are closely linked with the robotics industry and automation-related technology. We provide a nurturing environment for companies that develop and produce robots, operate automation systems, and apply robots and automation systems in their operations. These newly built KICs will play a crucial role in promoting the advancement and proliferation of robotics and automation-related technology.',
              ],
              [
                'The Versatility of Robotics Technology',
                'Robotics technology is incredibly diverse and versatile. Examples of its use include robots in manufacturing processes for assembly or inspection tasks, robots in warehouses for transporting or sorting products, customer service robots, and even agricultural robots for crop harvesting or livestock management. These technologies contribute significantly toward productivity improvement, quality enhancement, and labor cost saving.',
              ],
              [
                'Our Vision for the Future',
                'In Saha District, we are committed to utilizing robotics technology to support our Knowledge Industry Center. We aim to transform the aging Sinpyeong-Jangnim Industrial Complex into a hub of innovative technology, leveraging the power of robotics to redefine industries, improve efficiencies, and explore new possibilities. Join us as we embark on this journey towards the future.',
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6 "
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: '#fff',
                    padding: 25,
                    height: '100%',
                  }}
                >
                  <h4 style={{ color: '#333' }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            {/* 이미지 */}
            <div className="col-lg-5 col-md-12" style={{ textAlign: "center" }}>
              <div
                style={{
                  width: "95%",
                  height: "93%",
                  minHeight: 170,
                  margin: "0 auto",
                  backgroundSize: "cover",
                  border: "1px solid #dadada",
                  borderRadius: 20,
                  backgroundImage: `url("resources/strategic_business_division/2_InnovationTech_pic6.jpg")`,
                }}
              ></div>
            </div>
            {/* 내용 */}
            <div className="col-lg-7 col-md-12">
              <div className="content-block">
                <h2>Saha District & The Thriving Seafood Industry</h2>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            {/* 내용 */}
            {[
              [
                `Gamcheon Port: The Heartbeat of Busan's Seafood Industry`,
                `Gamcheon Port, one of the most prominent ports in Busan, serves as a crucial conduit for 70% of Busan's seafood. With an annual seafood circulation of nearly 1 million tons, it stands as Busan's largest fishing port. Moreover, Gamcheon Port has solidified its position as the epicenter of Busan's seafood industry, thanks to a dense concentration of seafood processing factories, distribution centers, and markets.`,
              ],
              [
                "The Vital Role of Saha District",
                `The Saha District, home to Gamcheon Port, is a well-known beacon of Busan's robust seafood industry. As a central hub of seafood processing and distribution, the Saha District's seafood industry injects vibrancy into the local economy and spawns a plethora of job opportunities for residents and the wider community.`,
              ],
              [
                `Fueling Busan's Development`,
                `The seafood industry, anchored in Gamcheon Port in the Saha District, is a potent driving force behind the advancement of Busan's broader seafood industry. They catalyze the circulation of seafood within Busan and command a prominent position as the city's seafood processing and distribution hubs. `,
              ],
              [
                "The Seafood Specialized Complex",
                `Nestled within the Sinpyeong-Jangnim Industrial Complex in the Saha District, the Seafood Specialized Complex started its formation in 2015. Spanning an area of 155,000 square meters, the complex accommodates seafood processing factories, research institutes, and distribution centers. The development of this specialized complex is expected to bolster job creation and energize local economic activity, especially within the seafood processing and distribution sectors.`,
              ],
              [
                "Spearheading Innovation at the Seafood Specialized Complex",
                `At the Seafood Specialized Complex, you'll find processing factories engaged in the production of diverse seafood products. Its research institutes are devoted to elevating seafood quality and innovating new seafood products.`,
              ],
              [
                "Projected Impact: A Bright Future",
                `The establishment of the Seafood Specialized Complex portends a bright future, teeming with potential for significant local job creation and economic stimulation. It's poised to open new avenues of employment and inject vitality into the local seafood processing and distribution industry. Ultimately, the Seafood Specialized Complex promises to play a pivotal role in advancing the local seafood industry and stimulating the local economy. `,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                key={`asdwm${index}`}
                style={{ marginBottom: 25 }}
              >
                <div
                  style={{
                    background: "#fff",
                    padding: 25,
                    height: "100%",
                  }}
                >
                  <h4 style={{ color: "#333" }}>{item[0]}</h4>
                  <hr />
                  <p className="align-justify">{item[1]}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            {/* 내용 */}
            <div className="col-lg-9 col-md-12">
              <div className="content-block">
                <h2>SAHA DISTRICT'S FORWARD-THINKING STRATEGY</h2>
                <br />
              </div>
            </div>

            {/* 카드 */}
            {[
              [
                `Harnessing Artificial Intelligence, Robotics, and Automation`,
                `Saha District seeks to become a leader in advanced manufacturing by incorporating state-of-the-art technologies such as Artificial Intelligence (AI), robotics, and automation. The integration of AI will optimize manufacturing processes, enhancing efficiency, and minimizing errors. Simultaneously, robotics and automation will be used to perform repetitive tasks, reducing operational costs and saving time. This innovative approach underpins Saha District's role as a champion of the future manufacturing industry.`,
              ],
              [
                "Revolutionizing Logistics with IoT and Big Data",
                `By leveraging the Internet of Things (IoT) and Big Data analytics, Saha District plans to transform supply chain management. The application of IoT will create a network of interconnected devices, streamlining logistics processes, and improving real-time communication. Furthermore, Big Data analytics will enable more precise decision-making by analyzing vast volumes of data, optimizing delivery times, and minimizing waste. This comprehensive approach to logistics aims to boost efficiency and elevate the quality of services within the region.`,
              ],
              [
                `Fostering Innovation in Healthcare with Bio and Advanced Medical Technology`,
                `Saha District is committed to pushing the boundaries of healthcare innovation through the use of bio and advanced medical technology. These technologies are anticipated to improve disease diagnosis, develop innovative treatments, and enhance overall patient care. The introduction of advanced medical technology could lead to personalized medicine, offering treatments customized to individual patients, thus improving healthcare outcomes and residents' quality of life.`,
              ],
              [
                "Transforming the Retail Industry with AI and Virtual Reality",
                `Saha District envisions a retail industry revolution, utilizing AI and virtual reality (VR). AI technology can provide personalized shopping experiences by analyzing consumer behavior, and enhancing customer satisfaction. VR, on the other hand, can transform the shopping experience by allowing customers to virtually interact with products before purchasing. These groundbreaking technologies not only improve the retail experience but also contribute to enhanced security measures and reduced fraud.`,
              ],
              [
                "Sustainable Future Powered by AI and Machine Learning",
                `Saha District is deeply committed to sustainability, harnessing the potential of AI and machine learning to foster an energy-efficient environment. The district aims to improve energy consumption patterns and significantly reduce carbon emissions, aligning with its broader sustainability objectives. Through these initiatives, Saha District is paving the way toward a greener and more sustainable future for its residents.`,
              ],
            ].map((item, index) => (
              <div
                className="col-lg-6 col-md-6"
                style={{ marginBottom: 18 }}
                key={`askldj${index}`}
              >
                <div
                  className="align-justify"
                  style={{
                    background: "#fff",
                    boxShadow: "1px 1px 15px -1px rgba(0,0,0,0.1)",
                    height: "100%",
                    padding: 25,
                    // borderRadius: 13,
                  }}
                >
                  <h4>{item[0]}</h4>
                  <hr />
                  <p>{item[1]}</p>
                </div>
              </div>
            ))}
            {/* 카드 */}
            {/* 카드 */}
            <div className="col-lg-6 col-md-6" style={{ marginBottom: 18 }}>
              <div
                className="align-justify"
                style={{
                  background: "#fff",
                  boxShadow: "0px 0px 10px -3px rgba(0,0,0,0.05)",
                  height: "100%",
                  padding: 20,
                  // borderRadius: 13,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#11ab87",
                }}
              >
                <h5>
                  <b>
                    By embracing and integrating these forward-thinking
                    technologies, Saha District is not only preparing for the
                    future but actively shaping it, ensuring a vibrant,
                    sustainable, and technologically advanced future for all its
                    residents.
                  </b>
                </h5>
              </div>
            </div>
            {/* 카드 */}
          </div>
        </div>
      </section>
    </>
  );
};

export default En;
