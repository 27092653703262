/**
 *
 *
 */

import React from "react";
import { getCookie } from "utils";
import Ko from "./OrganizationStructure.ko";
import En from "./OrganizationStructure.en";
import { PageTitle } from "components";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;

/**
 * [Component] PageSample
 * --
 */
const PageIndex = () => {
  /* ===== State ===== */
  const language = getCookie("country");

  /* ===== Render ===== */
  return (
    <>
      <PageTitle
        title={lang["organization_structure"]}
        breadcrumb={[lang["discover_saha"], lang["organization_structure"]]}
        backgroundImage={
          "resources/discover_saha/2_Organization_Structure_pic1.JPG"
        }
      />
      {language !== "KR" ? <En /> : <Ko />}
    </>
  );
};

export default PageIndex;
