import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Form2 from "./Form2";

const Section = styled.section`
  margin: 0;
  padding: 50px 0;
  background-color: #ffffff;
`;

const Banner = styled.div`
  background: linear-gradient(135deg, #11998e, #38ef7d);
  padding: 15px;
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 12px;
  margin-bottom: 20px;
`;

const AboutWrapper = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

// ✅ 동영상 컨테이너 (모서리 직각 + 글로우 효과)
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 비율 유지 */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4); /* 그림자로 입체감 추가 */

  /* 🔹 글로우 효과 */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const MuteButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background: rgba(0, 0, 0, 0.9);
  }
`;

const Heading = styled.h3`
  font-size: 26px;
  font-weight: bold;
  color: #222;
  margin-top: 20px;
`;

const Description = styled.p`
  font-size: 16px;
  color: #555;
  margin: 15px 0;
`;

const LearnMoreButton = styled.button`
  background-color: #11ab87;
  color: #ffffff;
  border: none;
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #09976d;
    transform: scale(1.05);
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const AboutSection = ({ language }) => {
  const navigate = useNavigate();
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  return (
    <Section className="about-section">
      <div className="container">
        <div className="row">
          {/* ✅ About SAHA (왼쪽) */}
          <div className="col-md-6">
            <Banner>NEW SAHA</Banner>
            <AboutWrapper>
              {/* ✅ 동영상 (모서리 제거 + 글로우 효과 추가) */}
              <VideoContainer>
                <iframe
                  src={`https://www.youtube.com/embed/VxmJgYmMIsQ?autoplay=1&loop=1&playlist=VxmJgYmMIsQ&controls=0&modestbranding=1&rel=0&mute=${isMuted ? "1" : "0"}`}
                  title="Saha Introduction Video"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
                <MuteButton onClick={toggleMute}>
                  {isMuted ? "🔈" : "🔊"}
                </MuteButton>
              </VideoContainer>

              <Heading>ABOUT SAHA</Heading>
              <Description>
                {language !== "KR" ? (
                  <>
                    Welcome to the Saha District, a rapidly growing hub in
                    Busan, South Korea. Blending history, culture, and
                    cutting-edge industries, Saha is evolving into a prime
                    destination for innovation and business. With strategic
                    investments and smart industrial developments, the future of
                    advanced industries starts here.
                  </>
                ) : (
                  <>
                    대한민국 부산의 미래 산업 중심, 사하구에 오신 것을
                    환영합니다. 역사와 문화를 간직한 사하구는 첨단 산업과 혁신이
                    어우러지는 도시로 성장하고 있습니다. 전략적 투자와 스마트
                    산업단지 조성을 통해 미래 산업의 중심지로 도약하고 있습니다.
                  </>
                )}
              </Description>

              <LearnMoreButton onClick={() => navigate("/history-culture")}>
                Learn more
              </LearnMoreButton>
            </AboutWrapper>
          </div>

          {/* ✅ Form2 (오른쪽) */}
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <FormWrapper>
              <Form2 />
            </FormWrapper>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AboutSection;
