/**
 *
 *
 */

import React from "react";

/**
 * [Component] DivisionOverview
 * --
 */
const Ko = () => {
  /* ===== State ===== */
  const members = [
    {
      position: "전략사업과장",
      name: "변영태",
      contact: "051-220-4310",
      email: "t8588012@korea.kr",
      responsibilities: "",
    },
    {
      position: "미래전략계장",
      name: "정규섭",
      contact: "051-240-4313",
      email: "jks4166@korea.kr",
      responsibilities: "미래 전략 업무 총괄",
    },
    {
      position: "주무관",
      name: "유현석",
      contact: "051-220-4314",
      email: "kalinhand78@korea.kr",
      responsibilities:
        "구청장 현안시책 및 특별지시사항 검토, 사적공원 조성 사업 추진",
    },
    {
      position: "주무관",
      name: "홍용욱",
      contact: "051-220-4312",
      email: "kora2116@korea.kr",
      responsibilities: "장기발전계획 수립 및 사하발전 프로젝트",
    },
    {
      position: "주무관",
      name: "정민규",
      contact: "051-220-4315",
      email: "jmg89@korea.kr",
      responsibilities: "다대포복합개발 추진, 전략자문위원회 운영",
    },
    {
      position: "주무관",
      name: "권다혜",
      contact: "051-220-4316",
      email: "gooneoda@korea.kr",
      responsibilities: "관광개발사업",
    },
    {
      position: "주무관",
      name: "정예린",
      contact: "051-220-4311",
      email: "rin13561@korea.kr",
      responsibilities: "서무, 회계",
    },
    {
      position: "기업육성계장",
      name: "이근영",
      contact: "051-220-5023",
      email: "icib123@korea.kr",
      responsibilities: "기업육성업무 총괄",
    },
    {
      position: "주무관",
      name: "설정희",
      contact: "051-220-5024",
      email: "zlflfcm82@korea.kr",
      responsibilities: "산업단지관리, 기업관련 규제 개선",
    },
    {
      position: "주무관",
      name: "김소연",
      contact: "051-220-5022",
      email: "kkss12345@korea.kr",
      responsibilities: "신재생에너지 발전사업 총괄, 수소연료공급시설 설치",
    },
    {
      position: "주무관",
      name: "정설아",
      contact: "051-220-5026",
      email: "reuna16@korea.kr",
      responsibilities: "지식산업센터, 감천동 신재생에너지 발전사업",
    },
    {
      position: "주무관",
      name: "전가영",
      contact: "051-220-5025",
      email: "jgy1106@korea.kr",
      responsibilities: "산학협력, 드론 법, 바이오 헬스 등",
    },
  ];

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>전략사업과 소개</h2>
                <p className="align-justify">
                  전략사업과 홈페이지에 오신 것을 환영합니다. 사하구의
                  장기발전계획과 각종 지역개발사업을 체계적으로 수립하고,
                  기업투자유치를 위한 각종 규제에 대한 해결방안을 모색하며
                  기업하기 좋은 환경을 조성하기 위해 노력하고 있습니다. 사하구의
                  미래 성장 기반을 마련하고 더 나은 미래, 새로운 사하를 만들기
                  위해 최선을 다하고 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview1.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>What We Do</small>
                  <br />
                  부서에서 하는 일
                </h2>
              </div>
              <h5>
                1. 장기 개발 계획 수립
                <hr />
                2. 사하 개발 프로젝트 추진
                <hr />
                3. 관광 개발 사업 추진
                <hr />
                4. 지식산업센터 및 기업 관련 규제 개선
                <hr />
                5. 신재생에너지 발전 사업 및 신성장 산업, 첨단 프로젝트 추진
                <hr />
                6. 학술 및 산업 관련 업무 등 총괄
              </h5>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="content-block">
                <h2>
                  <small>Strategic Business Division</small>
                  <br />
                  전략사업과
                </h2>
              </div>
              <h5>
                Email: admin@newsaha.net
                <hr />
                Phone number: 051-220-4311
                <hr />
                Fax: 051-220-4319
              </h5>
            </div>
            <div className="col-lg-6 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview3.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="content-block">
            <h2>
              <small>Organizational Chart</small>
              <br />
              조직도
            </h2>
          </div>
          {/* Row */}
          <div
            style={{
              margin: "15px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "#339e0d",
                padding: 15,
                width: "100%",
                maxWidth: 660,
                height: 100,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                border: "2px solid #287d0a",
              }}
            >
              <h4>
                전략사업과
                <br />
                <small>Strategic Business Division</small>
              </h4>
            </div>
          </div>
          {/* Row */}
          {/* Row */}
          <div
            style={{
              margin: "15px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: 320,
                margin: "0 10px",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: 15,
                  width: "100%",
                  height: 100,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#287d0a",
                  border: "2px solid #339e0d",
                }}
              >
                <h4>
                  미래전략
                  <br />
                  <small>Future Initiatives Team</small>
                </h4>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                maxWidth: 320,
                margin: "0 10px",
              }}
            >
              <div
                style={{
                  background: "#fff",
                  padding: 15,
                  width: "100%",
                  height: 100,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#287d0a",
                  border: "2px solid #339e0d",
                }}
              >
                <h4>
                  기업육성
                  <br />
                  <small>Business Expansion Team</small>
                </h4>
              </div>
            </div>
          </div>
          {/* Row */}
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="content-block">
            <h2>
              구성원<small>({members.length})</small>
            </h2>
          </div>
          {/* Row */}
          <div className="row">
            {/* <h6>구성원</h6> */}
            {/* === 구성원 === */}
            {members.map((item) => (
              <div className="col-lg-4 col-md-6" style={{ padding: 10 }}>
                <div
                  className="team-person text-left "
                  style={{
                    padding: "20px 25px",
                    height: 280,
                    background: "#fff",
                    border: "1px solid #f1f1f1",
                    boxShadow: "1px 1px 5px -2px rgba(0,0,0,0.3)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "100%",
                      width: 55,
                      height: 55,
                      background: "#f1f1f1",
                      marginBottom: 15,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#dbdbdb",
                    }}
                  >
                    <h5>{item.name[0]}</h5>
                  </div>
                  <h5>
                    {item.name} <small>/ {item.position}</small>
                  </h5>
                  <hr style={{ margin: "10px 0" }} />
                  <p style={{ margin: 0 }}>
                    <b>연락처</b>: {item.contact}
                  </p>
                  <p style={{ margin: 0 }}>
                    <b>이메일</b>: {item.email}
                  </p>
                  {item.responsibilities && (
                    <p style={{ margin: 0 }}>
                      <b>담당업무</b>: {item.responsibilities}
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* === 구성원 === */}
          </div>
          {/* Row */}
        </div>
      </section>
    </>
  );
};

export default Ko;
