/**
 *
 *
 */

import React from "react";

/**
 * [Component] DivisionOverview
 * --
 */
const Ko = ({ data, onChange, sendEmail }) => {
  /* ===== State ===== */

  /* ===== Render ===== */
  return (
    <>
      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-md-12 mb-12 mb-lg-0">
              <div className="content-block">
                <h2>비즈니스 및 투자 문의</h2>
                <p className="align-justify">
                  사하구는 지식 기반 산업의 허브로서 성장, 혁신, 번영을
                  지속적으로 육성하고 있습니다. 지식산업센터로의 사업장 이전을
                  고려 중이시거나, 사하구에서 새로운 벤처를 시작하시거나, 저희가
                  제공하는 인센티브에 대한 조언과 정보를 원하시는 경우, 저희가
                  안내해 드리겠습니다. 아래 양식을 사용하거나 직접 이메일을 보내
                  문의해 주세요. 모든 문의에 신속하게 답변해 드리기 위해 최선을
                  다하고 있습니다
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contact-area pl-0 pl-lg-5">
                <div className="section-title">
                  <h3>문의양식</h3>
                </div>
                <form
                  name="contact_form"
                  className="default-form contact-form"
                  action="!#"
                  method="post"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Name"
                          placeholder="이름"
                          required=""
                          value={data.name}
                          onChange={(e) => onChange("name", e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Email"
                          placeholder="이메일"
                          required=""
                          value={data.email}
                          onChange={(e) => onChange("email", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Phone"
                          placeholder="연락처"
                          required=""
                          value={data.phone}
                          onChange={(e) => onChange("phone", e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <select
                          className="form-control"
                          name="subject"
                          placeholder=""
                          defaultValue="부동산"
                          onChange={(e) => onChange("type", e.target.value)}
                        >
                          <option value="부동산">부동산</option>
                          <option value="기술">기술 스타트업</option>
                          <option value="관광">관광</option>
                          <option value="지식산업센터">지식산업센터</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="company"
                          placeholder="소속"
                          required=""
                          value={data.company}
                          onChange={(e) => onChange("company", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="form_message"
                          placeholder="내용"
                          required=""
                          value={data.content}
                          onChange={(e) => onChange("content", e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form-group text-center">
                        <button
                          type="button"
                          className="btn-style-one"
                          onClick={() => sendEmail()}
                        >
                          전송
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section ">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <img
                src="resources/strategic_business_division/1_DivisionOverview3.png"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-9 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>개별 상담 안내</h2>
                </div>
                <p style={{ fontSize: "1.2em" }}>
                  사하구 투자 또는 사업 기회에 관한 문의는{" "}
                  <a
                    href="mailto:admin@newsaha.net"
                    style={{ textDecoration: "none", color: "#007bff" }}
                  >
                    admin@newsaha.net
                  </a>
                  . 으로 보내주시기 바랍니다. 저희 팀은 필요한 모든 정보와
                  지원을 안내해 드리겠습니다.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <img
                src="resources/support/support_2_2.jpeg"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
            <div className="col-lg-7 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>비즈니스를 위한 사하구 방문</h2>
                </div>
                <p style={{ fontSize: "1.2em" }}>
                  비즈니스 목적으로 사하구를 방문하시는 경우 숙박 시설, 기업
                  회의 공간 또는 기타 필요한 사항에 대한 안내가 필요하시면
                  언제든지 문의해 주시기 바랍니다. 최대한 생산적이고 쾌적한
                  비즈니스 방문이 될 수 있도록 최선을 다하겠습니다.
                </p>
              </>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="service-overview section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              {/* = 주소 = */}
              <>
                <div className="content-block">
                  <h2>사하구의 성장에 함께 참여해보세요</h2>
                </div>
                <p style={{ fontSize: "1.2em" }}>
                  사하구에 투자하거나 사업을 시작하는 것은 성장을 위한 전략적
                  결정일 뿐만 아니라 번성하고 혁신적인 커뮤니티에 합류할 수 있는
                  기회이기도 합니다. 여러분의 관심에 감사드리며 잠재적인 기회에
                  대해 함께 논의할 수 있기를 기대합니다.
                </p>
              </>
            </div>

            <div className="col-lg-5 col-md-12">
              <img
                src="resources/support/support_2_3.jpg"
                width="90%"
                style={{ borderRadius: 12 }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ko;
