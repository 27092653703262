/**
 *
 *
 */

import React, { useState } from "react";
import { getCookie, setCookie } from "utils";
import globalObj from "locale/intlGlobals";

const {
  intl: { txt: lang },
} = globalObj;
const cookieCountry = getCookie("country");

/**
 * [Component] HeaderTop
 * --
 */
const HeaderTop = () => {
  /* ===== State ===== */
  const [newCountry, setNewCounttry] = useState(cookieCountry);

  /* ===== Functions ===== */
  const handleChangeLanguage = (e) => {
    const { value } = e.target;
    setNewCounttry(value);
    setCookie("country", value);
    window.location.reload();
  };

  /* ===== Render ===== */
  return (
    <>
      <div className="header-top">
        <div className="container">
          <div className="row align-items-center" style={{ padding: "14px 0" }}>
            <div className="col-md-6">
              <div className="top-left text-center text-md-left">
                {/* 운영시간 */}
                <h6 style={{ padding: "5px 0" }}>
                  {lang["operation_comment"]}
                </h6>
              </div>
            </div>
            <div className="col-md-6">
              <div className="top-right text-center text-md-right">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{
                    padding: "2.5px 10px",
                    borderRadius: 5,
                    opacity: 0.6,
                    height: 32,
                  }}
                  value={newCountry}
                  defaultValue={"US"}
                  onChange={handleChangeLanguage}
                  placeholder="Language"
                >
                  {/* <option selected>Language</option> */}
                  <option value="US">English</option>
                  <option value="KR">한국어</option>
                </select>

                {/* <ul className="social-links">
                  <li>
                    <a href="https://themefisher.com/" aria-label="facebook">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://themefisher.com/" aria-label="twitter">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://themefisher.com/" aria-label="google-plus">
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://themefisher.com/" aria-label="instagram">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://themefisher.com/" aria-label="pinterest">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTop;
