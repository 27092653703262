import React from "react";
import PropTypes from "prop-types";
import menusData from "./menusData";
import HeaderNavStyles from "./HeaderNavStyles";

const MobileMenu = ({ sideMenu, setSideMenu, handleSubMenuClick }) => {
  return (
    <>
      {sideMenu && (
        <div
          className="fade-in"
          style={HeaderNavStyles.fadeBackground}
          onClick={() => setSideMenu(false)}
        ></div>
      )}
      <div
        className={`slide-right ${sideMenu ? "open" : ""}`}
        style={{
          ...HeaderNavStyles.slideRightMenu,
          transform: sideMenu ? "translateX(0)" : "translateX(-100%)",
        }}
      >
        <div style={HeaderNavStyles.menuHeader}>
          <button
            onClick={() => setSideMenu(false)}
            style={HeaderNavStyles.closeButton}
            aria-label="Close menu"
          >
            &#x2715; {/* Close icon */}
          </button>
        </div>
        <nav
          className="navbar navbar-expand-lg"
          style={HeaderNavStyles.navbarLinks}
        >
          <div id="navbarLinks">
            <ul style={HeaderNavStyles.navbarLinks}>
              {menusData.map((menu) => (
                <li key={menu.id}>
                  <div
                    className={`nav-link ${menu.submenus.length > 0 ? "dropdown-toggle" : ""}`}
                    style={HeaderNavStyles.megaMenuButton}
                  >
                    {menu.title}
                  </div>
                  {menu.submenus.length > 0 && (
                    <ul
                      style={HeaderNavStyles.dropdownMenu}
                      className="mega-menu"
                    >
                      {menu.submenus.map((submenu) => (
                        <li
                          key={submenu.id}
                          onClick={() => handleSubMenuClick(submenu.path)}
                          style={HeaderNavStyles.submenuItem}
                        >
                          <span style={HeaderNavStyles.megaMenuButton}>
                            {submenu.title}
                          </span>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

MobileMenu.propTypes = {
  sideMenu: PropTypes.bool.isRequired,
  setSideMenu: PropTypes.func.isRequired,
  handleSubMenuClick: PropTypes.func.isRequired,
};

export default MobileMenu;
